import Allergenipage from "./Allergeni/Index";
import ArticoliPage from "./Articoli";
import CategorieManager from "./Categorie";
import Ingredientipage from "./Ingredienti/Index";
import QrLink from "./QrLink";
import Stats from "./Statistiche/Stats";
import Branding from "./Stile/Branding";
import Traduzioni from "./Traduzioni";

export const menu: {
  lb: string;
  icon?: React.ReactNode;
  superadmin?: boolean;
  cont?: React.ReactNode;
  key: string;
}[] = [
  {
    lb: "Articoli",
    key: "art",
    cont: <ArticoliPage />,
  },
  {
    lb: "Categorie",
    key: "cat",
    cont: <CategorieManager />,
  },
  {
    lb: "Ingredienti",
    key: "ing",
    cont: <Ingredientipage />,
  },

  {
    lb: "Traduzioni",
    key: "tradu",
    cont: <Traduzioni />,
  },
  {
    lb: "Statistiche",
    key: "stats",
    cont: <Stats />,
  },
  {
    lb: "Codice QR",
    key: "qrlink",
    cont: <QrLink />,
  },
  {
    lb: "Impostazioni",
    key: "bran",
    cont: <Branding />,
  }


];
