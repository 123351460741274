import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { Empty, theme, Typography } from "antd";

interface RRWeekAnalytics {
  dayOfWeek: string; // Giorno in inglese (es. "Monday")
  count: number;
}

interface WeekAnalyticsChartProps {
  data: RRWeekAnalytics[];
}

const WeekAnalyticsChart: React.FC<WeekAnalyticsChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const { token } = theme.useToken();

  useEffect(() => {
    if (!chartRef.current) return;

    const chart = echarts.init(chartRef.current);

    // Mappa dei giorni in inglese -> italiano
    const dayMapping: { [key: string]: string } = {
      Monday: "Lunedì",
      Tuesday: "Martedì",
      Wednesday: "Mercoledì",
      Thursday: "Giovedì",
      Friday: "Venerdì",
      Saturday: "Sabato",
      Sunday: "Domenica",
    };

    // Ordina i giorni della settimana
    const weekDaysEnglish = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const weekDaysItalian = weekDaysEnglish.map((day) => dayMapping[day]);

    // Prepopola i dati con 0 per i giorni mancanti
    const mappedData = weekDaysEnglish.map((day) => {
      const found = data.find((entry) => entry.dayOfWeek === day);
      return found ? found.count : 0;
    });

    const option: echarts.EChartsOption = {
      xAxis: {
        type: "category",
        data: weekDaysItalian, // Usa i giorni in italiano
        axisLabel: {
          fontSize: 13,
          interval: 0, // Mostra tutte le etichette
          rotate: 40,
          padding: 1,
          color: token.colorText, // Utilizza il colore del testo dal tema
        },
        
      },
      yAxis: {
        type: "value",
        axisLabel: {
          fontSize: 13,
          color: token.colorText, // Utilizza il colore del testo dal tema
        },
        
      },
      series: [
        {
          data: mappedData, // I dati sono prepopolati con 0 per i giorni mancanti
          type: "bar",
          barWidth: 20,
          itemStyle: {
            
            color: token.colorPrimary, // Utilizza il colore primario dal tema
          },
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params: any) => {
          const item = params[0];
          return `${item.name}: ${item.value}`;
        },
         // Sfondo del tooltip
        borderColor: token.colorBorder, // Bordo del tooltip
        textStyle: {
          color: token.colorText, // Colore del testo nel tooltip
        },
      },
      grid: {
        left: "2%",
        right: "2%",
        bottom: "10%",
        containLabel: true,
      },
    };

    // Imposta l'opzione nel grafico
    chart.setOption(option);

    // Funzione per gestire il ridimensionamento
    const handleResize = () => {
      chart.resize();
    };

    window.addEventListener("resize", handleResize);

    // Pulisci il grafico e rimuovi l'event listener quando il componente viene smontato
    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [data, token]);

  return (
    <div
      style={{
        background: token.colorBgContainer, // Utilizza lo sfondo dal tema
        padding: "20px 25px",
        borderRadius: token.borderRadius, // Utilizza il border radius dal tema
      }}
    ><Typography.Title style={{ margin: 0 }} level={4}>
    Visualizzazioni settimana
  </Typography.Title>
      {data.length > 0 ? (
        <>
          
          <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
        </>
      ) : (
        <Empty style={{ width: "100%", height: "400px", alignContent: "center" }} />
      )}
    </div>
  );
};

export default WeekAnalyticsChart;
