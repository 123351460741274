import { render } from "@testing-library/react";
import { App, Button, Input, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import ModalOpenBt from "../common/ModalOpenbt";
import TabEdit from "./TabEdit";
import { apiGenResturant, apiGenTraduzioni } from "../../utils/apiutils";
import { useAuth } from "../../../state/auth/auth";
import { Resturnt } from "../Stile/Branding";
import { searchValid } from "../../utils/string";
import { LuBrainCircuit } from "react-icons/lu";
import { LoadingOutlined } from "@ant-design/icons";
import {
  ClockCircleOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";

export type datumDic = {
  bb_lang: string;
  bb_value: string;
  ref: string;
};

type props = {
  dic: datumDic[];
  refresh: () => void;
  filter: string;
  setIsTranslating: (value: boolean) => void;
  setHasPendingTranslations: (value: boolean) => void;
  onTranslateAll: (translateFn: () => Promise<void>) => void;
  // NEW: add callback to let the parent know how many rows are currently selected
  onSelectionChange: (count: number) => void;
};

const langs = ["it"];

export const allSuppLangs = [
  "it", "en", "es", "de", "fr", "nl", "pl", "zh", "ru", "ja",
  "ko", "sv", "cs", "nb", "da", "fi", "el", "tr", "uk", "sq", "ro", "ar"
];

const Tabella: React.FC<props> = ({
  dic,
  refresh,
  filter,
  setIsTranslating,
  setHasPendingTranslations,
  onTranslateAll,
  onSelectionChange,
}) => {
  const [langEnabled, setLangEnabled] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]); // NEW

  const { api } = useAuth();
  const { notification } = App.useApp();

  useEffect(() => {
    apiGenResturant()
      .getList(api)
      .then((r) => {
        const l = r as Resturnt;
        if (l.langsEnable) {
          try {
            setLangEnabled(JSON.parse(l.langsEnable));
          } catch (e) {
            setLangEnabled(
              l.langsEnable.split(",").map((lang) => lang.trim())
            );
          }
        }
      });
  }, []);

  // Build columns + data
  const { cols, datatable } = useMemo(() => {
    const cols: { [key: string]: {} } = {};
    const data: {
      [key: string]: {
        [col: string]: string;
      };
    } = {};

    dic.forEach((d) => {
      const l = d.bb_lang.toLowerCase();
      if (!cols[l]) {
        cols[l] = l;
      }

      // only create a row if there's an IT reference (so we skip empty it)
      if (data[d.ref]) {
        data[d.ref][l] = d.bb_value;
      } else if (l === "it" && d.bb_value) {
        data[d.ref] = { ref: d.ref, [l]: d.bb_value };
      }
    });

    return {
      cols,
      datatable: data,
    };
  }, [dic]);

  // Decide if we have pending translations (based on missing fields in each row)
  useEffect(() => {
    const hasPending = Object.values(datatable).some((row) =>
      langEnabled.some((lang) => lang !== "it" && !row[lang] && row["it"])
    );
    setHasPendingTranslations(hasPending);
  }, [datatable, langEnabled, setHasPendingTranslations]);

  // --- Translation logic --- //

  // We'll translate only selected rows if any are selected.
  // Otherwise, we translate everything that is missing a translation.
  const getTranslationsFromApi = (words: string[], targetLang: string) => {
    return apiGenTraduzioni()
      .extraPost(api, "word", {
        texts: words,
        source: "it",
        target: targetLang,
      })
      .then((response: any) => response.translations);
  };

  const saveBatch = (items: { ref: string; bb_value: string; bb_lang: string }[]) => {
    return apiGenTraduzioni().extraPost(api, "saveBatch", items);
  };

  const translateAll = async () => {
    if (!langEnabled.length || !dic.length) {
      notification.warning({
        message: "Nessuna lingua abilitata o nessun dato disponibile per la traduzione.",
      });
      return;
    }

    setIsTranslating(true);

    try {
      // Identify which rows to translate:
      // if selectedRowKeys is non-empty, use only those
      const allRows = Object.values(datatable);
      const rowsToProcess =
        selectedRowKeys.length > 0
          ? allRows.filter((row) => selectedRowKeys.includes(row.ref))
          : allRows;

      // For each enabled lang, if row is missing that translation => fill it
      for (const lang of langEnabled) {
        if (lang === "it") continue;

        // only consider rows that have it but no lang
        const rowsNeedingLang = rowsToProcess
          .filter((row) => row["it"] && !row[lang])
          .map((row) => ({ ref: row["ref"], text: row["it"] }));

        if (rowsNeedingLang.length === 0) continue;

        const texts = rowsNeedingLang.map((r) => r.text);
        const translations = await getTranslationsFromApi(texts, lang);

        const itemsToSave = rowsNeedingLang.map((row, index) => ({
          ref: row.ref,
          bb_value: translations[index],
          bb_lang: lang,
        }));

        await saveBatch(itemsToSave);
      }

      notification.success({
        message: "Traduzioni completate!",
      });
    } catch (error) {
      notification.error({
        message: "Errore nella traduzione",
        description:
          error instanceof Error ? error.message : "Errore sconosciuto",
      });
    } finally {
      setIsTranslating(false);
      refresh();
      // Clear selection after translating, if you prefer:
      setSelectedRowKeys([]);
      onSelectionChange(0);
    }
  };

  // Let the parent keep track of this method
  useEffect(() => {
    onTranslateAll(translateAll);
  }, [langEnabled, datatable, selectedRowKeys]);

  // --- TABLE COLUMNS --- //
  const itColumn = {
    title: "it",
    dataIndex: "it",
    key: "it",
    width: 150,
    fixed: "left" as const,
  };

  const azioniColumn = {
    title: "Azioni",
    dataIndex: "gestisci",
    key: "gestisci",
    width: 120,
    fixed: "left" as const,
    render: (_: any, r: any) => (
      <ModalOpenBt
        btLabel="modifica"
        btProps={{
          icon: <EditOutlined />,
        }}
      >
        <TabEdit
          value={{
            ref: r["ref"],
            it: r["it"],
            ...datatable[r["ref"]],
          }}
          refresh={refresh}
        />
      </ModalOpenBt>
    ),
  };

  const otherLangs = langEnabled
    .filter((lang) => lang !== "it")
    .map((c) => ({
      title: c,
      dataIndex: c,
      key: c,
      width: 150,
    }));

  const columns = [itColumn, azioniColumn, ...otherLangs];

  // NEW: rowSelection for the table
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      onSelectionChange(selectedRows.length);
    },
  };

  const dataSource = Object.values(datatable).filter((v) =>
    filter ? searchValid(Object.values(v), filter) : true
  );

  return (
    <div>
      <Table
        rowKey="ref"
        rowSelection={rowSelection}   // <--- enable row selection
        scroll={{ y: "max-content", x: "max-content" }}
        pagination={{ pageSize: 7 }}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  );
};

export const TranslateButton: React.FC<{
  onTranslate: () => Promise<void>;
  isTranslating: boolean;
  hasPendingTranslations: boolean;
  // NEW: how many rows are selected (to change the label)
  selectedRowsCount: number;
}> = ({
  onTranslate,
  isTranslating,
  hasPendingTranslations,
  selectedRowsCount,
}) => {
  let label = "Niente da tradurre";
  // If we have selected rows, show "Traduci righe selezionate"
  if (selectedRowsCount > 0) {
    label = "Traduci righe selezionate";
  } else if (hasPendingTranslations) {
    // No selected rows, but we do have missing translations => "Traduci tutto"
    label = "Traduci tutto";
  }

  // If we neither have missing translations nor selected rows, disable
  const disabled = !hasPendingTranslations && selectedRowsCount === 0;

  return (
    <Button
      icon={<LuBrainCircuit />}
      onClick={onTranslate}
      loading={isTranslating}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default Tabella;
