import React, { useEffect, useMemo, useState } from "react";
import { Articolo } from "../tabella";
import { Ingrediente } from "../../Ingredienti/tabella";
import { apiGenIng, apiGenItem } from "../../../utils/apiutils";
import { useAuth } from "../../../../state/auth/auth";
import { Input, Table, Tag, Typography } from "antd";
import { render } from "@testing-library/react";
import { searchValid } from "../../../utils/string";
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import AllergenoIconRender from "../../Allergeni/AllergenoIconRender";

type props = {
  item: Articolo;
  open?: boolean;
};

const ItemManageIngs: React.FC<props> = ({ item, open }) => {
  const [allIngs, setAllIngs] = useState<Ingrediente[]>([]);

  const { api } = useAuth();

  const [refsing, setResing] = useState(false);

  const [dbSelected, setDbSelected] = useState<Ingrediente[] | null>(null);

  const refresh = () => {
    setResing(true);
    apiGenItem()
      .extraGet(api, `ingredienti/${item.ref}`)
      .then((a) => {
        setDbSelected(a as Ingrediente[]);
      })
      .finally(() => {
        setResing(false);
      });
  };

  useEffect(() => {
    if (!dbSelected) {
      refresh();
    }
  }, [open, dbSelected]);

  const allergeni = useMemo(() => {
    const ret: {
      [k: string]: string;
    } = {};

    dbSelected?.forEach((db) => {
      if (db.allergeni) {
        db.allergeni.forEach((a) => {
          if (!ret[a]) {
            ret[a] = a;
          }
        });
      }
    });

    return Object.keys(ret);
  }, [dbSelected]);

  useEffect(() => {
    apiGenIng()
      .getList(api)
      .then((l) => {
        setAllIngs(l as Ingrediente[]);
      });
  }, []);

  const onAction = (action: "select" | "unselect", element: element) => {
    setResing(true);

    const then = (l: Ingrediente[]) => {
      setDbSelected(l);
    };
    const finaly = () => {
      setResing(false);
    };
    if (action == "select") {
      apiGenItem()
        .relateEntity(api, item, element)
        .then((e) => then(e as Ingrediente[]))
        .finally(finaly);
    } else {
      apiGenItem()
        .unrelateEntity(api, item, element)
        .then((e) => then(e as Ingrediente[]))
        .finally(finaly);
    }
  };

  return (
    <div>
      <TableElements
        elementLb={"Ingrediente"}
        elements={allIngs}
        loading={refsing}
        selectedRefs={dbSelected ? dbSelected.map((el) => el.ref) : []}
        onAction={onAction}
      />

      <div>
        <Typography.Title level={4}>Allergeni</Typography.Title>
        <div>
          {allergeni.map((a) => {
            return (
              <Tag color="orange" icon={<AllergenoIconRender allegeno={a} />}>
                {a}
              </Tag>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ItemManageIngs;

const TableElements: React.FC<{
  elements: element[];
  loading: boolean;
  selectedRefs: string[];
  elementLb: string;
  onAction: (a: "select" | "unselect", el: any) => void;
}> = ({ elements, loading, selectedRefs, elementLb, onAction }) => {
  const [filter, setfilter] = useState<string>("");

  const { selected, selectable } = useMemo(() => {
    const selected: element[] = [];
    const selectable: element[] = [];

    elements.forEach((el) => {
      if (selectedRefs.includes(el.ref)) {
        selected.push(el);
      } else {
        selectable.push(el);
      }
    });

    return { selected, selectable };
  }, [elements, selectedRefs]);

  return (
    <div>
      <div>
        <Input
          value={filter}
          placeholder="filtra"
          onChange={(e) => setfilter(e.target.value)}
        />
      </div>
      <div>
        <SingleTableEls
          nameLb={elementLb}
          onAction={(el) => {
            onAction("unselect", el);
          }}
          actionCont={
            <Tag color="red" icon={<MinusCircleOutlined />}>
              rimuovi
            </Tag>
          }
          title=""
          loading={loading}
          elements={selected.filter((e) => {
            if (filter && !searchValid(e.name, filter)) {
              return false;
            }
            return true;
          })}
        />
      </div>
      <div>
        <SingleTableEls
          nameLb={elementLb}
          onAction={(el) => {
            onAction("select", el);
          }}
          actionCont={
            <Tag color="green" icon={<PlusCircleOutlined />}>
              aggiungi
            </Tag>
          }
          title="Ingrendienti disponibili"
          elements={selectable.filter((e) => {
            if (filter && !searchValid(e.name, filter)) {
              return false;
            }
            return true;
          })}
          loading={loading}
        />
      </div>
    </div>
  );
};

type element = { name: string; ref: string };

const SingleTableEls: React.FC<{
  elements: element[];
  loading: boolean;
  title: string;
  nameLb: string;
  onAction: (el: element) => void;
  actionCont: React.ReactNode;
}> = ({ elements, nameLb, onAction, actionCont, title, loading }) => {
  const columns = [
    {
      title: nameLb,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 130,
      render: (v: any, r: element) => {
        if (loading) {
          return <LoadingOutlined />;
        }

        return (
          <div
            onClick={() => {
              onAction(r);
            }}
            style={{ cursor: "pointer" }}
          >
            {actionCont}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Typography.Title level={4}>{title}</Typography.Title>
      <Table
        pagination={{ pageSize: 5 }}
        size="small"
        dataSource={elements}
        columns={columns}
      />
    </div>
  );
};
