import { useEffect, useState } from "react";
import { theme, Typography, Grid } from "antd";
import { useAuth } from "../../../state/auth/auth";
import {
  PlusCircleOutlined,
} from "@ant-design/icons";
import TabellaCetegorie, { Categoria } from "./Tabella";
import { apiGenItemGroup } from "../../utils/apiutils";
import ModalOpenBt from "../common/ModalOpenbt";
import CreateEdit from "./CreateEdit";
import StandPage from "../common/StandPage";
import CardsCategorie from "./Tabella";

const CategorieManager = () => {
  const [lista, setLista] = useState<Categoria[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = theme.useToken();
  const { api } = useAuth();
  const [filter, setFilter] = useState("");
  const { md } = Grid.useBreakpoint();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // Funzione per filtrare gli elementi eliminati
  const filtraElementiNonEliminati = (listaCompleta: Categoria[]) => {
    return listaCompleta.filter(item => !item.deleted);
  };

  const refresh = () => {
    setIsLoading(true);
    apiGenItemGroup()
      .getList(api)
      .then((i) => {
        // Filtra gli elementi eliminati prima di impostare lo stato
        const listaFiltrata = filtraElementiNonEliminati(i as Categoria[]);
        setLista(listaFiltrata);
      })
      .catch((error) => {
        console.error("Errore nel recupero delle categorie:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div >
      <Typography style={{ paddingLeft: md ? "5%" : "2%" }}>
        <h3><b>Categorie</b></h3>
      </Typography>
      <StandPage
        extraBt={
        
          <ModalOpenBt
          btLabel={md ? "Crea" : ""}
          drawerTitle="Crea categoria"
            btProps={{
              icon: <PlusCircleOutlined />,
            }}
          >
            <CreateEdit note={true} refresh={refresh} />
          </ModalOpenBt>
             
        }
        isLoading={isLoading}
        refresh={refresh}
        showFilter
        showRefresh
        onFilterChange={setFilter}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        showDeleteButton
        lista={lista}
        apiGen={apiGenItemGroup()}
        showOrder={true}
      >
          <CardsCategorie
          lista={lista}
          filterText={filter}
          disabled={isLoading}
          refresh={refresh}
          // Se vuoi sincronizzare la selezione a livello di manager:
          selectedRefs={selectedRowKeys.map(String)}
          setSelectedRefs={(refs) => setSelectedRowKeys(refs)}
        />
        <br></br>
      </StandPage>
    </div>
  );
};

export default CategorieManager;
