import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  Checkbox,
  Switch,
  Typography,
  Input,
  List,
  Space,
  message,
  theme,
  notification,
} from "antd";

import ModalOpenBt from "../common/ModalOpenbt";
import CreateEdit from "./CreateEdit";
import { searchValid } from "../../utils/string";
import { apiGenItemGroup } from "../../utils/apiutils";
import { useAuth } from "../../../state/auth/auth";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import {
  CheckOutlined,
  CloseOutlined,
  MenuOutlined // Drag handle icon
} from "@ant-design/icons";

const { Text } = Typography;
const { TextArea } = Input;

export type Categoria = {
  ref: string;
  name: string;
  kname: string;
  subtitle: string;
  parentRef: string | null; // Se null => "padre"
  active: boolean;
  deleted: boolean;
};

type Props = {
  lista: Categoria[];
  filterText: string;
  disabled: boolean;
  refresh: () => void;
  selectedRefs?: string[];
  setSelectedRefs?: (refs: string[]) => void;
};

const CardsCategorie: React.FC<Props> = ({
  lista,
  filterText,
  disabled,
  refresh,
  selectedRefs,
  setSelectedRefs,
}) => {
  const { api } = useAuth();
  const { token } = theme.useToken();

  // Stato locale con tutte le categorie (padri + sottocategorie)
  const [categories, setCategories] = useState<Categoria[]>(lista);

  // Mappa con la versione "salvata" di ogni subtitle
  const [savedSubtitles, setSavedSubtitles] = useState<Record<string, string>>(() => {
    const initialMap: Record<string, string> = {};
    lista.forEach((cat) => {
      initialMap[cat.ref] = cat.subtitle;
    });
    return initialMap;
  });

  const [selectedSet, setSelectedSet] = useState<Set<string>>(
    new Set(selectedRefs || [])
  );

  // Inline editing per il nome
  const [editingNames, setEditingNames] = useState<Record<string, string>>({});
  const [isEditing, setIsEditing] = useState<Record<string, boolean>>({});

  // Se cambia la prop "lista", aggiorniamo categories e savedSubtitles
  useEffect(() => {
    setCategories(lista);
    const updatedMap: Record<string, string> = {};
    lista.forEach((cat) => {
      updatedMap[cat.ref] = cat.subtitle;
    });
    setSavedSubtitles(updatedMap);
  }, [lista]);

  // Se cambia la prop "selectedRefs"
  useEffect(() => {
    if (selectedRefs) {
      setSelectedSet(new Set(selectedRefs));
    }
  }, [selectedRefs]);

  // Filtra le categorie padre
  const parentCategories = useMemo(() => {
    let ret = categories.filter((cat) => cat.parentRef === null);
    if (filterText) {
      ret = ret.filter((cat) => searchValid([cat.name], filterText));
    }
    return ret;
  }, [categories, filterText]);

  // Trova sottocategorie di un padre
  const getSubcategories = (parentRef: string) =>
    categories.filter((c) => c.parentRef === parentRef);

  // -------------------------------
  //  CHECKBOX (selezione)
  // -------------------------------
  const handleParentCheckbox = (parent: Categoria, checked: boolean) => {
    const newSelected = new Set(selectedSet);
    if (checked) {
      newSelected.add(parent.ref);
    } else {
      newSelected.delete(parent.ref);
    }
    // Seleziona/deseleziona anche i figli
    const subs = getSubcategories(parent.ref);
    subs.forEach((s) => {
      if (checked) {
        newSelected.add(s.ref);
      } else {
        newSelected.delete(s.ref);
      }
    });
    setSelectedSet(newSelected);
    setSelectedRefs?.([...newSelected]);
  };

  const handleSubCheckbox = (subRef: string, checked: boolean) => {
    const newSelected = new Set(selectedSet);
    if (checked) {
      newSelected.add(subRef);
    } else {
      newSelected.delete(subRef);
    }
    setSelectedSet(newSelected);
    setSelectedRefs?.([...newSelected]);
  };

  // -------------------------------
  //  SWITCH (ACTIVE)
  // -------------------------------
  const handleParentSwitch = (parent: Categoria, active: boolean) => {
    const newCategories = categories.map((cat) => {
      if (cat.ref === parent.ref) return { ...cat, active };
      if (cat.parentRef === parent.ref) return { ...cat, active };
      return cat;
    });
    setCategories(newCategories);

    // Salva su API
    apiGenItemGroup()
      .edit(api, { ...parent, active })
      .catch(() => message.error("Errore aggiornando la categoria padre."));

    const subs = getSubcategories(parent.ref);
    subs.forEach((sub) => {
      apiGenItemGroup()
        .edit(api, { ...sub, active })
        .catch(() =>
          message.error("Errore aggiornando una sottocategoria del padre.")
        );
    });
  };

  const handleSubSwitch = (sub: Categoria, active: boolean) => {
    const newCategories = categories.map((cat) =>
      cat.ref === sub.ref ? { ...cat, active } : cat
    );
    setCategories(newCategories);

    apiGenItemGroup()
      .edit(api, { ...sub, active })
      .catch(() => message.error("Errore aggiornando la sottocategoria."));
  };

  // -------------------------------
  //   INLINE EDITING DEL NOME
  // -------------------------------
  // Stile comune per testo e input, wrap su più righe, no minWidth
  const getNameStyle = (isParent: boolean): React.CSSProperties => ({
    fontSize: isParent ? 22 : 18,
    fontWeight: isParent ? 600 : 400,
    margin: 0,
    padding: "2px 6px",
    border: "1px solid transparent",
    borderRadius: 4,
    background: "transparent",
    boxShadow: "none",
    outline: "none",
    width: "auto",
    // Forziamo un wrap se supera ~20 caratteri
    maxWidth: "20ch",
    whiteSpace: "normal",
    wordWrap: "break-word",
    wordBreak: "break-word",
  });

  const startEditing = (cat: Categoria) => {
    setIsEditing((prev) => ({ ...prev, [cat.ref]: true }));
    setEditingNames((prev) => ({ ...prev, [cat.ref]: cat.name }));
  };

  const handleNameChange = (ref: string, newName: string) => {
    setEditingNames((prev) => ({ ...prev, [ref]: newName }));
  };

  const confirmEditing = (cat: Categoria) => {
    const newName = editingNames[cat.ref]?.trim() || "";
    // Se è vuoto, annulla editing
    if (!newName) {
      setIsEditing((prev) => ({ ...prev, [cat.ref]: false }));
      return;
    }
    // Se non è cambiato, niente salvataggio / notifica
    if (newName === cat.name) {
      setIsEditing((prev) => ({ ...prev, [cat.ref]: false }));
      return;
    }

    // Aggiorna local
    const newCategories = categories.map((c) =>
      c.ref === cat.ref ? { ...c, name: newName } : c
    );
    setCategories(newCategories);

    // Salva su API
    apiGenItemGroup()
      .edit(api, { ...cat, name: newName })
      .then(() => {
        setIsEditing((prev) => ({ ...prev, [cat.ref]: false }));
        notification.success({ message: "Nome aggiornato", duration: 1.5 });
      })
      .catch(() => {
        message.error("Errore salvando il nuovo nome.");
      });
  };

  const renderInlineName = (cat: Categoria, isParent?: boolean) => {
    const style = getNameStyle(!!isParent);

    if (isEditing[cat.ref]) {
      // Calcoliamo la lunghezza del testo + qualche spazio
      // per gestire input "adattabile" in base ai caratteri
      const nameLength = editingNames[cat.ref]?.length || 0;
      // Stabilisci un range di dimensioni in ch
      // ad es. min 5ch e max 20ch
      const dynamicWidthCh = Math.min(Math.max(nameLength + 1, 5), 20);
      return (
        <Input
          autoFocus
          value={editingNames[cat.ref] || ""}
          onChange={(e) => handleNameChange(cat.ref, e.target.value)}
          onBlur={() => confirmEditing(cat)}
          onPressEnter={() => confirmEditing(cat)}
          style={{
            ...style,
            border: "1px solid #d9d9d9",
            background: "white",
            color:"black",
            // Usa la dimensione calcolata in ch
            width: `${dynamicWidthCh}ch`,
          }}
        />
      );
    }
    return (
      <Text
        style={{
          ...style,
          cursor: "pointer",
        }}
        onClick={() => startEditing(cat)}
      >
        {cat.name}
      </Text>
    );
  };

  // -------------------------------
  //   NOTE (subtitle) DEL PADRE
  // -------------------------------
  const handleSubtitleChange = (parentRef: string, newSubtitle: string) => {
    setCategories((prev) =>
      prev.map((c) => (c.ref === parentRef ? { ...c, subtitle: newSubtitle } : c))
    );
  };

  const handleSubtitleBlur = (parent: Categoria) => {
    const updatedParent = categories.find((c) => c.ref === parent.ref);
    if (!updatedParent) return;

    const oldSubtitle = savedSubtitles[parent.ref] ?? "";
    const newSubtitle = updatedParent.subtitle ?? "";

    if (oldSubtitle === newSubtitle) {
      // Nessuna modifica
      return;
    }

    // Salva se cambiato
    apiGenItemGroup()
      .edit(api, updatedParent)
      .then(() => {
        setSavedSubtitles((prev) => ({
          ...prev,
          [parent.ref]: newSubtitle,
        }));
        notification.success({
          message: "Note salvate con successo",
          duration: 1.5,
        });
      })
      .catch(() => {
        message.error("Errore salvando le note (subtitle).");
      });
  };

  // -------------------------------
  //   DRAG & DROP
  // -------------------------------
  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const subRef = draggableId;
    const newParentRef = destination.droppableId;

    const updatedCats = categories.map((cat) => {
      if (cat.ref === subRef) {
        return {
          ...cat,
          parentRef: newParentRef === "null" ? null : newParentRef,
        };
      }
      return cat;
    });
    setCategories(updatedCats);

    const movedSub = updatedCats.find((c) => c.ref === subRef);
    if (movedSub) {
      try {
        await apiGenItemGroup().edit(api, movedSub);
      } catch (err) {
        message.error("Errore aggiornando la parentRef della sottocategoria.");
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Space
        wrap
        style={{ 
          width: "100%", 
          maxHeight: "80vh", 
          overflowY: "auto", 
          gap: 20 
        }}
        align="start"
      >
        {parentCategories.map((parent) => {
          const isCheckedParent = selectedSet.has(parent.ref);
          const subs = getSubcategories(parent.ref);

          return (
            <Card
              key={parent.ref}
              bodyStyle={{ padding: 0 }}
              headStyle={{ padding: "0px" }}
              style={{ 
                display: "inline-block", 
                width: "auto", 
                borderRadius: 20,
                transition: "box-shadow 0.2s",
              }}
              // Gestione ombra al passaggio del mouse
              onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.3)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = "";
              }}
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 12,
                  }}
                >
                  {/* Parte sinistra: checkbox + nome */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 8,
                      marginRight: 60,
                    }}
                  >
                    <Checkbox
                      checked={isCheckedParent}
                      onChange={(e) =>
                        handleParentCheckbox(parent, e.target.checked)
                      }
                      disabled={disabled}
                    />
                    {renderInlineName(parent, true)}
                  </div>

                  {/* Parte destra: pulsante Crea + switch */}
                  <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <ModalOpenBt btIcon="Crea" btLabel="Crea" disabled={disabled} drawerTitle="Crea sottocategoria">
                      <CreateEdit
                        refParent={parent.ref}
                        isSottoCategoria
                        refresh={refresh}
                        note={false}
                      />
                    </ModalOpenBt>
                    <Switch
                      checked={parent.active}
                      onChange={(checked) => handleParentSwitch(parent, checked)}
                      disabled={disabled}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </div>
                </div>
              }
            >
              <Droppable droppableId={parent.ref || "null"}>
                {(providedDroppable) => (
                  <div
                    ref={providedDroppable.innerRef}
                    {...providedDroppable.droppableProps}
                    style={{ padding: 10 }}
                  >
                    {subs.map((sub, index) => {
                      const isSubChecked = selectedSet.has(sub.ref);
                      return (
                        <Draggable
                          key={sub.ref}
                          draggableId={sub.ref}
                          index={index}
                        >
                          {(providedDraggable) => (
                            <List.Item
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              style={{
                                background: token.colorBgElevated,
                                borderRadius: 8,
                                padding: "8px",
                                marginBottom: "8px",
                                display: "flex",
                                alignItems: "center",
                                boxShadow: "0 1px 2px rgba(0,0,0,0.5)",
                                ...providedDraggable.draggableProps.style,
                              }}
                            >
                              {/* Icona drag handle a sinistra */}
                              <div
                                {...providedDraggable.dragHandleProps}
                                style={{
                                  cursor: "grab",
                                  marginRight: 8,
                                  color: "#999",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <MenuOutlined />
                              </div>

                              {/* Checkbox */}
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={isSubChecked}
                                onChange={(e) =>
                                  handleSubCheckbox(sub.ref, e.target.checked)
                                }
                                disabled={disabled}
                              />
                              {/* Nome della sottocategoria */}
                              {renderInlineName(sub, false)}

                              {/* Switch a destra */}
                              <Switch
                                style={{ marginLeft: "auto" }}
                                checked={sub.active}
                                onChange={(checked) => handleSubSwitch(sub, checked)}
                                disabled={disabled}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                              />
                            </List.Item>
                          )}
                        </Draggable>
                      );
                    })}
                    {providedDroppable.placeholder}
                  </div>
                )}
              </Droppable>

              {/* Note (subtitle) del padre */}
              <div style={{ margin: 14 }}>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  Note:
                </Typography.Title>
                <TextArea
                  style={{
                    background: "white",
                    color: "black",
                    borderRadius: 15,
                  }}
                  rows={3}
                  value={parent.subtitle || ""}
                  onChange={(e) => handleSubtitleChange(parent.ref, e.target.value)}
                  onBlur={() => handleSubtitleBlur(parent)}
                  placeholder="Inserisci una nota..."
                />
              </div>
            </Card>
          );
        })}
      </Space>
      <br></br>
    </DragDropContext>
    
  );
};

export default CardsCategorie;
