import React, { useState } from "react";
import { Upload, Typography, Button, notification, message } from "antd";
import type { UploadProps, UploadFile } from "antd/es/upload/interface";
import { useAuth } from "../../../state/auth/auth";

interface CaricaSfondoProps {
  /**
   * Callback invocata dopo un caricamento riuscito.
   * Il padre potrà aggiornare il “time” e ricaricare l’immagine.
   */
  onBackgroundSelected?: () => void;
}

const CaricaSfondo: React.FC<CaricaSfondoProps> = ({ onBackgroundSelected }) => {
  const { api } = useAuth();

  // Stato locale: file selezionato dall’utente
  const [localFile, setLocalFile] = useState<File | null>(null);

  // Anteprima locale (URL temporaneo)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  /**
   * Controllo "prima dell’upload" per filtrare i file non-immagine.
   * Se non è un’immagine -> `Upload.LIST_IGNORE` (non lo aggiunge alla lista).
   * Se è immagine -> restituiamo false per fermare l’upload auto di antd.
   */
  const beforeUploadCheck = (file: File) => {
    if (!file.type.startsWith("image/")) {
      message.error("Sono supportati solo file immagine!");
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  /**
   * Gestione dell’evento onChange di antd Upload
   */
  const handleFileChange: UploadProps["onChange"] = (info) => {
    // Vogliamo sempre forzare una sola selezione alla volta
    // Svuotiamo la coda antd e usiamo localFile
    const newList = [...info.fileList];
    if (newList.length > 1) {
      // Se l’utente seleziona più file insieme, prendiamo solo il primo
      newList.splice(1);
    }

    // Otteniamo il primo file
    const fileObj = newList[0]?.originFileObj as File | undefined;
    if (fileObj) {
      setLocalFile(fileObj);
      setPreviewUrl(URL.createObjectURL(fileObj));
    } else {
      setLocalFile(null);
      setPreviewUrl(null);
    }

    // Forziamo la fileList vuota dopo aver catturato i dati
    // per permettere ulteriori onChange se l’utente riseleziona lo stesso file
    info.fileList = [];
  };

  /**
   * Upload manuale: clic su “Carica”
   */
  const handleConfirmUpload = () => {
    if (!localFile) return;

    api
      .uploadImage(localFile, "resturant/uploadBg")
      .then(() => {
        notification.success({
          message: "Sfondo caricato con successo!",
          duration: 1.5,
        });
        // reset stato
        setLocalFile(null);
        setPreviewUrl(null);

        // Avvisa il genitore
        onBackgroundSelected?.();
      })
      .catch((err) => {
        console.error("Errore caricamento sfondo:", err);
        notification.error({
          message: "Errore durante il caricamento",
          duration: 1.5,
        });
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 16,
      }}
    >
    <Upload
      accept="image/*"
      multiple
      fileList={[]} // Forza sempre una lista vuota
      beforeUpload={beforeUploadCheck}
      showUploadList={false}
      onChange={handleFileChange}
    >
        <div
          style={{
            maxWidth: 200,
            maxHeight: 500,
            minHeight:200,
            minWidth:250,
            border: "1px dashed #ccc",
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          {previewUrl ? (
            <img
              src={previewUrl}
              style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
              alt="Anteprima"
            />
          ) : (<>
             <div style={{textAlign:"center"}}>
  <Typography.Text>Carica uno sfondo</Typography.Text>
  <br />
  <Typography.Text type="secondary">Consigliamo un rapporto 20:9.</Typography.Text>
</div>
            </>
          )}
        </div>
      </Upload>

      <Button
        type="primary"
        disabled={!localFile}
        onClick={handleConfirmUpload}
      >
        Applica
      </Button>
    </div>
  );
};

export default CaricaSfondo;
