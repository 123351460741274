import React, { useEffect, useState } from "react";
import { itemtimprops } from "./ManageAvalability";
import dayjs, { Dayjs } from "dayjs";
import { Articolo } from "../tabella";
import {
  App,
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Segmented,
  Switch,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import { css } from "@emotion/css";
import { apiGenItemAvailability } from "../../../utils/apiutils";
import { useAuth } from "../../../../state/auth/auth";
import { CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";

type props = {
  refresh: () => void;
  editValue?: null | itemtimprops;
  item: Articolo;
  initName: string;
  close?: () => void;
};

const timeFormtat = "HH:mm";
const dateFormtat = "DD/MM/YYYY HH:mm";

const CreaEditAvailabilityRule: React.FC<props> = ({
  editValue,
  item,
  initName,
  refresh,
  close,
}) => {
  const [nameValue, setnameValue] = useState<string>(initName);
  const [istemp, setIstemp] = useState<boolean>(false);
  const [interval, setInterval] = useState<[Dayjs, Dayjs]>([
    dayjs(),
    dayjs().add(1, "month"),
  ]);
  const [days, setdays] = useState([0, 6]);

  const [timestart, settimestart] = useState("10:00");
  const [timeend, settimeend] = useState("13:00");

  const [active, setactive] = useState<boolean>(true);
  const [price, setprice] = useState<number>(item.price);

  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (editValue) {
      setnameValue(editValue.name);
      setIstemp(editValue.isTemp);
      if (editValue.isTemp) {
        setInterval([dayjs(editValue.start), dayjs(editValue.end)]);
      } else {
        setdays(JSON.parse(editValue.days));
        settimestart(editValue.timestart);
        settimeend(editValue.timeend);
      }

      setactive(editValue.active);
      setprice(editValue.price);
    }
  }, [editValue]);

  const { api } = useAuth();

  const { notification } = App.useApp();

  const saveDone = () => {
    refresh();
    if (close) {
      close();
    }
    notification.success({
      message: "Salvataggio avvenuto con successo!",
      key: "saving",
       duration: 1.5
    });
  };

  const catchThing = () => {
    notification.error({
      message: "Errore Salvataggio!",
      key: "saving", duration: 1.5
    });
  };

  const fin = () => {
    setSaving(false);
  };

  const dosaving = () => {
    setSaving(true);
    notification.info({
      message: "Salvataggio...",
      icon: <LoadingOutlined />,
      key: "saving", duration: 1.5
    });
  };

  const create = () => {
    dosaving();
    apiGenItemAvailability()
      .create(api, {
        name: nameValue,
        isTemp: istemp,
        start: interval[0].valueOf(),
        end: interval[1].valueOf(),
        days: JSON.stringify(days),
        timestart: timestart,
        timeend: timeend,
        active: active,
        price: price,
        itemref: item.ref,
      } as itemtimprops)
      .then(saveDone)
      .catch(catchThing)
      .finally(fin);
  };

  const edit = () => {
    dosaving();
    apiGenItemAvailability()
      .edit(api, {
        ref: editValue?.ref,
        name: nameValue,
        isTemp: istemp,
        start: interval[0].valueOf(),
        end: interval[1].valueOf(),
        days: JSON.stringify(days),
        timestart: timestart,
        timeend: timeend,
        active: active,
        price: price,
        itemref: item.ref,
      } as itemtimprops)
      .then(saveDone)
      .catch(catchThing)
      .finally(fin);
  };

  const save = () => {
    if (editValue) {
      edit();
    } else {
      create();
    }
  };

  return (
    <div
      className={css`
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
      `}
    >
      <Campo label="Nome">
        <Input
          
          value={nameValue}
          onChange={(e) => setnameValue(e.target.value)}
          addonAfter={<EditOutlined/>}
        />
      </Campo>

          
      <Campo label="Tipo Regola">
      
      <Tooltip
          placement="bottom"
          color="white"
          style={{minWidth:"450px"}}
          title={
                <div style={{ color:"black"}}>
                   <Typography.Text>
                    <b>Temporanea: </b> Applica una regola che viene usata in un determinato frangete di tempo.
                   </Typography.Text>
                   <br></br>
                   <br></br>
                   <Typography.Text>
                    <b>Fasce Orarie e Giornaliere: </b> Applica una regola che viene usata ciclicamente.
                   </Typography.Text>
                
                </div>
  }
>
  <Segmented
    value={istemp ? "Temporanea" : "Fasce Orarie e Giornaliere"}
    onChange={(e) => {
      setIstemp(e === "Temporanea");
    }}
    options={["Temporanea", "Fasce Orarie e Giornaliere"]}
  />
</Tooltip>
      </Campo>

      {istemp ? (
        <Campo label="Intervallo di Tempo">
          <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
            format={dateFormtat}
            allowClear={false}
            value={interval}
            onChange={(i) => {
              if (i) setInterval(i as any);
            }}
          />
        </Campo>
      ) : (
        <>
          <Campo label="Giorni di Disponibilità">
            <Checkbox.Group
            
              value={days}
              onChange={(e) => setdays(e)}
              options={[1, 2, 3, 4, 5, 6, 0].map((d) => {
                return {
                  value: d,
                  label: dayjs().set("day", d).format("dddd"),
                };
              })}
            />
          </Campo>
          <Campo label="Orari di Disponibilità">
            <TimePicker.RangePicker
              format={timeFormtat}
              minuteStep={15}
              allowClear={false}
              value={[dayjs(timestart, "HH:mm"), dayjs(timeend, "HH:mm")]}
              onChange={(e) => {
                if (e) {
                  if (e[0]) {
                    settimestart(e[0].format(timeFormtat));
                  }
                  if (e[1]) {
                    settimeend(e[1].format(timeFormtat));
                  }
                }
              }}
            />
          </Campo>
        </>
      )}
      <Campo label="Prezzo">
        <InputNumber<string>
          style={{ width: 200 }}
          min="0"
          value={price.toString()}
          addonBefore={"€"}
          step="0.01"
          onChange={(e) =>
            setprice(
              e ? (Number.isNaN(e) ? item.price : Number(e)) : item.price
            )
          }
          stringMode
        />
      </Campo>

      <Campo label="Attiva regola">
        <Switch
          value={active}
          onChange={(e) => {
            setactive(e);
          }}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </Campo>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          justifyContent: "end",
        }}
      >
        <Button
          onClick={(e) => {
            save();
          }}
          type="primary"
          disabled={saving}
        >
          Salva
        </Button>
      </div>
    </div>
  );
};

const Campo: React.FC<{
  label: string;

  children: React.ReactNode;
}> = ({ label, children }) => {
  return (
    <div>
      <Typography.Text  strong>{label}</Typography.Text>
      <div style={{marginTop:"5px"}}>{children}</div>
    </div>
  );
};

export default CreaEditAvailabilityRule;
