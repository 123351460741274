import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { Empty, theme, Typography } from "antd";
import { reporp } from "./Stats";
import { useAuth } from "../../../state/auth/auth";
import { apiGenItemGroup } from "../../utils/apiutils";

interface Categoria {
  ref: string;
  name: string;
  kname: string;
  parentRef: string | null;
  active: boolean;
}

// Dopo il match otteniamo
interface MatchedItem {
  groupName: string;
  count: number;
  parentName: string; // es: "Primi", "Secondi", "N/A"
}

const PerGroup = ({ stats }: { stats: reporp }) => {
  const { token } = theme.useToken();
  const { api } = useAuth();

  const chartRef = useRef<HTMLDivElement>(null);

  const [lista, setLista] = useState<Categoria[]>([]);
  const [matchedData, setMatchedData] = useState<MatchedItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // 1) Carichiamo tutte le categorie
  useEffect(() => {
    setIsLoading(true);
    apiGenItemGroup()
      .getList(api)
      .then((cats) => {
        setLista(cats as Categoria[]);
      })
      .catch((error) => {
        console.error("Errore nel caricamento delle categorie:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [api]);

  // 2) Creiamo matchedData quando abbiamo sia la lista che le topgroups
  useEffect(() => {
    if (lista.length > 0 && stats.topgroups.length > 0) {
      const tempMatched = stats.topgroups.map((tg) => {
        const parentCat = lista.find((cat) => cat.ref === tg.group.parentRef);
        return {
          groupName: tg.group.name,
          count: tg.count,
          parentName: parentCat ? parentCat.name : "N/A",
        };
      });
      setMatchedData(tempMatched);
      
    }
  }, [lista, stats]);

  // 3) Inizializziamo ECharts quando matchedData è pronto
  useEffect(() => {
    if (!chartRef.current) return;
    if (matchedData.length === 0) return;

    // Inizializza il grafico solo una volta
    let chart: echarts.ECharts;
    if (echarts.getInstanceByDom(chartRef.current)) {
      chart = echarts.getInstanceByDom(chartRef.current) as echarts.ECharts;
    } else {
      chart = echarts.init(chartRef.current);
    }

    // a) Estrarre le categorie padre (asse Y) in un array
    const parentNames = Array.from(new Set(matchedData.map((d) => d.parentName)));

    // b) Estrarre i groupName (ogni group corrisponde a una serie nello stack)
    const groupNames = Array.from(new Set(matchedData.map((d) => d.groupName)));

    // c) Costruire le serie: per ogni groupName, array di valori in base ai parentName
    //    Il valore di 'data[i]' corrisponde a 'parentNames[i]'
    const series = groupNames.map((group) => {
      const data = parentNames.map((pName) => {
        const found = matchedData.find(
          (md) => md.parentName === pName && md.groupName === group
        );
        return found ? found.count : 0;
      });

      return {
        name: group,
        type: "bar",
        barWidth: 40,
        stack: "total", // Tutte queste serie condividono lo stesso stack
        label: {
          show: true,
        },
        emphasis: {
          focus: "series",
        },
        data,
      };
    });

    // d) Configurazione dell’opzione ECharts
    const option: echarts.EChartsOption = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params: any) => {
          
          // `params` è un array di oggetti, uno per ogni serie
          const somma = params.reduce((acc: number, item: any) => acc + (item.value|| 0), 0);

          // Prendiamo il nome della categoria (parentName)
          const parentName = params[0].name;

          // Iniziamo a costruire il tooltip
          let html = `<strong>${parentName}: ${somma}</strong><br/>`;

          params.forEach((item: any) => {
            if(item.value>0) {
            html += `${item.seriesName}: ${item.value}<br/>`;}
          });

          return html;
        },
      },
      legend: {
        data: groupNames,
        textStyle:{ color: token.colorText}
      },
      grid: {
        left: "2%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLabel: {
          color: token.colorText,
          fontSize: 13
        },
      },
      yAxis: {
        type: "category",
        data: parentNames, // L'ordine delle righe corrisponde a data[i] delle serie
        axisLabel: {
          color: token.colorText,
          
          
          
          rich: {
            alignLeft: {
              fontSize: 13,
              align: "left", // Specifica l'allineamento per le etichette
              lineHeight: 14, // Aggiunge spaziatura tra le righe (opzionale)
            }},
    formatter: (value: string) => {
      const maxLength = 10; // Numero massimo di caratteri per riga
      const words = value.split(" "); // Divide il testo in parole
      let currentLine = "";
      const lines: string[] = [];

      words.forEach((word) => {
        if ((currentLine + word).length > maxLength) {
          lines.push(currentLine.trim()); // Aggiungi la riga completa
          currentLine = word + " "; // Inizia una nuova riga con la parola corrente
        } else {
          currentLine += word + " "; // Aggiungi la parola alla riga corrente
        }
      });

      if (currentLine.trim()) {
        lines.push(currentLine.trim()); // Aggiungi l'ultima riga
      }

      return `{alignLeft|${lines.join("\n")}}`; // Unisce le righe con "\n"
    },
          
        },
      
      },
      series: series as unknown as echarts.SeriesOption[],
    };

    // Imposta l'opzione con notMerge: true per rinfrescare completamente il grafico
    chart.setOption(option, { notMerge: true, lazyUpdate: false });

    // Pulizia quando il componente si smonta
    return () => {
      chart.dispose();
    };
  }, [matchedData, token]);

  return (
    <div
      style={{
        background: token.colorBgContainer,
        padding: "20px 25px",
        borderRadius: token.borderRadius,
      }}
    >
      <Typography.Title style={{ margin: 0 }} level={4}>
        Visualizzazioni categorie
      </Typography.Title>
  
      {isLoading ? (
        <Typography.Text>Caricamento categorie...</Typography.Text>
      ) : stats.topgroups.length > 0 ? (
        <div
          ref={chartRef}
          style={{
            width: "100%",
            height: "500px",
            marginTop: "20px",
          }}
        />
      ) : (
        <Empty
          style={{
            width: "100%",
            height: "400px",
            alignContent: "center",
          }}
        />
      )}
    </div>
  );
  
};

export default PerGroup;
