import { Table, TableProps } from "antd";
import React, { useMemo } from "react";
import { searchValid } from "../../utils/string";
import IngredienteEdit from "./IngredienteEdit";
import ModalOpenBt from "../common/ModalOpenbt";
import AssociateIngs from "./auxs/AssociateAllergeno";

export type Ingrediente = {
  name: string;
  kname: string;
  ref: string;
  allergeni?: string[];
  
};

type props = {
  lista: Ingrediente[];
  filterText: string;
  disabled: boolean;
  refresh: () => void;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
};

const AllergenoTable: React.FC<props> = ({
  lista,
  filterText,
  disabled,
  refresh,
  selectedRowKeys,
  setSelectedRowKeys
}) => {
  const columns: TableProps<Ingrediente>["columns"] = [
    {
      title: "Ingredienti",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Allergeni",
      dataIndex: "Allergeni",
      key: "Allergeni",
      width: 150,
      render: (_, v) => {
        return (
          <ModalOpenBt disabled={disabled}>
            <AssociateIngs ingrediente={v} />
          </ModalOpenBt>
        );
      },
    },
    {
      title: "Azioni",
      dataIndex: "actions",
      key: "actions",
      width: 150,
      render: (_, v) => {
        return (
          <IngredienteEdit
            disabled={disabled}
            ingrediente={v}
            ondone={refresh}
          />
        );
      },
    },
  ];

  const dataToShow = useMemo(() => {
    let ret = lista;

    if (filterText) {
      ret = ret.filter((a) => {
        return searchValid([a.name], filterText);
      });
    }

    return ret;
  }, [lista, filterText]);

  return (
    <div>
      <Table<Ingrediente>
        rowKey="ref"
        columns={columns}
        scroll={{ y: 'max-content', x: 'max-content' }}
        pagination={{ pageSize: 9 }}
        rowSelection={{
          selectedRowKeys,
          onChange: (keys) => {
            if (setSelectedRowKeys) {
              setSelectedRowKeys(keys);
            }
          }
        }}
        
        
        

        dataSource={dataToShow}
      />
    </div>
  );
};

export default AllergenoTable;
