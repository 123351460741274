import React, { useEffect, useState } from "react";
import { Ingrediente } from "./tabella";
import { Button, Drawer, notification } from "antd";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { useAuth } from "../../../state/auth/auth";
import CommonForm from "../common/CommonForm";

type props = {
  ingrediente: Ingrediente;
  ondone: () => void;
  disabled: boolean;
};

const IngredienteEdit: React.FC<props> = ({
  ingrediente,
  ondone,
  disabled,
}) => {
  const [open, setopen] = useState(false);

  const [value, setvalue] = useState({});

  const { api } = useAuth();

  useEffect(() => {
    setvalue(ingrediente);
  }, [ingrediente]);

  const save = () => {
    api
      .post("ingredienti/put", value)
      .then((e) => {
        notification.success({ message: "salvataggio riuscito" , duration: 1.5});
        ondone();
        setopen(false);
        setvalue({});
      })
      .catch(() => {
        notification.error({
          message: "Errore salvataggio", duration: 1.5
        });
      });
  };

  return (
    <div>
      <Button
        onClick={() => setopen(true)}
        icon={<EditOutlined />}
        disabled={disabled}
      >
        modifica
      </Button>

      <Drawer
        onClose={() => {
          setopen(false);
        }}
        open={open}
        title="Modifica ingrediente"
      >
        <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: 20 }}>
          <div>
            {open && (
              <CommonForm
                value={value}
                onChange={setvalue}
                elements={[
                  {
                    key: "name",
                    type: "string",
                    label: "Nome",
                  },
                ]}
              />
            )}
          </div>
          <div>
            <Button
              size="large"
              type="primary"
              onClick={save}
              icon={<SaveOutlined />}
            >
              salva
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default IngredienteEdit;
