import React from "react";
import { Grid, theme } from "antd";
import { useAuth } from "../../state/auth/auth";
import AdminPage from "../admin";
import AuthenticationPage from "../login";
import RegisterPage from "./Register"; // Assicurati che il path sia corretto

const MainPage = () => {
  const { user } = useAuth();
  const { token } = theme.useToken();
  const { md } = Grid.useBreakpoint();

  // Determiniamo quale componente mostrare in base allo stato di autenticazione e al ruolo
  let content;
  if (user) {
    // Se l'utente è autenticato e ha il flag superAdmin, mostra RegisterPage
    if (user.superAdmin) {
      content = <RegisterPage />;
    } else {
      content = <AdminPage />;
    }
  } else {
    content = <AuthenticationPage />;
  }

  return (
    <div
      style={{
        background: token.colorBgBase,
        height: "100vh",
        width: "100vw",
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: "auto",
        alignContent: user ? "center" : md ? "center" : "start",
        justifyContent: "center",
      }}
    >
      {content}
    </div>
  );
};

export default MainPage;
