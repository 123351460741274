import { Card, Grid, Row, Col, Badge, Input, Typography, theme } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import styled from "styled-components";

type Props = {
  value: string;
  onChange: (nv: string) => void;
};

type FontTypes = "serif" | "sans"  | "Playfair" | "Lora" | "Playwrite" | "Charm" | "Roboto" | "Nunito" ;

// Funzione per ottenere il font-family basato sul tipo
const getFont = (tty: FontTypes): string => {

  switch (tty) {

    case "Roboto":
      return "Roboto";
    case "sans":
      return "Raleway, sans-serif";
    case "serif":
      return "Cinzel Decorative, serif";
    case "Playfair":
      return "Playfair Display, italic";
    case "Lora":
      return "Lora, italic";
    case "Playwrite":
      return "Playwrite GB S , italic";
    case "Charm":
      return "Charm, san-serif";
      case "Nunito":
        return "Nunito";
  }
};

// Opzioni di font
const opts: { value: FontTypes; label: string }[] = [
  { value: "sans", label: "Sans Serif" },
  { value: "serif", label: "Serif" },
  { value: "Lora", label: "Lora" },
  { value: "Roboto", label: "Roboto" },
  { value: "Playfair", label: "Playfair" },
  { value: "Charm", label: "Charm" },
  { value: "Nunito", label: "Nunito" },
  { value: "Playwrite", label: "Playwrite" },
];

// Styled component per la preview del font
const PreviewText = styled.div<{ fontFamily: string }>`
  font-family: ${(props) => props.fontFamily};
  font-size: 16px;
  margin: 10px 0;
  text-align: center;
`;

// Componente FontFamilyPicker aggiornato
const FontFamilyPicker: React.FC<Props> = ({ value, onChange }) => {
  const { md } = Grid.useBreakpoint();
  const { lg } = Grid.useBreakpoint();
  const { sm } = Grid.useBreakpoint();
  const [previewText, setPreviewText] = useState("Ordina Qui");

  const {
    token: { colorPrimary, colorBorder, colorSuccess, colorBgContainer },
  } = theme.useToken();

  return (
    <div
      >
<Row gutter={[16, 16]} justify="start">
  {opts.map((opt) => (
    <Col
      key={opt.value}
      xs={12} // 100% larghezza per dispositivi extra piccoli
      sm={12} // 50% larghezza per dispositivi piccoli
      md={12}  // 33.33% larghezza per dispositivi medi
      lg={8}  // 25% larghezza per dispositivi grandi
      xl={6}  // 20% larghezza per dispositivi extra grandi
    >
      <Badge
        count={value === opt.value ? "✓" : ""}
        style={{
          backgroundColor: value === opt.value ? colorPrimary : colorSuccess,
    color: "#fff",
    fontWeight: "bold",
    fontSize: "14px", // Aumenta la dimensione del testo
    width: "28px",    // Aumenta la larghezza del badge
    height: "28px",   // Aumenta l'altezza del badge
    lineHeight: "28px", // Allinea verticalmente il testo
    borderRadius: "50%", // Mantiene il badge circolare
        }}
        offset={[-10, 10]} // Posizionamento del conteggio
      >
        <Card
          hoverable
          onClick={() => onChange(opt.value)}
          style={{
            
             minWidth: sm ? "230px" : md ? "260px" : lg ? "220px" : "180px",
             maxWidth: sm ? "350px" : md ? "260px" : lg ? "220px" : "180px",
            border:
              value === opt.value
                ? `2px solid ${colorPrimary}`
                : `1px solid ${colorBorder}`,
            borderRadius: 8,
            transition: 'border 0.3s ease',
          }}
          title={
            <Typography.Title
              level={5}
              style={{
                fontFamily: getFont(opt.value),
                margin: 0,
                textAlign: "left",
                height: 30
              }}
            >
              {opt.label}
            </Typography.Title>
          }
          headStyle={{
            padding: '12px 16px',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
          bodyStyle={{
            backgroundColor: '#fff',
            borderBottom: "none",
          }}
        >
          <PreviewText
            style={{
              minHeight: "60px",
              maxWidth: sm ? "180px" : md ? "220px" : lg ? "260px" : "30px",
              textAlign: "left",
              fontSize: 24,
              color: "black"
            }}
            fontFamily={getFont(opt.value)}
          >
            {previewText}
          </PreviewText>
        </Card>
      </Badge>
    </Col>
  ))}
</Row>

        
        
    </div>
  );
};

export default FontFamilyPicker;
