import { Space, Table, Tag, theme, Typography, Grid, Switch, notification, Button, Modal } from "antd";
import type { TableProps } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { searchValid } from "../../utils/string";
import ModalOpenBt from "../common/ModalOpenbt";
import {
  DeleteOutlined,
  ClockCircleOutlined,
  // EditOutlined,  // Rimosso perchè non più utilizzato in tabella
  CheckOutlined, 
  CloseOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import EditItem from "./drwrs/Edit";
import { formatEuro } from "../../utils/numbers";
import ItemManageIngs from "./drwrs/ManageIngs";
import ManageAvailability from "./drwrs/ManageAvalability";
import { Ingrediente } from "../Ingredienti/tabella";
import { Categoria } from "../Categorie/Tabella";
import { useAuth } from "../../../state/auth/auth";
import { apiGenItemGroup, apiGenItem } from "../../utils/apiutils";
import type { FilterValue } from 'antd/es/table/interface';

export interface Articolo {
  ref: string;
  name: string;
  kname: string;
  description: string;
  kdescription: string;
  tags: Tags[];
  price: number;
  pic: string;
  allergeni: string;
  active: boolean;
  showprice: boolean;
  showdescription: boolean;
  showtags: boolean;
  refParent: string;
  showingredienti: boolean;
  showallegeni: boolean;
  ings: Ingrediente[];
  removedIngs: Ingrediente[];
}

export interface Tags {
  name: string;
  ref: string;
  
}

type props = {
  articoli: Articolo[];
  filterText: string;
  isloading: boolean;
  refresh: () => void;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
};

const Articolitable: React.FC<props> = ({
  articoli,
  filterText,
  isloading,
  refresh,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const [cats, setcats] = useState<Categoria[]>([]);
  const { token } = theme.useToken();
  const { api } = useAuth();
  const { md } = Grid.useBreakpoint();

  useEffect(() => {
    apiGenItemGroup()
      .getList(api)
      .then((e) => setcats(e as any));
  }, [api]);

  const handleActiveChange = (checked: boolean, record: Articolo) => {
    apiGenItem()
      .edit(api, { ...record, active: checked })
      .then(() => {
        notification.success({ message: "Stato aggiornato correttamente!", duration: 1.5 });
        refresh();
      })
      .catch(() => {
        notification.error({ message: "Errore durante l'aggiornamento dello stato", duration: 1.5 });
      });
  };

  // Stato per i filtri
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({});

  const columns: TableProps<Articolo>["columns"] = [
    {
      title: "Titolo",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Categoria",
      dataIndex: "refParent",
      key: "refParent",
      width: 250,
      // Mostra solo le categorie effettivamente usate in tabella
      filters: cats
        .filter((cat) => articoli.some((a) => a.refParent === cat.ref))
        .map((cat) => ({
          text: cat.parentRef
            ? `${cats.find((e) => e.ref === cat.parentRef)?.name || ""}/${cat.name}`
            : cat.name,
          value: cat.ref,
        })),
      filteredValue: filters.refParent || null,
      onFilter: (value, record) => record.refParent === value,
      render(value) {
        const cat = cats.find((e) => e.ref === value);
        let lastname = "";
        let pp = "";

        if (cat) {
          lastname = cat.name;
          if (cat.parentRef) {
            const catp = cats.find((e) => e.ref === cat.parentRef);
            pp = catp ? catp.name : "";
          }
        }

        return (
          <Typography.Text>
            <Typography.Text>{pp}</Typography.Text>
            {pp ? "/" : ""}
            <Typography.Text color={token.colorPrimaryText} strong>
              {lastname}
            </Typography.Text>
          </Typography.Text>
        );
      },
    },
    {
      title: "Prezzo",
      dataIndex: "price",
      key: "price",
      width: "15%",
      sorter: (a, b) => {
        const priceA = typeof a.price === 'string' ? a.price : a.price?.toString();
        const priceB = typeof b.price === 'string' ? b.price : b.price?.toString();
        // Consideriamo null come 0
        const priceAValue = priceA ? parseFloat(priceA.replace(/[^\d.-]/g, '')) : 0;
        const priceBValue = priceB ? parseFloat(priceB.replace(/[^\d.-]/g, '')) : 0;
    
        return priceAValue - priceBValue;
      },
      sortDirections: ["ascend", "descend"],
      render: (v) => {
        const price = v == null ? 0 : v;
        return <>{formatEuro(price)}</>;
      },
    },
    {
      title: "Attivo",
      dataIndex: "active",
      key: "active",
      width: "10%",
      sorter: (a, b) => Number(a.active) - Number(b.active),
      render: (active, record) => {
        return (
          <Switch
            checked={active}
            onChange={(checked) => handleActiveChange(checked, record)}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        );
      },
    },
    {
      title: "Regole prezzo",
      dataIndex: "availability",
      key: "availability",
      width: 130,
      render(value, record) {
        return (
          <ModalOpenBt
            disabled={isloading}
            btProps={{
              icon: <ClockCircleOutlined />,
            }}
          >
            <ManageAvailability item={record} />
          </ModalOpenBt>
        );
      },
    },
    // La colonna "Azioni" è stata rimossa in modo che il tasto modifica venga gestito da StandPage
  ];

  const dataToShow = useMemo(() => {
    let ret = articoli;
    if (filterText) {
      ret = ret.filter((a) => {
        return searchValid([a.name, a.description], filterText);
      });
    }
    return ret;
  }, [articoli, filterText]);

  return (
    <div style={{ overflow: "auto" }}>
      <Table<Articolo>
        rowKey="ref"
        showSorterTooltip={{ title: '' }}
        columns={columns}
        scroll={{ y: 'max-content', x: 'max-content' }}
        pagination={{ pageSize: 9 }}
        rowSelection={{
          selectedRowKeys,
          onChange: (keys) => {
            if (setSelectedRowKeys) {
              setSelectedRowKeys(keys);
            }
          },
        }}
        dataSource={dataToShow}
        // Aggiorna i filtri (incluso quello di Categoria)
        onChange={(pagination, filters) => {
          setFilters(filters);
        }}
      />
    </div>
  );
};

export default Articolitable;
