import { useEffect, useState } from "react";
import AllergenoTable, { Ingrediente } from "./tabella";
import {  theme , Grid, Typography} from "antd";
import { useAuth } from "../../../state/auth/auth";

import CreateModalAllergeno from "./Create";
import StandPage from "../common/StandPage";
import { apiGenIng } from "../../utils/apiutils";

const Ingredientipage = () => {
  const [lista, setlista] = useState<Ingrediente[]>([]);

  const [isloading, setIsloading] = useState(false);

  const { token } = theme.useToken();

  const { api } = useAuth();

  const [filter, setfilter] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const refresh = () => {
    setIsloading(true);
    api
      .get("ingredienti/")
      .then((i) => {
        setlista(i);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  useEffect(() => {
    refresh();
  }, []);
 const md = Grid.useBreakpoint()
  return (
    <>
    <Typography  style={{paddingLeft: md.md ? "5%" : "2%" }}><h3><b>Ingredienti</b></h3></Typography>
    <StandPage
      extraBt={ 
        <CreateModalAllergeno
          listCreated={lista.map((l) => l.name)}
          disabled={isloading}
          onCreate={refresh}
        />
      }
      isLoading={isloading}
      refresh={refresh}
      showFilter
      showRefresh
      onFilterChange={setfilter}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      showDeleteButton
      lista={lista}
      apiGen={apiGenIng()}
    >
      <AllergenoTable
        lista={lista}
        filterText={filter}
        disabled={isloading}
        refresh={refresh}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </StandPage>
    </>
  );
};

export default Ingredientipage;
