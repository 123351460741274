import React, { useState, useEffect } from "react";
import { Button, notification } from "antd";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import { MenuOutlined } from "@ant-design/icons";
import { useAuth } from "../../../state/auth/auth";
import { apiGenItemGroup } from "../../utils/apiutils";

type RitemGroup = {
  ref: string;
  name: string;
  kname?: string;
  subtitle?: string;
  parentRef?: string;
  active?: boolean;
  deleted?: boolean;
  position: number | null; // può essere null
};



const Order: React.FC = () => {
  const { api } = useAuth();
  const [items, setItems] = useState<RitemGroup[]>([]);
  const [saving, setSaving] = useState(false);

  // Filtra gli elementi eliminati
  const filtraElementiNonEliminati = (listaCompleta: RitemGroup[]) =>
    listaCompleta.filter((item) => !item.deleted);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiGenItemGroup().getList(api);
        const listaFiltrata = filtraElementiNonEliminati(data as RitemGroup[]);
        // Filtra solo le categorie top-level (senza parentRef)
        const topLevelItems = listaFiltrata.filter(
          (item) => !item.parentRef
        );
        // Ordina: le categorie con posizione valorizzata vengono prima, quelle con null in fondo
        topLevelItems.sort((a, b) => {
          const posA =
            a.position !== null && a.position !== undefined
              ? a.position
              : Number.MAX_VALUE;
          const posB =
            b.position !== null && b.position !== undefined
              ? b.position
              : Number.MAX_VALUE;
          return posA - posB;
        });
        setItems(topLevelItems);
      } catch (error) {
        notification.error({
          message: "Errore nel caricamento delle categorie",
        });
      }
    };

    fetchData();
  }, [api]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [moved] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, moved);

    // Assegna nuove position sequenziali (1-based)
    const updatedItems = newItems.map((item, index) => ({
      ...item,
      position: index + 1,
    }));
    setItems(updatedItems);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await Promise.all(
        items.map((item) =>
          apiGenItemGroup().edit(api, { ...item, position: item.position })
        )
      );
      notification.success({
        message: "Ordine aggiornato con successo",
        duration: 1.5
      });
    } catch (error) {
      notification.error({
        message: "Errore nell'aggiornamento dell'ordine",
        duration: 1.5
      });
    }
    setSaving(false);
  };

  return (
    <div style={{ padding: 16 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="order-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <Draggable key={item.ref} draggableId={item.ref} index={index}>
                  {(providedDraggable) => (
                    <div
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      style={{
                        background: "#fff", // Puoi sostituire con token.colorBgElevated se disponibile
                        borderRadius: 8,
                        padding: "8px",
                        marginBottom: "8px",
                        color:"black",
                        display: "flex",
                        alignItems: "center",
                        boxShadow: "0 1px 2px rgba(0,0,0,0.5)",
                        ...providedDraggable.draggableProps.style,
                      }}
                    >
                      {/* Icona drag handle */}
                      <div
                        {...providedDraggable.dragHandleProps}
                        style={{
                          cursor: "grab",
                          marginRight: 8,
                          color: "#999",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <MenuOutlined />
                      </div>
                      <div>{item.name}</div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button type="primary" style={{marginTop:"30px"}} onClick={handleSave} loading={saving}>
        Salva ordine
      </Button>
    </div>
  );
};

export default Order