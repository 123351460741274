export type apiType = {
  get: (url: string) => Promise<any>;
  post: (url: string, payload: any) => Promise<any>;
  patch: (url: string, payload: any) => Promise<any>;
  uploadImage?: (file: File, url: string) => Promise<any>;
};

type entity = {
  id?: number;
  ref: string;
} & any;

class baseEntityApi {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  getList(api: apiType) {
    return getList(api, this.endpoint);
  }

  getEntity(api: apiType, refEn: string) {
    return getEntity(api, this.endpoint, refEn);
  }

  create(api: apiType, entity: entity) {
    return create(api, this.endpoint, entity);
  }
  save(api: apiType, entity: entity) {
    return save(api, this.endpoint, entity);
  }
  edit(api: apiType, entity: entity) {
    return edit(api, this.endpoint, entity);
  }
  deleteEntity(api: apiType, entity: entity) {
    return deleteEntity(api, this.endpoint, entity);
  }
  relateEntity(api: apiType, entity: entity, entityToRelate: entity) {
    return relateEntity(api, this.endpoint, entity, entityToRelate);
  }
  unrelateEntity(api: apiType, entity: entity, entityToRelate: entity) {
    return unrelateEntity(api, this.endpoint, entity, entityToRelate);
  }
  extraGet(api: apiType, extraendpoint: string) {
    return new Promise((res, rej) => {
      api.get(`${this.endpoint}/${extraendpoint}`).then(res).catch(rej);
    });
  }
  extraPost(api: apiType, extraendpoint: string, payload: any) {
    return new Promise((res, rej) => {
      api.post(`${this.endpoint}/${extraendpoint}`, payload)
        .then(res)
        .catch(rej);
    });
  }
  extraPatch(api: apiType, extraendpoint: string, payload: any) {
    return new Promise((res, rej) => {

        api.patch(`${this.endpoint}/${extraendpoint}`, payload)
          .then(res)
          .catch(rej);
      
    });
  }
}

const getList = (api: apiType, entityEndpoint: string) => {
  return new Promise((res, rej) => {
    api.get(`${entityEndpoint}/`).then(res).catch(rej);
  });
};

const getEntity = (api: apiType, entityEndpoint: string, refEn: string) => {
  return new Promise((res, rej) => {
    api.get(`${entityEndpoint}/${refEn}`).then(res).catch(rej);
  });
};

const create = (api: apiType, entityEndpoint: string, entity: entity) => {
  return new Promise((res, rej) => {
    api.post(`${entityEndpoint}/create`, entity).then(res).catch(rej);
  });
};

const save = (api: apiType, entityEndpoint: string, entity: entity) => {
  return new Promise((res, rej) => {
    api.post(`${entityEndpoint}/save`, entity).then(res).catch(rej);
  });
};

const edit = (api: apiType, entityEndpoint: string, entity: entity) => {
  return new Promise((res, rej) => {
    api.post(`${entityEndpoint}/put`, entity).then(res).catch(rej);
  });
};

const deleteEntity = (api: apiType, entityEndpoint: string, entity: entity) => {
  return new Promise((res, rej) => {
    api.post(`${entityEndpoint}/delete`, entity).then(res).catch(rej);
  });
};

const relateEntity = (
  api: apiType,
  entityEndpoint: string,
  entity: entity,
  entityToRelate: entity
) => {
  return new Promise((res, rej) => {
    api.post(`${entityEndpoint}/relate/${entity.ref}`, entityToRelate)
      .then(res)
      .catch(rej);
  });
};

const unrelateEntity = (
  api: apiType,
  entityEndpoint: string,
  entity: entity,
  entityToRelate: entity
) => {
  return new Promise((res, rej) => {
    api.post(`${entityEndpoint}/unrelate/${entity.ref}`, entityToRelate)
      .then(res)
      .catch(rej);
  });
};

export {
  getList,
  getEntity,
  create,
  edit,
  deleteEntity,
  relateEntity,
  unrelateEntity,
  baseEntityApi,
};
