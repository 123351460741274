import React, { useMemo } from "react";

import { AiFillAlert, AiOutlineWarning } from "react-icons/ai"; // React Icons
import { LuWheat } from "react-icons/lu";
import { LuFish } from "react-icons/lu";
import { searchValid } from "../../utils/string";
import { LuMilkOff } from "react-icons/lu";
import { GiPeanut } from "react-icons/gi";
import { LuEggOff } from "react-icons/lu";

import { GiAlmond } from "react-icons/gi";
type props = {
  allegeno: string;
};
const allergens = [
  { name: "Glutine", icon: <LuWheat /> },
  { name: "Crostacei", icon: <AiOutlineWarning /> },
  { name: "Uova", icon: <LuEggOff /> },
  { name: "Pesce", icon: <LuFish /> },
  { name: "Arachidi", icon: <GiPeanut /> },
  { name: "Soia", icon: <AiOutlineWarning /> },
  { name: "Latte", icon: <LuMilkOff /> },
  { name: "Lattosio", icon: <LuMilkOff /> },
  { name: "Frutta a guscio", icon: <GiAlmond /> },
  { name: "Sedano", icon: <AiFillAlert /> },
  { name: "Senape", icon: <AiOutlineWarning /> },
  { name: "Sesamo", icon: <AiFillAlert /> },
  { name: "Anidride solforosa e solfiti", icon: <AiOutlineWarning /> },
  { name: "Lupini", icon: <AiFillAlert /> },
  { name: "Molluschi", icon: <AiOutlineWarning /> },
];

const AllergenoIconRender: React.FC<props> = ({ allegeno }) => {
  let ret = useMemo(() => {
    const a = allergens.find((v) => searchValid(v.name, allegeno));

    if (a) {
      return a.icon;
    } else {
      return <AiOutlineWarning />;
    }
  }, [allegeno]);

  return ret;
};

export default AllergenoIconRender;
