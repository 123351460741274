import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Button,
  Card,
  ColorPicker,
  Divider,
  Input,
  InputNumber,
  notification,
  Slider,
  Space,
  theme,
  Typography,
  Switch,
  Segmented,
  Grid
} from "antd";
import {
  DownloadOutlined,
  WhatsAppOutlined,
  CopyOutlined,
  CheckOutlined,
  CloseOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import StandPage from "../common/StandPage";
import { QRCode } from "react-qrcode-logo";
import { apiGenQRcode } from "../../utils/apiutils";
import { useAuth } from "../../../state/auth/auth";

// Costanti
const waBaseURl =
  "https://web.whatsapp.com/send?text=http%3A%2F%2Fapp.ordinaqui.com%2Fmenu%2F";
const MIN_SIZE_CM = 1;
const MAX_SIZE_CM = 30;
const LEVELS: ("L" | "M" | "Q" | "H")[] = ["L", "M", "Q", "H"];

// Funzione per convertire il valore numerico in livello (ecLevel)
const precisionValToEcLevel = (val: number) => {
  switch (val) {
    case 1:
      return "L";
    case 2:
      return "M";
    case 3:
      return "Q";
    case 4:
      return "H";
    default:
      return "M";
  }
};

const QrLink: React.FC = () => {
  const { token } = theme.useToken();
  const { resturant, api } = useAuth();

  // Stato per le impostazioni del QR Code, in base alla risposta API,
  // aggiungendo la proprietà downloadSizeCm che verrà mandata anche via API
  const [qrData, setQrData] = useState({
    uselogo: false,
    precisionVal: 2,
    stile: "squares", // "squares" è uno dei valori ammessi: "squares" | "dots" | "fluid"
    bodycolor: "#000000",
    eyescolor: "#000000",
    eyesradius: 0,
    dimension: 256,
    menubaseurl: "https://menu.ordinaqui.com/menu/demo",
    
  });
  const [downloadSizeCm, setDownloadSizeCm] = useState<number>(6);

  const qrContainerRef = useRef<HTMLDivElement>(null);
  const [qrSize, setQrSize] = useState<number>(256);

  // Ref per controllare il primo render
  const isFirstRender = useRef(true);

  // Carica le impostazioni iniziali del QR Code dall'API
  useEffect(() => {
    apiGenQRcode()
      .getList(api)
      .then((data: any) => {
        const stileConverted = data.stile === "square" ? "squares" : data.stile;
        setQrData({
          uselogo: data.uselogo,
          precisionVal: data.precisionVal,
          stile: stileConverted,
          bodycolor: data.bodycolor,
          eyescolor: data.eyescolor,
          eyesradius: data.eyesradius,
          dimension: data.dimension,
          menubaseurl: data.menubaseurl,
          
        });
      })
      .catch(() => {
        notification.error({
          message: "Errore nel caricamento delle impostazioni del QR Code",
          duration: 1.5,
        });
      });
  }, [api]);

  // Regola la dimensione del QR in base alla larghezza del container
  const updateQrSize = () => {
    if (qrContainerRef.current) {
      const containerWidth = qrContainerRef.current.offsetWidth;
      const newSize = Math.min(containerWidth * 0.9, 400);
      setQrSize(newSize);
    }
  };

  useEffect(() => {
    updateQrSize();
    window.addEventListener("resize", updateQrSize);
    return () => {
      window.removeEventListener("resize", updateQrSize);
    };
  }, []);

  // Genera il path dinamico del logo (con cache-busting)
  const logoPath = useMemo(() => {
    if (!qrData.uselogo || !resturant?.ref) return undefined;
    const time = new Date().getTime(); // Cache-busting
    return `/img/logo/${resturant.ref}/${time}`;
  }, [qrData.uselogo, resturant]);

  // Funzione per copiare l'URL negli appunti
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(qrData.menubaseurl)
      .then(() => {
        notification.success({ message: "URL copiato negli appunti!", duration: 1.5 });
      })
      .catch(() => {
        notification.error({ message: "Errore durante la copia dell'URL.", duration: 1.5 });
      });
  };

  // Conversione da cm a pixel
  const cmToPx = (cm: number) => {
    const inches = cm / 2.54;
    return Math.round(inches * 96);
  };

  // Funzione per il download dell'immagine PNG
  const doDownload = (href: string, fileName: string) => {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = href;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("myQrCanvas") as HTMLCanvasElement;
    if (!canvas) {
      notification.error({ message: "Canvas del QR non trovato.", duration: 1.5 });
      return;
    }
    const downloadSizePx = cmToPx(downloadSizeCm);
    const downloadCanvas = document.createElement("canvas");
    downloadCanvas.width = downloadSizePx;
    downloadCanvas.height = downloadSizePx;
    const ctx = downloadCanvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(canvas, 0, 0, downloadSizePx, downloadSizePx);
      const dataUrl = downloadCanvas.toDataURL("image/png");
      doDownload(dataUrl, "QRCode.png");
    }
  };

  // Salvataggio automatico delle impostazioni con debounce,
  // eseguito solo se un valore viene modificato (escludendo il primo render)
  const autoSaveTimeoutRef = useRef<any>(null);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (autoSaveTimeoutRef.current) clearTimeout(autoSaveTimeoutRef.current);
    autoSaveTimeoutRef.current = setTimeout(() => {
      apiGenQRcode()
        .edit(api, qrData)
        .then(() => {
          // Notifica facoltativa per confermare il salvataggio
        })
        .catch(() => {
          notification.error({
            message: "Errore nel salvataggio automatico delle impostazioni",
            duration: 1.5,
          });
        });
    }, 1000);
    return () => {
      if (autoSaveTimeoutRef.current) clearTimeout(autoSaveTimeoutRef.current);
    };
  }, [qrData, api]);
  const {md} = Grid.useBreakpoint()
  return (
    <StandPage isLoading={false} showFilter={false} showRefresh={false}>
            <Typography style={{ paddingLeft: md ? "0%" : "0%" }}>
        <h3>
          <b>Codice QR</b>
        </h3>
      </Typography>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "12px",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {/* Card Sinistra: Anteprima QR Code */}
        <div style={{ flex: "1 1 100%", maxWidth: "400px", boxSizing: "border-box" }}>
          <Card  bordered={false}>
            <div
              ref={qrContainerRef}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <QRCode
                id="myQrCanvas"
                value={qrData.menubaseurl}
                size={qrSize}
                bgColor="#ffffff"
                fgColor={qrData.bodycolor}
                eyeColor={qrData.eyescolor}
                qrStyle={qrData.stile as "squares" | "dots" | "fluid" | undefined}
                eyeRadius={qrData.eyesradius}
                ecLevel={qrData.uselogo ? "H" : precisionValToEcLevel(qrData.precisionVal)}
                // Logo
                logoImage={logoPath}
                logoWidth={logoPath ? qrSize * 0.3 : 0}
                removeQrCodeBehindLogo={true}
                logoPadding={0}
                logoPaddingStyle="square"
              />
            </div>
            <Divider style={{ margin: "20px" }} />
                          {/* Pulsante Download */}
                          <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={downloadQRCode}
                style={{ width: "100%" }}
                size="large"
              >
                Scarica
              </Button>
              <Typography.Title level={5} style={{marginTop:20}}>Il tuo link </Typography.Title>
              {/* URL e copia */}
              <div>
                <Input
                  value={qrData.menubaseurl}
                  readOnly
                  style={{ marginTop: 0, background: "white", color: "black" }}
                  size="large"
                  suffix={
                    <Space>
                      <CopyOutlined onClick={copyToClipboard} style={{ cursor: "pointer" }} />
                      <a href={qrData.menubaseurl} target="_blank" rel="noreferrer">
                        <WhatsAppOutlined onClick={() => {
                    if (window) {
                      window.open(`${waBaseURl}/${qrData.menubaseurl}`, "_blank")?.focus();
                    }
                  }}
                  style={{ cursor: "pointer" , color:"green"}} />
                      </a>
                    </Space>
                  }
                />
              </div>

              {/* Pulsante WhatsApp */}
              <div style={{ textAlign: "center", marginTop:20 }}>
                <Button
                  onClick={() => {
                    if (window) {
                      window.open(`${qrData.menubaseurl}`, "_blank")?.focus();
                    }
                  }}
                  type="primary"
                  icon={<LinkOutlined  />}
                  size="large"
                  style={{
                    
                    width: "100%",
                    
                  }}
                >
                  Visualizza menù
                </Button>
              </div>
          </Card>
        </div>

        {/* Card Destra: Impostazioni */}
        <div style={{ flex: "1 1 100%", maxWidth: "400px", boxSizing: "border-box" }}>
          <Card title="Personalizza il codice QR" bordered={false} style={{ width: "100%" }}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              {/* Usa Logo */}
              <div>
                <Typography.Text strong style={{ marginRight: "2%" }}>
                  Usa Logo:
                </Typography.Text>
                <Switch
                  checked={qrData.uselogo}
                  onChange={(checked) => setQrData({ ...qrData, uselogo: checked })}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </div>

              {/* Precisione (Error Level) */}
              <div>
                <Typography.Text strong>Precisione:</Typography.Text>
                <Slider
                  min={0}
                  max={3}
                  step={1}
                  marks={{ 0: "Bassa", 1: "Media", 2: "Alta", 3: "Massima" }}
                  value={qrData.uselogo ? 3 : (qrData.precisionVal - 1)}
                  disabled={qrData.uselogo}
                  onChange={(val: number) =>
                    !qrData.uselogo && setQrData({ ...qrData, precisionVal: val + 1 })
                  }
                  style={{ marginTop: 10, width: "85%", marginLeft: 20 }}
                  tooltip={{ formatter: null }}
                />
              </div>

              {/* Stile corpo QR (squares / dots) */}
              <div>
                <Typography.Text strong>Stile corpo QR:</Typography.Text>
                <div style={{ marginTop: 8 }}>
                  <Segmented<"squares" | "dots" | "fluid">
                    options={[
                      { label: "Quadrati", value: "squares" },
                      { label: "Punti", value: "dots" },
                      { label: "Fluid", value: "fluid" },
                    ]}
                    onChange={(val) => setQrData({ ...qrData, stile: val })}
                  />
                </div>
              </div>

              {/* Colore corpo & Colore occhi */}
              <div style={{ display: "flex", flexDirection: "row", gap: "20%" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                  <Typography.Text strong>Colore corpo:</Typography.Text>
                  <ColorPicker
                    size="middle"
                    value={qrData.bodycolor}
                    onChange={(v) => setQrData({ ...qrData, bodycolor: v.toHexString() })}
                    showText
                    style={{ width: "100px" }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                  <Typography.Text strong>Colore occhi:</Typography.Text>
                  <ColorPicker
                    size="middle"
                    value={qrData.eyescolor}
                    onChange={(v) => setQrData({ ...qrData, eyescolor: v.toHexString() })}
                    showText
                    style={{ width: "100px" }}
                  />
                </div>
              </div>

              {/* Arrotondamento occhi */}
              <div>
                <Typography.Text strong>Arrotondamento occhi:</Typography.Text>
                <Slider
                  min={0}
                  max={40}
                  step={1}
                  style={{ width: "100%", marginTop: 8 }}
                  value={qrData.eyesradius}
                  onChange={(v) => setQrData({ ...qrData, eyesradius: v })}
                />
              </div>

              {/* Dimensione download */}
              <div>
                <Typography.Text strong>Dimensione Download (cm):</Typography.Text>
                <InputNumber
                  min={MIN_SIZE_CM}
                  max={MAX_SIZE_CM}
                  value={qrData.dimension}
                  onChange={(v) => {
                    const newVal = v || MIN_SIZE_CM;
                    setDownloadSizeCm(newVal);
                    setQrData({ ...qrData, dimension: newVal });
                  }}
                  className="custom-input-number"
                  style={{
                    width: "100%",
                    marginTop: 8,
                    background: "white",
                    color: "black",
                  }}
                  formatter={(value) => `${value} cm`}
                  parser={(value) => Number(value?.replace(" cm", "") || MIN_SIZE_CM)}
                />
                <Typography.Text type="secondary" style={{ display: "block", marginTop: 4 }}>
                  La dimensione influenzerà solo l'immagine scaricata.
                </Typography.Text>
              </div>

              

              
            </Space>
          </Card>
        </div>
      </div>
    </StandPage>
  );
};

export default QrLink;
