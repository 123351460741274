import { useEffect, useState } from "react";
import CommonForm from "../common/CommonForm";
import { apiGenResturant, apiGenTraduzioni } from "../../utils/apiutils";
import { useAuth } from "../../../state/auth/auth";
import { Resturnt } from "../Stile/Branding";
import { LangFlangRender } from "./LangFlangRender";
import { App, Button, Space, Divider, Input, Row, Col } from "antd";

import { LuBrainCircuit } from "react-icons/lu";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";

type props = {
  value: {
    ref: string;
    it: string;
  };
  refresh: () => void;
  close?: () => void;
};

const TabEdit: React.FC<props> = ({ close, value: _value, refresh }) => {
  const [value, setvalue] = useState<any>({});
  const [langEnabled, setLangEnabled] = useState<string[]>([]);

  const { notification } = App.useApp();
  const { api } = useAuth();

  useEffect(() => {
    apiGenResturant()
      .getList(api)
      .then((r) => {
        const l = r as Resturnt;
        if (l.langsEnable) setLangEnabled(JSON.parse(l.langsEnable));
      });
  }, []);

  useEffect(() => {
    setvalue(_value);
  }, [_value]);

  const salva = (word: string, lang: string) => {
    return apiGenTraduzioni().save(api, {
      ref: _value["ref"] as any,
      bb_value: word,
      bb_lang: lang,
    });
  };

  const getFromAi = (word: string, lang: string) => {
    return new Promise<string>((res, rej) => {
      apiGenTraduzioni()
        .extraPost(api, "word", {
          texts: [word],
          source: "it",
          target: lang,
        })
        .then((response: any) => {
          const translations = response.translations;
          if (translations && translations.length > 0) {
            res(translations[0]);
          } else {
            rej("No translation found");
          }
        })
        .catch(rej);
    });
  };

  return (
    <div style={{ padding: "16px" }}>
      {langEnabled.map((l) => (
        <Row
          key={l}
          gutter={16}
          align="middle"
          style={{ marginBottom: "16px", display: "flex", alignItems: "center" }}
        >
          <Col style={{ flex: 1 }}>
            <Input.TextArea
              value={value[l] || ""}
              onChange={(e) =>
                setvalue((prevValue: any) => ({
                  ...prevValue,
                  [l]: e.target.value,
                }))
              }
              disabled={l === "it"}
              placeholder={l === "it" ? "Italiano originale" : `Testo in ${l}`}
              autoSize={{ minRows: 3, maxRows: 6 }}
              style={{ width: "100%" }}
            />
          </Col>
          {l !== "it" ? (
            <Col style={{ flex: "0 0 20%" }}>
              <Button
                size="large"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0 10px",
                  width: "100%",
                }}
                icon={
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LangFlangRender lang={l} />
                  </div>
                }
                onClick={() => {
                  if (value["it"]) {
                    getFromAi(value["it"], l)
                      .then((translation) => {
                        setvalue((prevValue: any) => ({
                          ...prevValue,
                          [l]: translation,
                        }));
                      })
                      .catch((error) => {
                        notification.error({
                          message: `Errore nella traduzione per ${l}`, duration: 1.5,
                          description:
                            error instanceof Error
                              ? error.message
                              : "Errore sconosciuto",
                        });
                      });
                  }
                }}
              >
                Auto
              </Button>
            </Col>
          ) : (
            <Col style={{ flex: "0 0 23%" }} /> // Spazio vuoto per mantenere l'allineamento
          )}
        </Row>




      ))}
      <Divider />
      <Space style={{ width: "100%", justifyContent: "flex-start", marginTop: "16px" }}>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            const filterKey = ["ref", "it"];
            Object.keys(value)
              .filter((v) => !filterKey.includes(v))
              .reduce((p: Promise<any>, e: string) => {
                return p.then(() => {
                  notification.info({
                    icon: <LoadingOutlined />,
                    key: "SALVATAGGIOLANG",
                    message: "Salvataggio traduzione in " + e, duration: 1.5
                  });
                  return salva(value[e], e);
                });
              }, Promise.resolve())
              .then(() => {
                notification.success({
                  key: "SALVATAGGIOLANG",
                  message: "Salvataggio traduzioni riuscito", duration: 1.5
                });
                if (close) close();
                refresh();
              });
          }}
          icon={<SaveOutlined />}
        >
          Salva
        </Button>
      </Space>

    </div>
  );
};

export default TabEdit;
