import { Empty, Typography, theme } from "antd";

const PerCustomer = ({ shares, averageTime }: { shares: number; averageTime: number }) => {
  const { token } = theme.useToken();

  // Funzione per formattare il tempo
  const formatTime = (timeInSeconds: number) => {
    if (timeInSeconds > 60) {
      const minutes = Math.floor(timeInSeconds / 60); // Rimuove la parte decimale
      const seconds = Math.floor(timeInSeconds % 60); // Rimuove la parte decimale
      return `${minutes} minut${minutes > 1 ? 'i' : 'o'} e ${seconds} second${seconds > 1 ? 'i' : 'o'}`;
    }
    return `${Math.floor(timeInSeconds)} second${timeInSeconds === 0 ? 'o' : 'i'}`;
  };

  return (
    <div
      style={{
        background: token.colorBgContainer,
        padding: "20px 25px",
        borderRadius: token.borderRadius,
      }}
    >
     
        <>
          <Typography.Title style={{ margin: 0 }} level={4}>
            Condivisioni Whatsapp <Typography.Title style={{ margin: 0 }}>{shares}</Typography.Title>
          </Typography.Title>
          <br />
          <br />
          <br />
          <Typography.Title style={{ margin: 0 }} level={4}>
            Tempo medio sul menu{" "}
            <Typography.Title style={{ margin: 0 }}>{formatTime(averageTime)}</Typography.Title>
          </Typography.Title>
        </>
      
    </div>
  );
};

export default PerCustomer;
