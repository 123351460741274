import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";

const EASYMENUAUTH = "EASYMENUAUTH";

// Define interfaces for user and response data
export interface User {
  id: string;
  username: string;
  superAdmin?: boolean;
  // Add other user properties as needed
}


export interface ResturantB {
  name: string;
  ref: string;
  // Add other properties as needed
}

interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  expires: number;
  expiresIn: number;
  user: User;
  restutrant: ResturantB;
}

interface AuthContextType {
  user: User | null;
  lastusername?: string;
  resturant: ResturantB | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  sendResetCode: (username: string) => Promise<void>;
  resetPassword: (resetCode: string, password: string) => Promise<void>;
  api: {
    get: (url: string) => Promise<any>;
    post: (url: string, payload: any) => Promise<any>;
    patch: (url: string, payload: any) => Promise<any>;
    uploadImage: (file: File, url: string) => Promise<any>;
  };
}

// Create the AuthContext with a default value
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a custom hook to use the AuthContext
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// AuthProvider component to wrap the app and provide auth methods
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [resturant, setResturant] = useState<ResturantB | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [tokenValidity, setTokenValidity] = useState<number | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [lastusername, setLastusername] = useState("");

  const isTokenValid = (): boolean => {
    if (!tokenValidity) return false;
    const now = new Date().getTime();
    return now < tokenValidity;
  };

  const renewToken = async (): Promise<void> => {
    // Implementa la logica di rinnovo token se necessario
  };

  useEffect(() => {
    const oldauth = localStorage.getItem(EASYMENUAUTH);
    if (oldauth) {
      try {
        const prev: AuthResponse = JSON.parse(oldauth) as AuthResponse;
        const now = new Date().getTime();
        if (prev && prev.expires && now < prev.expires) {
          saveAuth(prev);
        } else {
          setLastusername(prev.user.username);
        }
      } catch (error) {}
    }
  }, []);

  const saveAuth = (a: AuthResponse) => {
    const { accessToken, refreshToken, expires, user, restutrant } = a;
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setTokenValidity(expires);
    setResturant(restutrant);
    setUser(user);
    localStorage.setItem(EASYMENUAUTH, JSON.stringify(a));
    // Imposta il rinnovo del token se necessario
  };

  const login = async (username: string, password: string): Promise<void> => {
    try {
      const response: AxiosResponse<AuthResponse> = await axios.post(
        "/api/auth/login",
        { username, password }
      );
      const ar = response.data;
      localStorage.setItem(EASYMENUAUTH, JSON.stringify(ar));
      saveAuth(ar);
    } catch (error) {
      console.error("Login failed", error);
      throw error;
    }
  };

  const logout = (): void => {
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
    setTokenValidity(null);
    localStorage.removeItem(EASYMENUAUTH);
  };

  const sendResetCode = async (username: string): Promise<void> => {
    try {
      await axios.post("/api/send-reset-code", { username });
    } catch (error) {
      console.error("Failed to send reset code", error);
      throw error;
    }
  };

  const resetPassword = async (resetCode: string, password: string): Promise<void> => {
    try {
      await axios.post("/api/reset-password", { resetCode, password });
    } catch (error) {
      console.error("Failed to reset password", error);
      throw error;
    }
  };

  const apiRequest = async (
    method: "POST" | "GET" | "PATCH",
    url: string,
    payload: any = null
  ): Promise<any> => {
    if (!isTokenValid()) {
      await renewToken();
    }
    if (!accessToken) throw new Error("No access token available");
    const headers = { Authorization: `Bearer ${accessToken}` };
    try {
      if (method === "POST") {
        const response = await axios.post(`/api/p/${url}`, payload, { headers });
        return response.data;
      } else if (method === "PATCH") {
        const response = await axios.patch(`/api/p/${url}`, payload, { headers });
        return response.data;
      } else {
        const response = await axios.get(`/api/p/${url}`, { headers });
        return response.data;
      }
    } catch (error) {
      console.error("API request failed", error);
      throw error;
    }
  };

  const uploadImage = (file: File, url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(`/api/p/${url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) =>
          reject(`Error uploading file: ${error.response?.data || error.message}`)
        );
    });
  };

  const api = {
    get: (url: string) => apiRequest("GET", url),
    post: (url: string, payload: any) => apiRequest("POST", url, payload),
    patch: (url: string, payload: any) => apiRequest("PATCH", url, payload),
    uploadImage,
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        sendResetCode,
        resetPassword,
        api,
        resturant,
        lastusername,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const setCookie = (
  name: string,
  value: string,
  days: number,
  path: string = "/"
) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${value};${expires};path=${path}`;
};

export default AuthProvider;
