import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import ReactDOMServer from "react-dom/server";
import { Empty, Typography, theme } from "antd";
import { LangFlangRender } from "../Traduzioni/LangFlangRender";

const { useToken } = theme;

type LangStats = {
  language: string;
  count: number;
};

interface TotaliProps {
  totali: LangStats[];
  totalViews: number;
}

const Totali: React.FC<TotaliProps> = ({ totali, totalViews }) => {
  const { token } = useToken();
  const chartRef = useRef<HTMLDivElement>(null);

  // Converte la bandiera (SVG) in base64
  const getFlagBase64 = (lang: string): string => {
    const flagSVG = ReactDOMServer.renderToString(
      <LangFlangRender lang={lang.toLowerCase()} />
    );
    return `data:image/svg+xml;base64,${btoa(flagSVG)}`;
  };

  useEffect(() => {
    if (!chartRef.current) return;

    const myChart = echarts.init(chartRef.current);

    // Mappa codice => base64 bandiera
    const iconMap: Record<string, string> = totali.reduce((acc, item) => {
      const upperLang = item.language.toUpperCase();
      acc[upperLang] = getFlagBase64(item.language);
      return acc;
    }, {} as Record<string, string>);

    // Esempio di palette colori
    const colorPalette = [
      "#5470C6",
      "#91CC75",
      "#EE6666",
      "#FAC858",
      "#73C0DE",
      "#3BA272",
      "#FC8452",
      "#9A60B4",
      "#EA7CCC",
    ];

    // -----------------------------
    // Serie #1: fette colorate con count (valore) all'interno
    // -----------------------------
    const seriesDataCounts = totali.map((item, index) => {
      const lang = item.language.toUpperCase();
      return {
        // Il name DEVE combaciare col name della legenda
        name: lang,
        value: item.count,
        itemStyle: { color: colorPalette[index % colorPalette.length] },
        label: {
          show: true,
          position: "inside" as const,
          formatter: "{c}", // Mostra il valore numerico
        },
      };
    });

    // -----------------------------
    // Serie #2: trasparente, label esterna con bandiera + codice nazione
    // e collegamento con linea
    // -----------------------------
    const seriesDataFlags = totali.map((item) => {
      const lang = item.language.toUpperCase();
      return {
        name: lang, // deve corrispondere al name in serie e legenda
        value: item.count,
        label: {
          show: true,
          position: "outside" as const,
          formatter: () => `{flag|} {code|${lang}}`,
          rich: {
            flag: {
              backgroundColor: { image: iconMap[lang] },
              width: 20,
              height: 15,
            },
            code: {
              fontSize: 14,
              color: token.colorText,
              backgroundColor: "transparent",
            },
          },
        },
      };
    });

    // -----------------------------
    // Creiamo la lista per la legenda.
    //  - name = "IT", "EN", ...
    //  - icon = SVG personalizzato (cerchio colorato + bandiera)
    // -----------------------------
    const legendData = seriesDataCounts.map((item) => {
      const lang = item.name;
      const color = item.itemStyle.color;
      const flag = iconMap[lang] || "";

      // Creiamo un piccolo SVG 40x20
      const svg = `
        <svg width="40" height="20" xmlns="http://www.w3.org/2000/svg">
          <!-- Cerchio colorato -->
          <circle cx="10" cy="10" r="5" fill="${color}" />
          <!-- Bandiera a destra -->
          <image href="${flag}" x="20" y="2" width="16" height="16" />
        </svg>
      `;

      const customIcon = "image://" + `data:image/svg+xml;base64,${btoa(svg)}`;
      return {
        name: lang, // deve combaciare con name in series
        icon: customIcon,
      };
    });

    // -----------------------------
    // Configurazione ECharts
    // -----------------------------
    const option: echarts.EChartsOption = {
      tooltip: {
        trigger: "item",
      },
      // Legenda in posizione fissa (per desktop):
      legend: {
        top: 60,
        left: "left",
        orient: "vertical",
        itemWidth: 60,
        itemHeight: 30,
        itemGap: 1,
        // Definiamo 'rich' per gestire il grassetto del codice nazione
        textStyle: {
          color: token.colorText,
          
          rich: {
          
            normal: {
              fontWeight: "normal",
              color: token.colorText,
              fontSize: 16,
            },
          },
        },
        data: legendData,
        // 1) Legenda formatter: calcolo percent su totalViews
        // 2) Metto in grassetto solo il codice nazione, lasciando la percentuale normale.
        formatter: (name) => {
          // Cerchiamo i dati associati
          const found = totali.find(
            (x) => x.language.toUpperCase() === name
          );
          if (!found) {
            // fallback
            return name;
          }
          const val = found.count;
          const percent = totalViews
            ? ((val / totalViews) * 100).toFixed(0)
            : "0";
          
          return `{normal|${name}:} {normal| ${percent}%}`
        },
      },
      series: [
        // Serie #1: colorata con count inside
        {
          name: "",
          type: "pie",
          radius: ["40%", "70%"],
          center: ["50%", "50%"],
          avoidLabelOverlap: true,
          labelLine: { show: false },
          label: {
            fontSize:16,
            fontFamily: token.fontFamily,
            fontWeight: "bold"
          },
          itemStyle:{borderWidth: 2},
          data: seriesDataCounts as any,
        },
        // Serie #2: trasparente, label outside (bandiera + codice),
        // collegata con una linea
        {
          name: "SerieFlags",
          type: "pie",
          radius: ["40%", "70%"],
          center: ["50%", "50%"],
          avoidLabelOverlap: true,
          silent: true,
          itemStyle: {
            color: "transparent",
            borderColor: "transparent",
            borderWidth: 2
          },
          
          labelLine: {
            show: true,
            length: 20,
            length2: 10,
            
            lineStyle: {
              color: "#888",
              width: 1,
            },
          },
   
          data: seriesDataFlags as any,
        },
      ],
      // --------------- Responsiveness con 'media' ---------------
      // Quando la larghezza del container < 768px, sposta la legenda sotto il grafico
      media: [
        {
          query: { maxWidth: 600 },
          option: {
            legend: {
              top: 380,
              left: "center",
              orient: "horizontal",
              
            },
            // Se vuoi che il grafico si sposti più in alto, modifica center
            series: [
              { center: ["45%", "35%"] },
              { center: ["45%", "35%"] },
            ],
          },
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [totali, token, totalViews]);

  return (
    <div
      style={{
        background: token.colorBgContainer,
        padding: "20px 25px",
        borderRadius: token.borderRadius,
      }}
    ><div style={{width:"180px"}}>
      <Typography.Title style={{ margin: 0 }} level={4}>
        Visualizzazioni
      </Typography.Title>
      <Typography.Title level={1} style={{ margin: 0 , fontSize: "60px"}}>{totalViews}</Typography.Title>
      </div>
      {totali.length > 0 ? (
        <div
          ref={chartRef}
          style={{ width: "100%", height: "500px", paddingTop: "2px" }}
        />
      ) : (
        <Empty style={{ width: "100%", height: "400px", alignContent:"center"}}/>

      )}
    </div>
  );
};

export default Totali;
