import * as base from "./base";

const apiGenIng = () => {
  return new base.baseEntityApi("ingredienti");
};

const apiGenAller = () => {
  return new base.baseEntityApi("allergeni");
};

const apiGenItem = () => {
  return new base.baseEntityApi("item");
};

const apiGenItemAvailability = () => {
  return new base.baseEntityApi("itemavailability");
};

const apiGenItemGroup = () => {
  return new base.baseEntityApi("itemgroup");
};

const apiGenTag = () => {
  return new base.baseEntityApi("tag");
};

const apiGenResturant = () => {
  return new base.baseEntityApi("resturant");
};

const apiGenUser = () => {
  return new base.baseEntityApi("user");
};

const apiGenQRcode = () => {
  return new base.baseEntityApi("qrcode");
};

const apiGenStats = () => {
  return new base.baseEntityApi("stats");
};

const apiGenTraduzioni = (): base.baseEntityApi & {
  getItems: (api: any) => Promise<any>;
  getGroups: (api: any) => Promise<any>;
  getIngredients: (api: any) => Promise<any>;
} => {
  const apiBase = new base.baseEntityApi("translate");

  // Uniamo i metodi esistenti e quelli aggiuntivi
  return Object.assign(Object.create(Object.getPrototypeOf(apiBase)), apiBase, {
    getItems: (api: any) => apiBase.extraGet(api, "item"),
    getGroups: (api: any) => apiBase.extraGet(api, "group"),
    getIngredients: (api: any) => apiBase.extraGet(api, "ingredient"),
  });
};

export default apiGenTraduzioni;


export {
  base,
  apiGenIng,
  apiGenAller,
  apiGenItem,
  apiGenItemGroup,
  apiGenItemAvailability,
  apiGenResturant,
  apiGenStats,
  apiGenTraduzioni,
  apiGenTag,
  apiGenQRcode,
  apiGenUser,
};
