import React from "react";

import {
  US,
  IT,
  ES,
  FR,
  DE,
  NL,
  PL,
  CH,
  RU,
  JP,
  KR,
  SV,
  CZ,
  NO,
  DK,
  FI,
  GR,
  TR,
  UA,
  HTMLSVGElement,
  CN,
  AL,
  RO,
  AR,
  SA,
  MO,
  MA,
  SE,
} from "country-flag-icons/react/3x2";
import { Typography } from "antd";

import "./css.css";

type props = {
  lang: string;
};

interface ElementAttributes<T>
  extends React.HTMLAttributes<T>,
    React.SVGAttributes<T> {}

interface Props extends ElementAttributes<HTMLSVGElement> {}

const props: Props = {
  height: 30,
};

const mappa: any = {
  ar :<SA {...props}/>,
  ro: <RO {...props}/>,
  sq: <AL {...props} />,
  it: <IT {...props} />,
  fr: <FR {...props} />,
  en: <US {...props} />,
  es: <ES {...props} />,
  de: <DE {...props} />,
  nl: <NL {...props} />,
  pl: <PL {...props} />,
  zh: <CN {...props} />,
  ru: <RU {...props} />,
  ja: <JP {...props} />,
  ko: <KR {...props} />,
  sv: <SE {...props} />,
  cs: <CZ {...props} />,
  nb: <NO {...props} />,
  da: <DK {...props} />,
  fi: <FI {...props} />,
  el: <GR {...props} />,
  tr: <TR {...props} />,
  uk: <UA {...props} />,
};

export const LangFlangRender: React.FC<props> = ({ lang }) => {
  const ret: any = mappa[lang] ? (
    mappa[lang]
  ) : (
    <Typography.Text>{lang}</Typography.Text>
  );
  return ret;
};
