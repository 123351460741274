import { App, Button, Input } from "antd";
import { useState } from "react";
import { AuthComp } from ".";
import axios from "axios";

const ResetPassPage = () => {
  const [valueusername, setValueusername] = useState("");

  const { notification } = App.useApp();

  const submit = () => {
    axios
      .post("/api/auth/reset", {
        username: valueusername,
      })
      .then((e) => {
        notification.success({
          message: "Reset Richiesto, controllare la propria casella email", duration: 1.5
        });
      })
      .catch((e) => {
        notification.error({
          message: "Errore controllare l'input", duration: 1.5
        });
      });
  };

  return (
    <div>
      <AuthComp
        submitbt={
          <Button shape="round" size="large" onClick={submit} type="primary" style={{ marginTop: "15px" }}>
            Richiedi reset
          </Button>
        }
        input={[
          {
            lb: "",
            el: (
              <Input
              size="large"
                style={{ marginTop: "16px" , backgroundColor:"white"}}
                placeholder="Inserisci username o email"
                onPressEnter={submit}
                value={valueusername}
                onChange={(e) => setValueusername(e.target.value)}
              />
            ),
          },
        ]}
      ></AuthComp>
    </div>
  );
};

export default ResetPassPage;
