// CommonForm.tsx
import {
  Checkbox,
  Col,
  ColorPicker,
  Input,
  InputNumber,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import CategoryPicker from "./CategoryPicker";
import FontFamilyPicker from "./custominputs/FontFamilyPicker";
import LangPicker from "./custominputs/LangsEnabled";
import { EditOutlined, TagsFilled } from "@ant-design/icons";
import TagPicker from "./TagPicker";
import { Articolo } from "../Articoli/tabella";

export type typeinput =
  | "check"
  | "string"
  | "price"
  | "text"
  | "category"
  | "color"
  | "number"
  | "Langs"
  | "tags"
  | "fontfamily";

export type formEl = {
  type: typeinput;
  disabled?: boolean;
  key: string;
  label: string | React.ReactNode;
  extra?: React.ReactNode;
};

export type props = {
  value: any;
  onChange: (v: any) => void;
  elements: formEl[];
  extra?: { label: string; children: React.ReactNode }[];
 
};

export const SingleInput: React.FC<{
  element: formEl;
  value: any;
  onChange: (v: any) => void;
  bulkMode?: boolean;
  
}> = ({ element: el, value, onChange: _onChange, bulkMode = false }) => {
  // Se in bulkMode, non impostiamo default per il "price"
  const [lvalue, setLvalue] = useState<any>(
    bulkMode ? value : el.type === "price" ? "0" : value
  );
  
  const onChange = (nv: any) => {
    setLvalue(nv);
    _onChange(nv);
  };

  useEffect(() => {
    if (!bulkMode) {
      if (el.type === "price" && (value === undefined || value === null)) {
        setLvalue("0");
      } else {
        setLvalue(value);
      }
    } else {
      // In bulk mode, non forziamo un default
      setLvalue(value);
    }
  }, [value, el.type, bulkMode]);

  let inp = <></>;

  switch (el.type) {
    case "string": {
      inp = (
        <div style={{ justifyContent: "center", alignContent: "center" }}>
          <Input
            addonAfter={<EditOutlined />}
            value={lvalue}
            disabled={!!el.disabled}
            onChange={(e) => onChange(e.target.value)}
            style={{ marginBottom: "20px", marginTop: "5px" }}
          />
        </div>
      );
      break;
    }
    case "color": {
      inp = (
        <ColorPicker
          disabled={!!el.disabled}
          value={lvalue ? lvalue : "#ff0000"}
          onChange={(v, h) => onChange(h)}
          size="large"
          style={{ width: "150px", border: "0px" }}
          showText
        />
      );
      break;
    }
    case "tags": {
      // value deve essere un array di Tag
      inp = (
        <TagPicker
          value={Array.isArray(lvalue) ? lvalue : []}
          onChange={(newTags) => onChange(newTags)}
        />
      );
      break;
    }
    case "fontfamily": {
      inp = <FontFamilyPicker value={lvalue} onChange={onChange} />;
      break;
    }

    case "text": {
      inp = (
        <Input.TextArea
          disabled={!!el.disabled}
          value={lvalue}
          onChange={(e) => onChange(e.target.value)}
          style={{ marginBottom: "20px", marginTop: "5px" }}
        />
      );
      break;
    }
    case "check": {
      inp = (
        <Checkbox
          disabled={!!el.disabled}
          checked={lvalue === true}
          onChange={(e) => onChange(e.target.checked)}
          style={{ marginBottom: "20px" }}
        />
      );
      break;
    }
    
    case "category": {
      inp = (
        <div>
          <CategoryPicker
            value={lvalue}
            onChange={(e) => onChange(e)}
          />
        </div>
      );
      break;
    }
    case "price": {
      inp = (
        <InputNumber<string>
          style={{ width: 200, marginBottom: "20px", marginTop: "5px" }}
          min="0"
          value={lvalue ? lvalue.toString() : (bulkMode ? "" : "0")}
          addonBefore={"€"}
          step="0.01"
          onChange={(e) =>
            onChange(e ? (Number.isNaN(Number(e)) ? "0" : e) : (bulkMode ? undefined : "0"))
          }
          stringMode
          defaultValue="0.00"
        />
      );
      break;
    }
    case "number": {
      inp = (
        <InputNumber
          style={{ marginBottom: "20px", marginTop: "5px" }}
          width={200}
          disabled={!!el.disabled}
          value={lvalue}
          onChange={(e) => onChange(e)}
        />
      );
      break;
    }
    default:
      break;
  }

  return inp;
};

const CommonForm: React.FC<props> = ({ value, onChange, elements, extra }) => {
  const ret: React.ReactNode[] = [];
  
  elements.forEach((el, i) => {
    ret.push(
      <Col span={el.type === "check" ? 12 : 24} key={el.key}>
        <div key={el.key}>
          <div>
            <Typography.Text strong>{el.label}</Typography.Text>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              gap: 10,
            }}
          >
            <SingleInput
            
              onChange={(e) => {
                const oldv = { ...value };
                oldv[el.key] = e;
                onChange(oldv);
              }}
              value={value[el.key]}
              element={el}
            />
            {el.extra ? el.extra : <></>}
            <p></p>
          </div>
        </div>
      </Col>
    );
  });

  extra?.forEach((el, i) => {
    ret.push(
      <Col span={24} key={"extra" + i}>
        <div>
          <div><Typography.Text strong>{el.label}</Typography.Text></div>
          <div>{el.children}</div>
        </div>
      </Col>
    );
  });

  return (
    <div>
      <Row gutter={[16, 16]}>{ret}</Row>
    </div>
  );
};

export default CommonForm;
