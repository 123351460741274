import React, { useEffect, useMemo, useState } from "react";
import { allSuppLangs } from "../../Traduzioni/Tabella";
import { Checkbox, Col, Row, Typography, Grid, notification, Button } from "antd";
import { LangFlangRender } from "../../Traduzioni/LangFlangRender";
import { useAuth } from "../../../../state/auth/auth";
import { apiGenResturant } from "../../../utils/apiutils";

export type Props = {
  langsEnable: string; // Stringa JSON (es. '["it", "en"]')
};

const LangPicker: React.FC = () => {
  const { md } = Grid.useBreakpoint();
  const { api } = useAuth();
  
  // Stato per il campo langsEnable (in formato JSON)
  const [resturant, setResturant] = useState<Props>({
    langsEnable: '["it", "eng"]',
  });

  // Carica i dati dal backend solo al montaggio
  const refresh = () => {
    apiGenResturant()
      .getList(api)
      .then((e) => setResturant(e as Props))
      .catch(() =>
        notification.error({ message: "Errore durante il caricamento!", duration: 1.5 })
      );
  };

  useEffect(() => {
    refresh();
  }, []); // Eseguito solo una volta al montaggio

  // Salva le impostazioni tramite API
  const save = () => {
    apiGenResturant()
      .edit(api, resturant)
      .then((e) => {
        setResturant(e as Props);
        notification.success({ message: "Impostazioni salvate!", duration: 1.5 });
      })
      .catch(() =>
        notification.error({ message: "Errore durante il salvataggio!", duration: 1.5 })
      );
  };

  // Converte la stringa JSON in un array
  const checkArray: string[] = useMemo(() => {
    if (resturant.langsEnable) {
      try {
        return JSON.parse(resturant.langsEnable);
      } catch (error) {
        return [];
      }
    }
    return [];
  }, [resturant.langsEnable]);

  // Aggiorna lo stato del campo langsEnable
  const handleLangChange = (newLangArray: string[]) => {
    setResturant((prev) => ({ ...prev, langsEnable: JSON.stringify(newLangArray) }));
  };

  return (
    <div>
      <Row gutter={[24, 24]}>
        {allSuppLangs.map((l) => {
          const isCheck = checkArray.some((e) => e === l);
          return (
            <Col span={8} key={l}>
              <Checkbox
                checked={isCheck}
                onChange={() => {
                  if (isCheck) {
                    handleLangChange(checkArray.filter((e) => e !== l));
                  } else {
                    handleLangChange([...checkArray, l]);
                  }
                }}
                value={l}
              >
                <LangFlangRender lang={l} />
                {md && (
                  <Typography.Text style={{ fontSize: "35px", marginLeft: 8 }} type="secondary" strong>
                    {l}
                  </Typography.Text>
                )}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
      <Button type="primary" onClick={save} style={{ marginTop: 16 }}>
        Salva
      </Button>
    </div>
  );
};

export default LangPicker;
