import { useEffect, useState, useCallback } from "react";
import { Tabs, Typography, Grid } from "antd";
import Tabella, { datumDic, TranslateButton } from "./Tabella";
import { apiGenTraduzioni } from "../../utils/apiutils";
import { useAuth } from "../../../state/auth/auth";
import StandPage from "../common/StandPage";

const { TabPane } = Tabs;

const Traduzioni = () => {
  const [items, setItems] = useState<datumDic[]>([]);
  const [groups, setGroups] = useState<datumDic[]>([]);
  const [ingredients, setIngredients] = useState<datumDic[]>([]);
  const [filter, setFilter] = useState("");
  const [isTranslating, setIsTranslating] = useState(false);
  const [hasPendingTranslations, setHasPendingTranslations] = useState(false);

  // NEW: track how many rows are selected in the *active* table.
  // You can adapt if you need a combined count from all tabs, but typically 
  // you’d keep track of the active tab’s selection.
  const [selectedRowsCount, setSelectedRowsCount] = useState<number>(0);

  // This will hold the actual "translateAll" function from whichever Tabella 
  // is currently active.
  const [translateAllFn, setTranslateAllFn] = useState<(() => Promise<void>) | null>(null);

  const { api } = useAuth();

  // Fetch data from APIs
  const fetchItems = () => {
    apiGenTraduzioni()
      .getItems(api)
      .then((data) => setItems(data as datumDic[]));
  };

  const fetchGroups = () => {
    apiGenTraduzioni()
      .getGroups(api)
      .then((data) => setGroups(data as datumDic[]));
  };

  const fetchIngredients = () => {
    apiGenTraduzioni()
      .getIngredients(api)
      .then((data) => setIngredients(data as datumDic[]));
  };

  const refresh = () => {
    fetchItems();
    fetchGroups();
    fetchIngredients();
  };

  useEffect(() => {
    refresh();
  }, []);

  // We'll receive each Tabella's custom "translate" function in here,
  // and store it for the parent button to invoke.
  const handleTranslateAll = useCallback((translateFn: () => Promise<void>) => {
    setTranslateAllFn(() => translateFn);
  }, []);

  const md = Grid.useBreakpoint();

  return (
    <>
      <Typography style={{ paddingLeft: md ? "5%" : "2%" }}>
        <h3>
          <b>Traduzioni</b>
        </h3>
      </Typography>
      <StandPage
        extraBt={
          <TranslateButton
            onTranslate={translateAllFn || (() => Promise.resolve())}
            isTranslating={isTranslating}
            hasPendingTranslations={hasPendingTranslations}
            selectedRowsCount={selectedRowsCount} // NEW
          />
        }
        isLoading={false}
        refresh={refresh}
        showFilter
        showRefresh
        onFilterChange={setFilter}
        showLangs={true}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Articoli" key="1">
            <Tabella
              filter={filter}
              dic={items}
              refresh={fetchItems}
              setIsTranslating={setIsTranslating}
              setHasPendingTranslations={setHasPendingTranslations}
              onTranslateAll={handleTranslateAll}
              // onSelectionChange callback will keep track of how many are selected
              onSelectionChange={setSelectedRowsCount}
            />
                        < br></br>
            < br></br>
            < br></br>
          </TabPane>
          <TabPane tab="Categorie" key="2">
            <Tabella
              filter={filter}
              dic={groups}
              refresh={fetchGroups}
              setIsTranslating={setIsTranslating}
              setHasPendingTranslations={setHasPendingTranslations}
              onTranslateAll={handleTranslateAll}
              onSelectionChange={setSelectedRowsCount}
            />
            < br></br>
            < br></br>
            < br></br>
          </TabPane>
          <TabPane tab="Ingredienti" key="3">
            <Tabella
              filter={filter}
              dic={ingredients}
              refresh={fetchIngredients}
              setIsTranslating={setIsTranslating}
              setHasPendingTranslations={setHasPendingTranslations}
              onTranslateAll={handleTranslateAll}
              onSelectionChange={setSelectedRowsCount}
            />
            < br></br>
            < br></br>
            < br></br>
          </TabPane>
        </Tabs>
      </StandPage>
    </>
  );
};

export default Traduzioni;
