import React, { useEffect, useState } from "react";
import { Table, Switch, message, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import { Ingrediente } from "../tabella";
import { apiGenAller, apiGenIng } from "../../../utils/apiutils";
import { useAuth } from "../../../../state/auth/auth";
import { LoadingOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Allergeno } from "../../Allergeni/tabella";

type props = {
  ingrediente: Ingrediente;
  open?: boolean;
};

const AssociateAllergeni: React.FC<props> = ({ ingrediente, open }) => {
  const [ings, setIngs] = useState<Allergeno[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { api } = useAuth();

  const refresh = () => {
    setLoading(true);
    apiGenIng()
      .getEntity(api, ingrediente.ref)
      .then((a) => {
        const fullEntity = a as Ingrediente;
        setSelected(fullEntity.allergeni || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open !== undefined && open) {
      refresh();
    }
  }, [open]);

  useEffect(() => {
    apiGenAller()
      .getList(api)
      .then((l) => {
        setIngs(l as Allergeno[]);
      })
      .catch(() => {
        message.error("Errore nel caricamento degli allergeni.");
      });
  }, [api]);

  const handleSwitchChange = (checked: boolean, record: Allergeno) => {
    if (checked) {
      apiGenAller()
        .relateEntity(api, record, ingrediente)
        .then(() => {
          setSelected((prev) => [...prev, record.ref]);
        })
        .catch(() => {
          message.error(`Errore nell'associare l'allergeno "${record.name}".`);
        });
    } else {
      apiGenAller()
        .unrelateEntity(api, record, ingrediente)
        .then(() => {
          setSelected((prev) => prev.filter((ref) => ref !== record.ref));
        })
        .catch(() => {
          message.error(`Errore nel dissociare l'allergeno "${record.name}".`);
        });
    }
  };

  const columns: ColumnsType<Allergeno> = [
    {
      title: "Allergeno",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "associa",
      key: "associa",
      render: (_: any, record: Allergeno) => {
        const checked = selected.includes(record.ref);
        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={checked}
            onChange={(checked) => handleSwitchChange(checked, record)}
          />
        );
      },
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Table
        pagination={false}
          showHeader={false}
          dataSource={ings}
          columns={columns}
          rowKey="ref"
          bordered
        />
      )}
    </div>
  );
};

export default AssociateAllergeni;
