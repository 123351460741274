import React, { useEffect, useMemo, useRef } from "react";
import * as echarts from "echarts";
import { Empty, theme, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";

/**
 * Tipo del "stats" che ricevi in props:
 * {
 *   months: Array<{
 *     year: number;
 *     month: number;  // 1-based => 1 = Gennaio
 *     count: number;
 *   }>
 * }
 *
 * min, max => timestamp (millisecondi)
 */

const format = "MMMM/YY";

const PerMese = ({
  stats,
  min: ini,
  max: fin,
}: {
  stats: { months: { year: number; month: number; count: number }[] };
  min: number;
  max: number;
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const { token } = theme.useToken();

  /**
   * Restituisce un Dayjs impostato su {year, month} (attenzione al -1 su month).
   */
  const getMonthDayjs = (y: number, m: number) => {
    // Esempio: y=2025, m=1 => (2025-01-xx) => Gennaio 2025
    return dayjs().set("year", y).set("month", m - 1).startOf("month");
  };

  /**
   * useMemo per creare i dati formattati per ECharts:
   * - Trova il mese min e max considerando sia i parametri (ini, fin)
   *   che i dati in stats.months.
   * - Inizializza ogni mese del range a 0.
   * - Somma i count di stats.months nel rispettivo mese.
   * - Crea un array { mese: string, visualizzazioni: number }.
   */
  const data = useMemo(() => {
    // 1) Calcoliamo min e max su base "year, month" e su base ini/fin
    const minTimestamp = Math.min(
      ...stats.months.map((m) => getMonthDayjs(m.year, m.month).valueOf()),
      ini
    );
    const maxTimestamp = Math.max(
      ...stats.months.map((m) => getMonthDayjs(m.year, m.month).valueOf()),
      fin
    );

    // 2) Creiamo una mappa { "Gennaio/25": 0, "Febbraio/25": 0, ... }
    //    che copra tutti i mesi da minTimestamp a maxTimestamp
    const map: Record<string, number> = {};
    let runner: Dayjs = dayjs(minTimestamp).startOf("month");
    const end: Dayjs = dayjs(maxTimestamp).endOf("month");

    while (!runner.isAfter(end)) {
      map[runner.format(format)] = 0;
      runner = runner.add(1, "month");
    }

    // 3) Sommiamo i count di stats.months
    stats.months.forEach((m) => {
      const d = getMonthDayjs(m.year, m.month); // Dayjs del mese
      // Consideriamo il dato solo se ricade nel range [minTimestamp, maxTimestamp]
      if (d.valueOf() >= minTimestamp && d.valueOf() <= maxTimestamp) {
        const key = d.format(format);
        if (map[key] !== undefined) {
          map[key] += m.count;
        }
      }
    });

    // 4) Convertiamo la mappa in un array e lo ritorniamo
    return Object.keys(map).map((k) => ({
      mese: k,
      visualizzazioni: map[k],
    }));
  }, [stats.months, ini, fin]);

  /**
   * useEffect: Inizializza ECharts e imposta le opzioni (stile invariato).
   */
  useEffect(() => {
    if (!chartRef.current) return;

    const chart = echarts.init(chartRef.current);

    const option: echarts.EChartsOption = {
      xAxis: {
        type: "category",
        data: data.map((d) => d.mese),
        axisLabel: {
          fontSize: 13,
          interval: 0,
          rotate: 40,
          padding: 1,
          color: token.colorText,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          fontSize: 13,
          color: token.colorText,
        },
      },
      series: [
        {
          data: data.map((d) => d.visualizzazioni),
          type: "line",
          smooth: true,
          lineStyle: {
            width: 4,
            color: token.colorPrimary,
          },
          symbol: "circle",
          symbolSize: 6,
          itemStyle: {
            color: "white",
          },
        },
      ],
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          const item = params[0];
          return `${item.name}: ${item.value}`;
        },
        borderColor: token.colorBorder,
      },
      grid: {
        left: "2%",
        right: "2%",
        bottom: "5%",
        containLabel: true,
      },
    };

    chart.setOption(option);

    // Pulizia quando il componente viene smontato
    return () => {
      chart.dispose();
    };
  }, [data, token]);

  /**
   * Render: se stats.months.length > 0, mostriamo il grafico,
   * altrimenti <Empty>.
   */
  return (
    <div
      style={{
        background: token.colorBgContainer,
        padding: "20px 25px",
        borderRadius: token.borderRadius,
      }}
    >
      <Typography.Title style={{ margin: 0 }} level={4}>
        Visualizzazioni mese
      </Typography.Title>
      <div>
        {stats.months.length > 0 ? (
          <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
        ) : (
          <Empty
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PerMese;
