import { useEffect, useState, useMemo } from "react";
import { Articolo } from "../tabella";
import { useAuth } from "../../../../state/auth/auth";
import { apiGenItemAvailability } from "../../../utils/apiutils";
import { Popover, Typography } from "antd";

import { Segmented } from "antd";
import AvailabilityTable from "./AvalabilityTable";
import { TT } from "country-flag-icons/react/3x2";

type props = {
  item: Articolo;
};

export type itemtimprops = {
  ref: string;
  name: string;
  isTemp: boolean;
  start: number;
  end: number;
  days: string;
  timestart: string;
  timeend: string;
  active: boolean;
  price: number;
  itemref: string;
};

const ManageAvailability: React.FC<props> = ({ item }) => {
  const [allAvalability, setallAvalability] = useState<itemtimprops[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  //const [mode, setmode] = useState<"Sempre" | "Regole">("Sempre");

  const { api } = useAuth();

  const mode = useMemo(() => {
    let ret: "Sempre" | "Regole" = "Sempre";

    ret = allAvalability.some((e) => e.active) ? "Regole" : "Sempre";

    return ret;
  }, [allAvalability]);

  const refresh = () => {
    setLoading(true);
    apiGenItemAvailability()
      .extraGet(api, `byItem/${item.ref}`)
      .then((l) => {
        setallAvalability(l as itemtimprops[]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (item) {
      refresh();
    }
  }, [item]);

  return (
    <div>
          <div style={{marginBottom:"40px"}}>
          <Typography.Title style={{ marginTop:"0px"}} level={4}>Prezzi automatici</Typography.Title>
          <Typography.Text type="secondary" >Crea regole per rendere i prezzi dei tuoi articoli dinamici</Typography.Text>
          </div>
      <div>
        <AvailabilityTable
          list={allAvalability}
          refresh={refresh}
          isloading={loading}
          item={item}
        />
      </div>
    </div>
  );
};

export default ManageAvailability;
