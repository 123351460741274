import { Grid, Tabs, theme, Typography } from "antd";
import LoginCont from "./login";
import ResetPassPage from "./resetpas";
import ResetCodePage from "./resetcode";
import React, { FC } from "react";

import imgsrc from "../../img/Group.png";
import imgsrcbk from "../../img/Groupbk.png";
import imgsrccol from "../../img/Groupcol.png";
import { getDarkmodeFromStorage } from "../../App";

const AuthenticationPage = () => {
  const { md } = Grid.useBreakpoint();
  const { token } = theme.useToken();
  const { Text } = Typography;

  const isdk = getDarkmodeFromStorage();

  return (
    <>
<div
  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: md ? token.colorBgContainer : "",
    padding: md ? "10px 20px 20px 20px" : "0 20px",
    borderRadius: md ? token.borderRadius : "",
    boxShadow: md ? token.boxShadow : "",
  }}
>
      <div style={{ padding: "40px 0" }}>
        {/* 
        <Typography.Title level={2}></Typography.Title> */}
        <img src={isdk ? imgsrccol : imgsrccol} height={140} />
      </div>
      <Tabs size="large"
        items={[
          {
            label: <Text strong >Login</Text>,
            children: <LoginCont />,
            key: "login",
          },

          {
            label: <Text strong>Reset Password</Text>,
            children: <ResetPassPage />,
            key: "pass",
          },
        ]}
      />
    </div>
    <div style={{
    
    bottom: 0,
    left: 0,
    color:"gray",
    width: "100%",
 
    padding: "10px", // Facoltativo, per aggiungere un po' di spazio
    textAlign: "center", // Facoltativo, per centrare il contenuto
  }}>
    <br></br>
    <br></br><p >OrdinaQui - La soluzione digitale per il tuo locale</p>
    <p >©2024 OrdinaQui. Tutti i diritti riservati.</p>
    <p><a style={{color: "gray"}} href="mailto:support@ordinaqui.com">Email: support@ordinaqui.com</a> | <a style={{color: "gray"}} href="tel:0280886019">Tel: 02 8088 6019</a></p>
  

  </div>
    </>
  );
};

export default AuthenticationPage;

export const AuthComp: FC<{
  input: { lb: string; el: React.ReactNode }[];
  submitbt: React.ReactNode;
}> = ({ input, submitbt }) => {
  const { md } = Grid.useBreakpoint();

  return (
    <div
      style={{
        width: md ? 400 : "calc(100vw - 40px)",
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: 10,
      }}
    >
      <div>
        {input.map((el) => {
          return (
            <div key={el.lb}>
              <div>{el.lb}</div>
              <div>{el.el}</div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          justifyContent: "end",
        }}
      >
        {submitbt}
      </div>
    </div>
  );
};
