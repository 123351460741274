import { SaveOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import CommonForm, { formEl } from "../../common/CommonForm";
import { useState } from "react";
import { apiGenItem } from "../../../utils/apiutils";
import { useAuth } from "../../../../state/auth/auth";
import ManageIngrs from "./ManageIngrs";

type props = {
  disabled: boolean;
  refresh: () => void;

  open?: boolean;
  close?: () => void;
};

const CreateItem: React.FC<props> = ({
  disabled,
  refresh,
  close = () => {},
  open = false,
}) => {
  const [value, setvalue] = useState<any>({ name: "" });

  const { api } = useAuth();

  const save = () => {
    if (!value["name"]) {
      notification.error({
        message: "Titolo Richiesto!", duration: 1.5
      });
      return;
    }


    apiGenItem()
      .create(api, value)
      .then((e) => {
        
        notification.success({ message: "salvataggio riuscito" , duration: 1.5 });
        refresh();
        close();
        setvalue({});
      })
      .catch(() => {
        notification.error({
          message: "Errore salvataggio", duration: 1.5
        });
      });
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: 20 }}>
      <div>
        {open && (
          <CommonForm
            value={value}
            onChange={setvalue}
            elements={ItemProps}
            extra={[
              {
                label: "Ingredienti",
                children: (
                  <ManageIngrs
                  
                    item={null}
                    value={{
                      ings: value && value.ings ? value.ings : [],
                      removedIngs:
                        value && value.removedIngs ? value.removedIngs : [],
                    }}
                    onChange={(nv) => {
                      setvalue({ ...value, ...nv });
                    }}
                  />
                ),
              },
            ]}
          />
        )}
      </div>

      <div>
        <Button
          size="large"
          type="primary"
          onClick={save}
          icon={<SaveOutlined />}
        >
          salva
        </Button>
      </div>
    </div>
  );
};

export default CreateItem;

export const ItemProps: formEl[] = [
  {
    key: "name",
    type: "string",
    label: "Titolo",
  },
  {
    key: "description",
    type: "text",
    label: "Descrizione",
  },
  {
    key: "refParent",
    type: "category",
    label: "Categoria",
  },
  {
    key: "tags",
    type: "tags",
    label: "Tags",
  },
  {
    key: "price",
    type: "price",
    label: "Prezzo base",
  },
  {
    key: "active",
    type: "check",
    label: "Attivo",
  },
  {
    key: "showprice",
    type: "check",
    label: "Mostra Prezzo",
  },
  {
    key: "showtags",
    type: "check",
    label: "Mostra Tags",
  },
  {
    key: "showdescription",
    type: "check",
    label: "Mostra Descrizione",
  },
  {
    key: "showingredienti",
    type: "check",
    label: "Mostra Ingredienti",
  },
  {
    key: "showallegeni",
    type: "check",
    label: "Mostra Allergeni",
  },
];
