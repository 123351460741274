import React from "react";
import { Row, Col, Card, Typography, ColorPicker } from "antd";
import type { Resturnt } from "./Branding";

// ^ assicurati che l'interfaccia Resturnt sia importabile o definita correttamente

interface ColorSettingsProps {
  resturant: Resturnt;
  activeColorKey: string | null;
  setActiveColorKey: React.Dispatch<React.SetStateAction<string | null>>;
  handleColorChange: (key: keyof Resturnt, value: string) => void;
}

const ColorSettings: React.FC<ColorSettingsProps> = ({
  resturant,
  activeColorKey,
  setActiveColorKey,
  handleColorChange,
}) => {
 

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          {/* Titolo in alto */}
          <Typography.Title level={4} style={{ marginBottom: "20px", marginTop:"15px" }}>
            Colori
          </Typography.Title>
    
          {/* Contenitore flex per le card */}
          <div style={{ display: "flex", flexWrap: "wrap", gap: 20, width: "400px"}}>
            {/* Card 1: Primario */}
            <Card
              title="Primario"
              hoverable
              style={{ 
                cursor: "pointer", 
                width: 150,          // larghezza fissa o come preferisci
                flex: "0 0 auto",    // impedisce di “stirare” la card
              }}
              headStyle={{
                padding: "8px 12px",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
              bodyStyle={{
                backgroundColor: resturant.primarycolor,
                borderBottom: "none",
                height: 80,
                padding: 8,
              }}
              onClick={() => setActiveColorKey("primarycolor")}
            >
              {activeColorKey === "primarycolor" && (
                <ColorPicker
                  value={resturant.primarycolor}
                  onChange={(value) =>
                    handleColorChange("primarycolor", value.toHexString())
                  }
                  onOpenChange={(open) => {
                    if (!open) setActiveColorKey(null);
                  }}
                  open
                  style={{ border: "none" }}
                />
              )}
            </Card>
    
            {/* Card 2: Sfondo 1 */}
            <Card
              title="Sfondo primario"
              hoverable
              style={{
                cursor: "pointer",
                width: 180,
                flex: "0 0 auto",
              }}
              headStyle={{
                padding: "8px 12px",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
              bodyStyle={{
                backgroundColor: resturant.colorPrimaryBg,
                borderBottom: "none",
                height: 80,
                padding: 8,
              }}
              onClick={() => setActiveColorKey("colorPrimaryBg")}
            >
              {activeColorKey === "colorPrimaryBg" && (
                <ColorPicker
                  value={resturant.colorPrimaryBg}
                  onChange={(value) =>
                    handleColorChange("colorPrimaryBg", value.toHexString())
                  }
                  onOpenChange={(open) => {
                    if (!open) setActiveColorKey(null);
                  }}
                  open
                  style={{ border: "none" }}
                />
              )}
            </Card>
    
            {/* Card 3: Sfondo 2 */}
            <Card
              title="Sfondo secodario"
              hoverable
              style={{
                cursor: "pointer",
                width: 180,
                flex: "0 0 auto",
              }}
              headStyle={{
                padding: "8px 12px",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
              bodyStyle={{
                backgroundColor: resturant.secondaryBg,
                borderBottom: "none",
                height: 80,
                padding: 8,
              }}
              onClick={() => setActiveColorKey("secondaryBg")}
            >
              {activeColorKey === "secondaryBg" && (
                <ColorPicker
                  value={resturant.secondaryBg}
                  onChange={(value) =>
                    handleColorChange("secondaryBg", value.toHexString())
                  }
                  onOpenChange={(open) => {
                    if (!open) setActiveColorKey(null);
                  }}
                  open
                  style={{ border: "none" }}
                />
              )}
            </Card>
    
            {/* Card 4: Testo */}
            <Card
              title="Testo"
              hoverable
              style={{
                cursor: "pointer",
                width: 150,
                flex: "0 0 auto",
              }}
              headStyle={{
                padding: "8px 12px",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
              bodyStyle={{
                backgroundColor: resturant.primarytextcolor,
                borderBottom: "none",
                height: 80,
                padding: 8,
              }}
              onClick={() => setActiveColorKey("primarytextcolor")}
            >
              {activeColorKey === "primarytextcolor" && (
                <ColorPicker
                  value={resturant.primarytextcolor}
                  onChange={(value) =>
                    handleColorChange("primarytextcolor", value.toHexString())
                  }
                  onOpenChange={(open) => {
                    if (!open) setActiveColorKey(null);
                  }}
                  open
                  style={{ border: "none" }}
                />
              )}
            </Card>
            <Card
              title="Titoli"
              hoverable
              style={{
                cursor: "pointer",
                width: 150,
                flex: "0 0 auto",
              }}
              headStyle={{
                padding: "8px 12px",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
              bodyStyle={{
                backgroundColor: resturant.secondarytextcolor,
                borderBottom: "none",
                height: 80,
                padding: 8,
              }}
              onClick={() => setActiveColorKey("secondarytextcolor")}
            >
              {activeColorKey === "secondarytextcolor" && (
                <ColorPicker
                  value={resturant.secondarytextcolor}
                  onChange={(value) =>
                    handleColorChange("secondarytextcolor", value.toHexString())
                  }
                  onOpenChange={(open) => {
                    if (!open) setActiveColorKey(null);
                  }}
                  open
                  style={{ border: "none" }}
                />
              )}
            </Card>
          </div>
        </div>
      );
};

export default ColorSettings;
