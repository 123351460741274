import {
  LoadingOutlined,
  RedoOutlined,
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  OrderedListOutlined,
  PlusCircleFilled,
  FlagOutlined,
} from "@ant-design/icons";
import { Button, Col, Grid, Input, Row, Spin, notification, Modal } from "antd";
import React, { useState } from "react";
import { useAuth } from "../../../state/auth/auth";
import EditItem from "../Articoli/drwrs/Edit"; // Assicurati che il path sia corretto
import ModalOpenBt from "./ModalOpenbt";
import { apiGenItemGroup } from "../../utils/apiutils";
import Order from "../Categorie/Order";
import LangPicker from "./custominputs/LangsEnabled";

type Props = {
  refresh?: () => void;
  isLoading: boolean;
  onFilterChange?: (nf: string) => void;
  extraBt?: React.ReactNode;
  children: React.ReactNode;
  showFilter: boolean;
  showRefresh: boolean;
  lista?: any[];
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  apiGen?: any;
  showDeleteButton?: boolean | React.ReactNode;
  showEdit?: string;
  showOrder?: boolean;
  showLangs?: boolean;
};

const StandPage: React.FC<Props> = ({
  children,
  showFilter,
  isLoading = false,
  extraBt,
  onFilterChange,
  showRefresh,
  refresh,
  lista,
  selectedRowKeys = [],
  setSelectedRowKeys,
  apiGen,
  showDeleteButton,
  showEdit,
  showOrder,
  showLangs,
}) => {
  const [filtervalue, setFiltervalue] = useState("");
  const { md } = Grid.useBreakpoint();
  const { api } = useAuth();

  const filterChange = (nf: string) => {
    if (onFilterChange) {
      onFilterChange(nf);
    }
    setFiltervalue(nf);
  };

  const handleDeleteSelected = async () => {
    if (!selectedRowKeys || !setSelectedRowKeys) {
      notification.warning({
        message: "Selezione non configurata.",
        duration: 1.5,
      });
      return;
    }
    if (!lista || !api) {
      console.error("Errore: 'lista' o 'api' non definiti.");
      return;
    }

    // Per la conferma dell'eliminazione usiamo Modal.confirm
    Modal.confirm({
      title: "Sei sicuro di voler procedere con l'eliminazione?",
      okText: "Elimina",
      okType: "danger",
      cancelText: "Annulla",
      onOk: async () => {
        try {
          const entitiesToDelete = lista.filter((item) =>
            selectedRowKeys.includes(item.ref)
          );

          const deletePromises = entitiesToDelete.map((entity) =>
            apiGen.deleteEntity(api, entity)
          );

          await Promise.all(deletePromises);

          notification.success({
            message: "Eliminati!",
            duration: 1.5,
          });

          setSelectedRowKeys([]);
          if (refresh) refresh();
        } catch (error) {
          notification.error({
            message: "Errore durante l'eliminazione",
            duration: 1.5,
          });
        }
      },
    });
  };

  return (
    <Spin indicator={<LoadingOutlined />} spinning={isLoading} tip="Caricamento...">
      <div
        style={{
          paddingLeft: md ? "5%" : "2%",
          display: "grid",
          gridTemplateColumns: "1fr",
          alignContent: "start",
          width: md ? undefined : "100vw",
        }}
      >
        <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
          <Row gutter={[16, 16]}>
            {showFilter && (
              <Col>
                <Input
                  onChange={(e) => filterChange(e.target.value)}
                  disabled={isLoading}
                  placeholder="Cerca..."
                  value={filtervalue}
                  addonAfter={<SearchOutlined />}
                  style={{ width: md ? 300 : 150 }}
                />
              </Col>
            )}
            {showRefresh && (
              <Col>
                <Button icon={<RedoOutlined />} disabled={isLoading} onClick={refresh} />
              </Col>
            )}
            <Col>{extraBt}</Col>
            
            {showEdit === "articoli" && (
              <Col>
                  <ModalOpenBt
                    btLabel={md ? "Modifica" : ""}
                    drawerTitle="Modifica articolo"
                    disabled={!selectedRowKeys || selectedRowKeys.length === 0}
                    btProps={{ icon: <EditOutlined /> }}
                  >
                    <EditItem
                      disabled={isLoading}
                      refresh={refresh!}
                      item={lista?.find((item) => item.ref === selectedRowKeys[0])}
                      selectedRowKeys={selectedRowKeys}
                      setSelectedRowKeys={setSelectedRowKeys!}
                      close={() => {
                        if (setSelectedRowKeys) setSelectedRowKeys([]);
                      }}
                    />
                  </ModalOpenBt>
              </Col>
            )}
            {showOrder && (
              <Col>
                  <ModalOpenBt
                    btLabel={md ? "Ordina" : ""}
                    drawerTitle="Ordina categorie"
                    btProps={{ icon: <OrderedListOutlined /> }}
                  >
                  <Order />
                  </ModalOpenBt>
              </Col>
            )}
            {showLangs && (
                            <Col>
                            <ModalOpenBt
                              btLabel={md ? "Lingue abilitate" : ""}
                              btProps={{ icon: <FlagOutlined /> }}
                              drawerTitle="Seleziona le lingue"
                            >
                            <LangPicker />
                            </ModalOpenBt>
                        </Col>
            )}
            {showDeleteButton && (
              <Col>
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={handleDeleteSelected}
                  disabled={!selectedRowKeys || selectedRowKeys.length === 0}
                >
                  {md ? "Elimina" : ""}
                </Button>
              </Col>
            )}
          </Row>
        </div>
        <div
          style={{
            overflow: "auto",
            paddingRight: md ? "5%" : "4%",
            paddingBottom: "200px",
            height: md
              ? "calc(100vh - 80px - 20px)"
              : "calc(100vh - 80px - 70px - 20px)",
          }}
        >
          {children}
        </div>
      </div>
    </Spin>
  );
};

export default StandPage;
