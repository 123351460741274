import React, { useState } from "react";
import {
  notification,
  theme,
  Button,
  Modal,
  Tabs,
  Row,
  Col,
  Image,
} from "antd";
import type { TabsProps } from "antd";
import CaricaSfondo from "./caricaSfondo";
import dayjs from "dayjs";
import { useAuth } from "../../../state/auth/auth";

interface BackgroundSelectorCarouselProps {
  onBackgroundSelected?: () => void; // callback opzionale
}

interface SlideItem {
  type: "image" | "upload";
  src?: string;
  label: string;
}

const BackgroundSelectorCarousel: React.FC<BackgroundSelectorCarouselProps> = ({
  onBackgroundSelected,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { api } = useAuth();

  // Se ti serve un “time” per forzare refresh dell’immagine
  const [time] = useState<number>(dayjs().valueOf());

  const slides: SlideItem[] = [
    { type: "image", src: "/img/Sfondo1.png",  label: "Sfondo 1" },
    { type: "image", src: "/img/Sfondo2.png",  label: "Sfondo 2" },
    { type: "image", src: "/img/Sfondo3.png",  label: "Sfondo 3" },
    { type: "image", src: "/img/Sfondo4.png",  label: "Sfondo 4" },
    { type: "image", src: "/img/Sfondo5.png",  label: "Sfondo 5" },
    { type: "image", src: "/img/Sfondo6.png",  label: "Sfondo 6" },
    { type: "image", src: "/img/Sfondo7.png",  label: "Sfondo 7" },
    { type: "image", src: "/img/Sfondo8.png",  label: "Sfondo 8" },
    { type: "image", src: "/img/Sfondo9.png",  label: "Sfondo 9" },
    { type: "image", src: "/img/Sfondo10.png", label: "Sfondo 10" },
    { type: "image", src: "/img/Sfondo11.png", label: "Sfondo 11" },
    { type: "image", src: "/img/Sfondo12.png", label: "Sfondo 12" },
    { type: "image", src: "/img/Sfondo13.png", label: "Sfondo 13" },
    { type: "image", src: "/img/Sfondo14.png", label: "Sfondo 14" },
    { type: "image", src: "/img/Sfondo15.png", label: "Sfondo 15" },
    { type: "image", src: "/img/Sfondo16.png", label: "Sfondo 16" },
    { type: "image", src: "/img/Sfondo17.png", label: "Sfondo 17" },
    { type: "image", src: "/img/Sfondo18.png", label: "Sfondo 18" },
    { type: "image", src: "/img/Sfondo19.png", label: "Sfondo 19" },
    { type: "image", src: "/img/Sfondo20.png", label: "Sfondo 20" },
    { type: "image", src: "/img/Sfondo21.png", label: "Sfondo 21" },
    { type: "image", src: "/img/Sfondo22.png", label: "Sfondo 22" },
    { type: "image", src: "/img/Sfondo23.png", label: "Sfondo 23" },
    { type: "image", src: "/img/Sfondo24.png", label: "Sfondo 24" },
    { type: "image", src: "/img/Sfondo25.png", label: "Sfondo 25" },
    { type: "image", src: "/img/Sfondo26.png", label: "Sfondo 26" },
    { type: "image", src: "/img/Sfondo27.png", label: "Sfondo 27" },
    { type: "image", src: "/img/Sfondo28.png", label: "Sfondo 28" },
    { type: "image", src: "/img/Sfondo29.png", label: "Sfondo 29" },
    { type: "image", src: "/img/Sfondo30.png", label: "Sfondo 30" },
    { type: "image", src: "/img/Sfondo31.png", label: "Sfondo 31" },
  ];

  // Colori di antd
  const {
    token: { colorPrimary },
  } = theme.useToken();

  // Funzione “Usa sfondo”
  const handleUseSfondo = () => {
    if (!selectedImageUrl) {
      notification.warning({
        message: "Nessuno sfondo selezionato",
        description: "Per favore, seleziona uno sfondo prima di usarlo.",
      });
      return;
    }
  
    // 1) Scarichiamo l'immagine remota
    fetch(selectedImageUrl)
      .then((res) => res.blob())
      .then((blob) => {
        // 2) Creiamo il File fittizio
        const fileName = selectedImageUrl.split("/").pop() || "sfondo.jpg";
        const file = new File([blob], fileName, { type: blob.type });
  
        // 3) Upload al server
        return api.uploadImage(file, "resturant/uploadBg");
      })
      .then(() => {
        notification.success({
          message: "Sfondo caricato e impostato correttamente!",
          duration: 1.5,
        });
        setIsModalOpen(false);
        onBackgroundSelected?.();
      })
      .catch((err) => {
        console.error("Errore durante l'upload automatico:", err);
        notification.error({
          message: "Errore durante l'upload",
          duration: 1.5,
        });
      });
  };

  // Seleziona sfondo - griglia a sinistra, anteprima a destra
  const SelezionaSfondoTab = (
    <div style={{ padding: 16 }}>
      <Row gutter={12}>
        <Col xs={24} md={18}>
          <Row gutter={[8, 8]}>
            {slides
              .filter((item) => item.type === "image" && item.src)
              .map((item, index) => (
                <Col key={index} xs={6} sm={4} md={4} lg={3}>
                  <div
                    style={{
                      width: 70,
                      height: 155,
                      border:
                        item.src === selectedImageUrl
                          ? `2px solid ${colorPrimary}`
                          : "none",
                      borderRadius: 4,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 5,
                      padding: 3,
                    }}
                    onClick={() => {
                      setSelectedIndex(index);
                      setSelectedImageUrl(item.src || null);
                    }}
                  >
                    <Image
                      preview={false}
                      src={item.src}
                      alt={item.label}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Col>

        {/* Anteprima + Usa sfondo (colonna destra 6 colonne) */}
        <Col xs={24} md={6}>
          <div
            style={{
              border: "1px solid #ddd",
              padding: 1,
              minHeight: 300,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 8,
            }}
          >
            {selectedImageUrl ? (
              <Image
                src={selectedImageUrl}
                alt="Anteprima sfondo"
                style={{
                  maxWidth: "100%",
                  maxHeight: 500,
                  objectFit: "contain",
                }}
              />
            ) : (
              <div style={{ textAlign: "center" }}>Nessuno sfondo selezionato</div>
            )}
            <Button
              type="primary"
              onClick={handleUseSfondo}
              disabled={!selectedImageUrl}
              style={{ marginTop: 8 }}
            >
              Applica
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Carica sfondo",
      children: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 300,
          }}
        >
          <CaricaSfondo
            onBackgroundSelected={() => {
              onBackgroundSelected?.();
              setIsModalOpen(false);
            }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Seleziona sfondo",
      children: SelezionaSfondoTab,
    },
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {/* Contenitore flex a colonna, allineato al centro */}

      <div style={{ }}>
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          Cambia Sfondo
        </Button>
      </div>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width="90%"
      >
        <Tabs defaultActiveKey="2" items={items} />
      </Modal>
    </div>
  );
};

export default BackgroundSelectorCarousel;
