import { useState } from "react";
import MenuComp from "./menu";
import { menu } from "./dmenu";
import { useAuth } from "../../state/auth/auth";
import { Grid } from "antd";

const AdminPage = () => {
  const [menuSel, setMenuSel] = useState(menu[0].key);
  const { md } = Grid.useBreakpoint();
  const { user } = useAuth();
  return user ? (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: md ? "auto 1fr" : "1fr",
        width: "100vw",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <MenuComp selected={menuSel} onSelect={(e) => setMenuSel(e)} />
      <div style={{}}>
        {menu.map((m) => {
          if (m.superadmin && !user.superAdmin) {
            return <></>;
          }

          return m.key === menuSel ? <div>{m.cont}</div> : <></>;
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AdminPage;
