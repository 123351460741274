// App.tsx
import React, { useState } from "react";
import { AuthProvider } from "./state/auth/auth";
import MainPage from "./pages/main/index.";
import RegisterPage from "./pages/main/Register"; // Crea questo componente (o importa quello già esistente)
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider, theme, FloatButton, App as AndApp } from "antd";
import { MoonOutlined, SunOutlined } from "@ant-design/icons";
import it from "antd/locale/it_IT";

const keydarkmode = "darkmode";

export const getDarkmodeFromStorage = () => {
  return (
    window.localStorage.getItem(keydarkmode) &&
    window.localStorage.getItem(keydarkmode) === "true"
  );
};
const setDarkmodeFromStorage = (isDark: boolean) => {
  return window.localStorage.setItem(keydarkmode, isDark ? "true" : "false");
};

function App() {
  const [isDarkmode, _setIsDarkmode] = useState(getDarkmodeFromStorage());

  const setDarkMode = (isDark: boolean) => {
    _setIsDarkmode(isDark);
    setDarkmodeFromStorage(isDark);
  };

  return (
    <AndApp>
      <div className="App">
        <AuthProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: isDarkmode ? "#EC3F6A" : "#d6a46b",
                colorInfo: "#faad14",
                colorBgBase: isDarkmode ? "#032435" : "#edece4",
                colorBgContainer: isDarkmode ? "#133446" : "#dbd2c8",
                fontSize: 16,
              },
              components: {
                Table: {
                  rowSelectedHoverBg: isDarkmode ? "" : "#d8ccc4",
                  rowSelectedBg: isDarkmode ? "" : "#d8ccc4",
                },
                Checkbox: {
                  controlInteractiveSize: 18,
                  colorBorder: isDarkmode ? "" : "#d6a46b",
                  colorBgContainer: isDarkmode ? "" : "#d8ccc4",
                  lineWidthFocus: 6,
                },
              },
              algorithm: isDarkmode
                ? theme.darkAlgorithm
                : theme.defaultAlgorithm,
            }}
            locale={it}
          >
           
                <MainPage />
         
          </ConfigProvider>
        </AuthProvider>
        <FloatButton
          onClick={() => setDarkMode(!isDarkmode)}
          icon={isDarkmode ? <SunOutlined /> : <MoonOutlined />}
        />
      </div>
    </AndApp>
  );
}

export default App;
