import React, { useEffect, useState } from "react";
import { useAuth } from "../../../state/auth/auth";
import {
  Button,
  Col,
  Grid,
  Row,
  Input,
  Typography,
  notification,
  Card,
  theme,
  Switch,
  FloatButton,
  Tabs,
} from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import StandPage from "../common/StandPage";
import { apiGenResturant, apiGenUser } from "../../utils/apiutils";
import FontFamilyPicker from "../common/custominputs/FontFamilyPicker";
import CaricaLogo from "./caricalogo";
import CaricaSfondo from "./caricalogo"; // se serve per background
import BackgroundSelectorCarousel from "./BackgroundSelectorCarousel";
import ColorSettings from "./ColorSettings";
import EditorNote from "./EditorNote";
import ModalOpenBt from "../common/ModalOpenbt";
import ResetPassword from "./ResetPassword";
import LangPicker from "../common/custominputs/LangsEnabled";

const { useBreakpoint } = Grid;

// Tipi (già definiti, con l'aggiunta di bookingSettings)
export type Resturnt = {
  name: string;
  fontFamily: string;
  primarycolor: string;
  colorPrimaryBg: string;
  secondaryBg: string;
  primarytextcolor: string;
  secondarytextcolor: string;
  langsEnable?: string;
  bbnote: string;
  usebg: boolean;
  bookingSettings: {
    whatsapp: boolean;
    email: boolean;
    enable: boolean;
  };
  ordinationSettings: {
    whatsapp: boolean;
    email: boolean;
    enable: boolean;
  };
};

export type User = {
  name: string;
  email: string;
  username: string;
  phone: string;
  contractType: number;
  startContract: string;
  endContract: string;
};

const Branding: React.FC = () => {
  const [restourant, setResturant] = useState<Resturnt>({
    name: "",
    fontFamily: "",
    primarycolor: "",
    colorPrimaryBg: "",
    secondaryBg: "",
    primarytextcolor: "",
    secondarytextcolor: "",
    bbnote: "",
    usebg: false,
    bookingSettings: {
      whatsapp: false,
      email: false,
      enable: true,
    },
    ordinationSettings: {
      whatsapp: false,
      email: false,
      enable: true,
    },
  });

  const [user, setUser] = useState<User>({
    name: "",
    email: "",
    username: "",
    phone: "",
    contractType: 0,
    startContract: "",
    endContract: "",
  });

  const { api, resturant: restAuth } = useAuth();
  const [edited, setEdited] = useState(false);
  const [activeColorKey, setActiveColorKey] = useState<string | null>(null);
  const md = useBreakpoint();

  // Forza refresh dell’immagine sfondo
  const [time, settime] = useState<number>(dayjs().valueOf());

  const { token } = theme.useToken();

  // Helper per aggiornare campo di restourant
  const updateField = (key: keyof Resturnt, value: any) => {
    setResturant((prev) => ({ ...prev, [key]: value }));
    setEdited(true);
  };

  // Helper per aggiornare i campi di bookingSettings,
  // convertendo boolean -> number
  const updateBookingSettings = (key: keyof Resturnt["bookingSettings"], checked: boolean) => {
    setResturant((prev) => ({
      ...prev,
      bookingSettings: {
        ...prev.bookingSettings,
        [key]: checked ? 1 : 0,
      },
    }));
    setEdited(true);
  };

  const updateOrdinationSettings = (key: keyof Resturnt["ordinationSettings"], checked: boolean) => {
    setResturant((prev) => ({
      ...prev,
      ordinationSettings: {
        ...prev.ordinationSettings,
        [key]: checked ? 1 : 0,
      },
    }));
    setEdited(true);
  };

  // Helper per aggiornare campi utente
  const updateUserField = (key: keyof User, value: any) => {
    setUser((prev) => ({ ...prev, [key]: value }));
  };

  // Carica i dati iniziali
  const refresh = () => {
    apiGenResturant()
      .getList(api)
      .then((e) => setResturant(e as any))
      .catch(() =>
        notification.error({
          message: "Errore durante il caricamento del ristorante!",
          duration: 1.5,
        })
      );

    apiGenUser()
      .getList(api)
      .then((e) => setUser(e as any))
      .catch(() =>
        notification.error({
          message: "Errore durante il caricamento dell'utente!",
          duration: 1.5,
        })
      );
  };

  useEffect(() => {
    refresh();
  }, []);
  console.log(restourant)
  // Validazioni base
  const validateEmail = (email: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone: string): boolean => {
    const regex = /^\+?[0-9\s\-]{7,}$/;
    return regex.test(phone);
  };

  // Salvataggio (restourant + user)
  const save = () => {
    if (!validateEmail(user.email)) {
      notification.error({
        message: "Indirizzo email non valido!",
        duration: 1.5,
      });
      return;
    }
    if (!validatePhone(user.phone)) {
      notification.error({
        message: "Numero di telefono non valido!",
        duration: 1.5,
      });
      return;
    }

    Promise.all([apiGenResturant().edit(api, restourant), apiGenUser().edit(api, user)])
      .then(([restResponse, userResponse]) => {
        setResturant(restResponse as any);
        setUser(userResponse as any);
        notification.success({
          message: "Impostazioni salvate con successo!",
          duration: 1.5,
        });
      })
      .catch(() => {
        notification.error({
          message: "Errore durante il salvataggio!",
          duration: 1.5,
        });
      });
  };

  const handleColorChange = (key: keyof Resturnt, value: string) => {
    updateField(key, value);
  };

  return (
    <StandPage isLoading={false} showFilter={false} showRefresh={false} refresh={refresh}>
      <div>
        <Typography style={{ marginTop: 0, paddingLeft: md ? "0%" : "0%" }}>
          <h3>
            <b>Impostazioni</b>
          </h3>
        </Typography>
        <Tabs defaultActiveKey="1">
          {/* --- TAB 1: PROFILO --- */}
          <Tabs.TabPane tab="Profilo" key="1">
            <Typography.Title style={{ marginTop: 0 }} level={5}>
              Username:{" "}
              <Input
                style={{ marginTop: 10 }}
                value={user.username}
                onChange={(e) => updateUserField("username", e.target.value)}
                addonAfter={<EditOutlined style={{ fontSize: "20px" }} />}
              />
            </Typography.Title>
            <Typography.Title level={5}>
              Email:{" "}
              <Input
                style={{ marginTop: 10 }}
                value={user.email}
                onChange={(e) => updateUserField("email", e.target.value)}
                onBlur={() => {
                  if (!validateEmail(user.email)) {
                    notification.error({
                      message: "Indirizzo email non valido!",
                      duration: 1.5,
                    });
                  }
                }}
                addonAfter={<EditOutlined style={{ fontSize: "20px" }} />}
              />
            </Typography.Title>
            <Typography.Title level={5}>
              Telefono:{" "}
              <Input
                style={{ marginTop: 10 }}
                value={user.phone}
                onChange={(e) => updateUserField("phone", e.target.value)}
                onBlur={() => {
                  if (!validatePhone(user.phone)) {
                    notification.error({
                      message: "Numero di telefono non valido!",
                      duration: 1.5,
                    });
                  }
                }}
                addonAfter={<EditOutlined style={{ fontSize: "20px" }} />}
              />
            </Typography.Title>
            <Typography.Title level={5}>Password:</Typography.Title>
            <ModalOpenBt btLabel={"Cambia Password"} btProps={{ icon: <EditOutlined /> }}>
              <ResetPassword />
            </ModalOpenBt>
            <Typography.Title level={5}>
              Inizio contratto: {user.startContract ? dayjs(user.startContract).format("DD/MM/YYYY") : ""}
            </Typography.Title>
            <Typography.Title level={5}>
              Fine contratto: {user.endContract ? dayjs(user.endContract).format("DD/MM/YYYY") : ""}
            </Typography.Title>
          </Tabs.TabPane>

          {/* --- TAB 2: MENU --- */}
          <Tabs.TabPane tab="Menu" key="2">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Logo:
            </Typography.Title>
            <CaricaLogo />

            <Typography.Title style={{ marginTop: "30px", marginBottom: "10px" }} level={4}>
              Nome ristorante:
            </Typography.Title>
            <Input
              value={restourant.name}
              onChange={(e) => updateField("name", e.target.value)}
              size="large"
              style={{ fontSize: "30px", marginBottom: 20 }}
              addonAfter={<EditOutlined style={{ fontSize: "20px" }} />}
            />

            <div>
              <Typography.Title level={4}>Note a fine menu:</Typography.Title>
              <EditorNote
                value={restourant.bbnote}
                onChange={(newContent: string) => updateField("bbnote", newContent)}
              />
            </div>

            {/** MOSTRIAMO BOOKING SETTINGS SOLO SE contractType === 1 **/}
            {user.contractType === 1 && (<>
              <div style={{ marginTop: 30 }}>

  {/* PRENOTAZIONI */}
  <div style={{ display: "flex", alignItems: "center", gap: 10, marginBottom: 10 }}>
    <Typography.Title level={4}>Prenotazioni:</Typography.Title>
    <Switch
      // Se bookingSettings è null, fallback a false
      checked={restourant.bookingSettings?.enable ?? false}
      onChange={(checked) => updateBookingSettings("enable", checked)}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      style={{ marginTop: 25 }}
    />
  </div>

  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: 10,
      marginBottom: 10,
      opacity: (restourant.bookingSettings?.enable ?? false) ? 1 : 0.5,
    }}
  >
    <span>Notifica email:</span>
    <Switch
      checked={restourant.bookingSettings?.email ?? false}
      onChange={(checked) => updateBookingSettings("email", checked)}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      disabled={!restourant.bookingSettings?.enable}
    />
  </div>

  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: 10,
      marginBottom: 10,
      opacity: (restourant.bookingSettings?.enable ?? false) ? 1 : 0.5,
    }}
  >
    <span>Notifica WhatsApp:</span>
    <Switch
      checked={restourant.bookingSettings?.whatsapp ?? false}
      onChange={(checked) => updateBookingSettings("whatsapp", checked)}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      disabled={!restourant.bookingSettings?.enable}
    />
  </div>
</div>

<div style={{ marginTop: 30 }}>

  {/* ORDINAZIONI */}
  <div style={{ display: "flex", alignItems: "center", gap: 10, marginBottom: 10 }}>
    <Typography.Title level={4}>Ordinazioni:</Typography.Title>
    <Switch
      // Fallback a false se ordinationSettings è null
      checked={restourant.ordinationSettings?.enable ?? false}
      // NB: Meglio avere una FUNZIONE DIVERSA per aggiornare ordinationSettings
      onChange={(checked) => updateOrdinationSettings("enable", checked)}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      style={{ marginTop: 25 }}
    />
  </div>

  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: 10,
      marginBottom: 10,
      opacity: (restourant.ordinationSettings?.enable ?? false) ? 1 : 0.5,
    }}
  >
    <span>Notifica email:</span>
    <Switch
      checked={restourant.ordinationSettings?.email ?? false}
      onChange={(checked) => updateOrdinationSettings("email", checked)}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      disabled={!restourant.ordinationSettings?.enable}
    />
  </div>

  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: 10,
      marginBottom: 10,
      opacity: (restourant.ordinationSettings?.enable ?? false) ? 1 : 0.5,
    }}
  >
    <span>Notifica WhatsApp:</span>
    <Switch
      checked={restourant.ordinationSettings?.whatsapp ?? false}
      onChange={(checked) => updateOrdinationSettings("whatsapp", checked)}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      disabled={!restourant.ordinationSettings?.enable}
    />
  </div>
</div>


            </>)}
          </Tabs.TabPane>

          {/* --- TAB 3: ASPETTO --- */}
          <Tabs.TabPane tab="Aspetto" key="3">
            <div style={{ display: "flex", flexWrap: "wrap", gap: 20, marginTop: 20 }}>
              <div style={{ flex: "1 1 300px", maxWidth: 400, boxSizing: "border-box" }}>
                <Card
                  style={{ width: 340, height: 650 }}
                  bodyStyle={{ padding: 0 }}
                  title={
                    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                      <Typography.Title level={4} style={{ margin: 0, marginRight: 10 }}>
                        Sfondo Personalizzato
                      </Typography.Title>
                      <Switch
                        checked={restourant.usebg}
                        onChange={(checked) => updateField("usebg", checked)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </div>
                  }
                >
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <div style={{ opacity: restourant.usebg ? 1 : 0.5 }}>
                      <img
                        key={time}
                        src={`/img/background/${restAuth?.ref}_bg?ts=${time}`}
                        style={{ maxWidth: 330, maxHeight: 500, display: "block", marginBottom: 20 }}
                      />
                      <BackgroundSelectorCarousel
                        onBackgroundSelected={() => {
                          settime(dayjs().valueOf());
                        }}
                      />
                    </div>
                    {!restourant.usebg && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background: "transparent",
                          cursor: "not-allowed",
                        }}
                      />
                    )}
                  </div>
                </Card>
              </div>

              <div style={{ flex: "1", boxSizing: "border-box" }}>
                <ColorSettings
                  resturant={restourant}
                  activeColorKey={activeColorKey}
                  setActiveColorKey={setActiveColorKey}
                  handleColorChange={handleColorChange}
                />
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <Typography.Title level={4}>Stile Font</Typography.Title>
              <FontFamilyPicker
                value={restourant.fontFamily}
                onChange={(value: string) => updateField("fontFamily", value)}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>

        <div style={{ display: "flex", justifyContent: "end", gap: 10, marginTop: 20 }}>
          <FloatButton
            type="primary"
            onClick={save}
            style={{ insetInlineEnd: 24, bottom: 120, width: "65px", height: "65px" }}
            icon={<SaveOutlined />}
            description="Salva"
          />
        </div>
      </div>
    </StandPage>
  );
};

export default Branding;
