import React, { useEffect, useMemo, useState } from "react";
import { Categoria } from "../Categorie/Tabella";
import { Grid, Select, Typography } from "antd";
import { useAuth } from "../../../state/auth/auth";
import { apiGenItemGroup } from "../../utils/apiutils";

type props = {
  value: string;
  onChange: (nv: string) => void;
};

type ops = {
  value: string;
  label: string | React.ReactNode;
  index: string;
};

const CategoryPicker: React.FC<props> = ({ value, onChange }) => {
  const [allcats, setAllcats] = useState<Categoria[]>([]);
  const { api } = useAuth();

  useEffect(() => {
    apiGenItemGroup()
      .getList(api)
      .then((ca) => setAllcats(ca as Categoria[]));
  }, [api]);

  const options = useMemo(() => {
    let ret: ops[] = [];

    // Filtra le categorie, escludendo quelle con 'bb_deleted' === true 
    const activeCategories = allcats.filter((c) => !c.deleted);

    // Mappa per conoscere velocemente i nomi dei parent
    const parents: { [ref: string]: string } = {};

    // Salviamo i nomi dei parent
    activeCategories.forEach((c) => {
      parents[c.ref] = c.name;
    });

    // Creiamo le options
    activeCategories.forEach((c) => {
      ret.push({
        value: c.ref,
        label: c.parentRef ? (
          <>
            <Typography.Text>{parents[c.parentRef]}</Typography.Text>/
            <Typography.Text strong>{c.name}</Typography.Text>
          </>
        ) : (
          <Typography.Text strong>{c.name}</Typography.Text>
        ),
        index: c.parentRef
          ? `${parents[c.parentRef]}_${c.parentRef}`
          : `${c.name}_${c.ref}`,
      });
    });

    // Ordiniamo le option in base alla proprietà 'index'
    ret = ret.sort((a, b) => a.index.localeCompare(b.index));
    return ret;
  }, [allcats]);

  const { md } = Grid.useBreakpoint();

  return (
    <div>
      <Select
        value={value}
        onChange={onChange}
        options={options}
        style={{ width: "100%", marginBottom: "20px", marginTop: "5px" }}
      />
    </div>
  );
};

export default CategoryPicker;
