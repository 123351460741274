import React, { useEffect, useMemo, useRef } from "react";
import { Empty, theme, Typography } from "antd";
import { reporp } from "./Stats";
import * as echarts from "echarts";
import dayjs, { Dayjs } from "dayjs";

const format = "HH:mm";

const PerHours = ({ stats }: { stats: reporp }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const { token } = theme.useToken();

  const getDy = (y: number) => {
    return dayjs()
      .set("hour", y)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);
  };

  const data = useMemo(() => {
    const min = 0;
    const max = 24;

    const map: { [k: string]: number } = {};

    let run: Dayjs = getDy(min);
    const end = getDy(max);
    while (!run.isAfter(end)) {
      map[run.format(format)] = 0;
      run = run.add(1, "hour");
    }

    stats.peakhours.forEach((m) => {
      const time = getDy(m.time).format(format);
      if (map[time] !== undefined) {
        map[time] += m.count;
      }
    });
    
    return Object.keys(map)
      .sort((a, b) => {
        const aHour = dayjs(a, format).hour();
        const bHour = dayjs(b, format).hour();
        return aHour - bHour;
      })
      .map((k) => ({
        Ora: k,
        visualizzazioni: map[k],
      }));
  }, [stats.peakhours]);

  const chartOptions = useMemo(() => {
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: data.map((item) => item.Ora),
        axisLabel: {
          rotate: 45,
          interval: 0,
          formatter: (value: string) => value,
          color: token.colorText,
        },
      
      },
      yAxis: {
        type: "value",
        axisLabel: {
          color: token.colorText,
        },
        
      },
      series: [
        {
          data: data.map((item) => item.visualizzazioni),
          type: "bar",
          barWidth: "60%",
          
          itemStyle: {
            color: token.colorPrimary,
            
          },
          emphasis: {
            focus: "series",
          },
        },
      ],
      grid: {
        left: "2%",
        right: "2%",
        bottom: "10%",
        containLabel: true,
      },
    };
  }, [data, token]);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = echarts.init(chartRef.current);

      chartInstance.setOption(chartOptions);

      const handleResize = () => {
        chartInstance.resize();
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        chartInstance.dispose();
      };
    }
  }, [chartOptions]);

  return (
    <div
      style={{
        background: token.colorBgContainer,
        padding: "20px 25px",
        borderRadius: token.borderRadius,
      }}
    >
      <Typography.Title style={{ margin: 0 }} level={4}>
        Visualizzazioni giornata
      </Typography.Title>
      {stats.peakhours.length > 0 ? (
          <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
        ) : (
          <Empty
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        )}
        </div>
  );
};

export default PerHours;