import React, { useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "./EditorNote.css";
import it from "suneditor/src/lang/it";

interface EditorNoteProps {
  value: string;
  onChange: (value: string) => void;
}

const EditorNote: React.FC<EditorNoteProps> = ({ value, onChange }) => {
  const [content, setContent] = useState(value);

  const editorOptions = {
    buttonList: [
      ["font", "fontSize"],
      ["bold", "underline", "italic", "strike"],
      ["fontColor", "hiliteColor"],
      ["list", "align"],
    ],
    font: [
      "Arial",
      "Helvetica",
      "Verdana",
      "Tahoma",
      "Trebuchet MS",
      "Times New Roman",
      "Georgia",
      "Garamond",
      "Courier New",
      "Brush Script MT",
      "Comic Sans MS",
      "Impact",
    ],
    fontSize: [8, 10, 12, 14, 16, 18, 20, 24, 30, 36],
    lang: it,
    showPathLabel: false,
    iframe: false,
    autoHeightEnabled: false,
    height: "200px",
  };

  const handleChange = (newContent: string) => {
    setContent(newContent);
    onChange(newContent);
  };

  useEffect(() => {
    setContent(value);
  }, [value]);

  return (
    <div>
      <SunEditor
        setOptions={editorOptions}
        defaultValue={content}
        onChange={handleChange}
      />
    </div>
  );
};

export default EditorNote;
