import { useEffect, useState } from "react";
import CommonForm from "../common/CommonForm";
import { Categoria } from "./Tabella";
import { useAuth } from "../../../state/auth/auth";
import { apiGenItemGroup } from "../../utils/apiutils";
import { App, Button } from "antd";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";

type props = {
  editValue?: Categoria | null;
  isSottoCategoria?: boolean;
  refresh: () => void;
  close?: () => void;
  refParent?: string;
  note: boolean;
};

const CreateEdit: React.FC<props> = ({
  isSottoCategoria = false,
  refresh,
  close,
  editValue,
  refParent,
  note,
}) => {
  const [value, setvalue] = useState<any>({
    active: true,
    name: "",
  });

  const [isdoing, setIsdoing] = useState<boolean>(false);

  const { api } = useAuth();

  const { notification } = App.useApp();

  useEffect(() => {
    if (editValue) {
      setvalue(editValue);

    }
  }, [editValue]);

  const setdoing = () => {
    setIsdoing(true);
    notification.info({
      message: "Salvataggio...",
      icon: <LoadingOutlined />,
      key: "catsave", duration: 1.5
    });
  };

  const doneritht = () => {
    refresh();
    if (close) {
      close();
    }
    notification.success({
      message: "Salvataggio riuscito con successo!",
      key: "catsave", duration: 1.5
    });
  };

  const catcht = () => {
    notification.error({
      message: "Errore salvataggio!",
      key: "catsave", duration: 1.5
    });
  };

  const finn = () => {
    setIsdoing(false);
  };

  const create = () => {
    const valtosave = { ...value } as Categoria;
    if (refParent) {
      valtosave.parentRef = refParent;
    }
    apiGenItemGroup()
      .create(api, valtosave)
      .then(doneritht)
      .catch(catcht)
      .finally(finn);
  };

  const edit = () => {
    if (editValue) {
      apiGenItemGroup()
        .edit(api, {
          ref: editValue.ref,
          ...value,
        })
        .then(doneritht)
        .catch(catcht)
        .finally(finn);
    }
  };

  const save = () => {
    setdoing();
    if (editValue) {
      edit();
    } else {
      create();
    }
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: 20 }}>
      {note ? <CommonForm
        value={value}
        onChange={(v) => setvalue({ ...value, ...v })}
        elements={[
          {
            key: "name",
            type: "string",
            label: "Nome",
          },
          {
            key: "subtitle",
            type: "text",
            label: "Note",
          },
          {
            key: "active",
            type: "check",
            label: "Visibile",
          },
        ]}
      /> : <CommonForm
      value={value}
      onChange={(v) => setvalue({ ...value, ...v })}
      elements={[
        {
          key: "name",
          type: "string",
          label: "Nome",
        },
        {
          key: "active",
          type: "check",
          label: "Visibile",
        },
      ]}
    /> }
      <div>
        <Button
          size="large"
          type="primary"
          onClick={save}
          disabled={isdoing}
          icon={<SaveOutlined />}
        >
          salva
        </Button>
      </div>
    </div>
  );
};

export default CreateEdit;