import { useEffect, useState } from "react";
import { apiGenStats } from "../../utils/apiutils";
import { useAuth } from "../../../state/auth/auth";
import { Typography, DatePicker, Empty, Grid, notification } from "antd";
import dayjs from "dayjs";
import Totali from "./Totali";

import PerMese from "./PerMese";
import PerHours from "./PerHours";
import PerGroup from "./PerGroup";
import StandPage from "../common/StandPage";
import WeekAnalyticsChart from "./PerWeek";
import PerCustomer from "./PerCustomer";

export type reporp = {
  totalViews: number;
  topgroups: {
    group: {
      ref: string;
      name: string;
      kname: string;
      parentRef: string;
      active: boolean;
    };
    count: number;
  }[];
  months: {
    month: number;
    year: number;
    count: number;
  }[];
  peakhours: {
    time: number;
    count: number;
  }[];
  languageCounts: {
    language: string;
    count: number;
  }[];
  weekAnalytics:{
    dayOfWeek: string;
    count: number;
  }[];
  shares: number;
  averageTime: number;
};

const Stats = () => {
  const [stats, setstats] = useState<reporp | null>(null);
  const [interval, setInterval] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(90, "days"),
    dayjs().endOf("month"),
  ]);
  const [isloading, setIsloading] = useState(false);

  const { md, lg } = Grid.useBreakpoint();
  const { api } = useAuth();

  const refresh = async () => {
    setIsloading(true);

    try {
      // Chiamata API per ottenere le statistiche
      const response: any = await apiGenStats().extraGet(
        api,
        `${interval[0].valueOf()}/${interval[1].valueOf()}`
      );

      // Imposta i dati delle statistiche
      const statsData: reporp = {
        totalViews: response.totalViews,
        topgroups: response.topgroups || [],
        months: response.months || [],
        peakhours: response.peakhours || [],
        languageCounts: response.languageCounts || [],
        weekAnalytics: response.weekAnalytics || [],
        shares: response.shares,
        averageTime: response.averageTime
      };

      setstats(statsData);
    } catch (error) {
      console.error("Errore durante il caricamento:", error);
      notification.error({ message: "Errore durante il caricamento dei dati!", duration: 1.5 });
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, [interval]);
  
  return (
    <>
      <Typography style={{ paddingLeft: md ? "5%" : "2%" }}>
        <h3>
          <b>Statistiche</b>
        </h3>
      </Typography>
      <StandPage
        extraBt={
          <DatePicker.RangePicker
            allowClear={false}
            value={interval}
            format={"DD/MM/YYYY"}
            disabled={isloading}
            onChange={(v) => setInterval(v as any)}
          />
        }
        isLoading={isloading}
        refresh={refresh}
        showFilter={false}
        showRefresh
      >
        {stats ? (
          <div
            style={{
              display: "grid",
              maxWidth: "100%",
              gridTemplateColumns: lg ? "1fr 1fr" : "1fr",
              gap: 20,
            }}
          >
            <Totali
              totali={stats.languageCounts}
              totalViews={stats.totalViews}
            />
            <PerGroup stats={stats} key={JSON.stringify(stats.topgroups)}/>
            <PerMese
              min={interval[0].valueOf()}
              max={interval[1].valueOf()}
              stats={stats}
            />
            <PerHours stats={stats} />
            <WeekAnalyticsChart data={stats.weekAnalytics}/>
            <PerCustomer shares={stats.shares} averageTime={stats.averageTime}/>
            <br></br>
            <br></br>
            <br></br>
            
          </div>
        ) : (
          <Empty />
        )}
      </StandPage>
    </>
  );
};

export default Stats;
