import React, { useState } from "react";
import { useAuth } from "../../state/auth/auth"; // Assicurati di impostare il percorso corretto

const RegisterPage: React.FC = () => {
  // Ottieni l'istanza di "api" da useAuth (che include il token nelle headers)
  const { api ,logout} = useAuth();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [ref, setRef] = useState(""); 
  const [message, setMessage] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // NOTA: non inserire il prefisso "/api/p/" poiché l'api di useAuth lo aggiunge automaticamente
      const data = await api.post("resturant/register", {
        email,
        name,
        username,
        password,
        ref,
      });
      // Il backend restituisce una stringa di successo
      setMessage(data);
    } catch (error: any) {
      console.error("Errore nella registrazione:", error);
      // Se esiste una risposta con un messaggio, lo usiamo; altrimenti mostriamo un messaggio di default
      const errMsg = error?.response?.data || "Errore nella registrazione, riprova.";
      setMessage(errMsg);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Registrazione</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nome:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Riferimento Ristorante (ref):</label>
          <input
            type="text"
            value={ref}
            onChange={(e) => setRef(e.target.value)}
            required
          />
        </div>
        <button type="submit">Registrati</button>
      </form>
      {message && <p>{message}</p>}
      <button onClick={logout}>Logout</button>
    </div>
    
  );
};

export default RegisterPage;
