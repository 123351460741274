import { App, Button, Input} from "antd";
import { AuthComp } from ".";
import { useEffect, useState } from "react";
import { useAuth } from "../../state/auth/auth";
import { LoadingOutlined } from "@ant-design/icons";


const LoginCont = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { login, lastusername } = useAuth();

  const [doinglogin, setdoinglogin] = useState(false);

  useEffect(() => {
    if (lastusername) {
      setUsername(lastusername);
    }
  }, [lastusername]);

  const { notification } = App.useApp();

  const sub = () => {
    setdoinglogin(true);
    login(username, password)
      .then((e) => {})
      .catch((e) => {
        notification.error({
          message: "Errore Login",
          description: "Controllare le credenziali", duration: 1.5
        });
      })
      .finally(() => {
        setdoinglogin(false);
      });
  };

  return (
    <div>
    <div>
      <AuthComp
        input={[
          {
            lb: "",
            el: (

                <Input
                size="large"
                  style={{ marginTop: "15px" ,backgroundColor:"white"}}
                  autoCapitalize="off"
                  autoCorrect="off"
                  disabled={doinglogin}
                  value={username}
                  placeholder="Inserisci username"
                  onPressEnter={() => sub()}
                  onChange={(e) => setUsername(e.target.value)}
                />
          
            ),
          },
          {
            lb: "",
            el: (
              
                <Input.Password
                  size="large"
                  disabled={doinglogin}
                  style={{ marginTop: "20px" ,backgroundColor:"white"}}
                  placeholder="Inserisci password"
                  onPressEnter={() => sub()}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              
            ),
          },
        ]}
        submitbt={
          <Button
            shape="round"
            size="large"
            style={{ marginTop: "16px" }}
            icon={doinglogin ? <LoadingOutlined /> : <></>}
            disabled={doinglogin}
            type="primary"
            onClick={sub}
          >
            Login
          </Button>
        }
      />
      </div>
        
    </div>
  );
};

export default LoginCont;