import { useEffect, useMemo, useState } from "react";
import { Ingrediente } from "../../Ingredienti/tabella";
import { Articolo } from "../tabella";
import { Select } from "antd";
import { apiGenIng, apiGenItem } from "../../../utils/apiutils";
import { useAuth } from "../../../../state/auth/auth";

type valueype = {
  ings: Ingrediente[];
  removedIngs: Ingrediente[];
};

type props = {
  item: Articolo | null;
  value: {
    ings: Ingrediente[];
    removedIngs: Ingrediente[];
  };
  onChange: (nv: { ings: Ingrediente[]; removedIngs: Ingrediente[] }) => void;
};

const ManageIngrs: React.FC<props> = ({
  item: _item,
  value: _value,
  onChange,
}) => {
  const [options, setOptions] = useState<Ingrediente[]>([]);

  const [item, setitem] = useState<Articolo | valueype>(
    _item ? { ..._item, ings: [] } : { ings: [], removedIngs: [] }
  );

  const { api } = useAuth();

  useEffect(() => {
    apiGenIng()
      .getList(api)
      .then((l) => {
        setOptions(l as any);
      });
  }, []);

  useEffect(() => {
    if (_item && _item.ref)
      apiGenItem()
        .getEntity(api, _item.ref)
        .then((e) => {
          setitem(e as any);
        });
  }, [_item]);

  const value = useMemo(() => {
    const ings = item.ings
      ? item.ings
          ?.map((e) => e.ref)
          .filter((e) => !_value.removedIngs.some((el) => el.ref === e))
      : [];

    return [...ings, ..._value.ings.map((e) => (e.ref ? e.ref : e.name))];
  }, [item.ings, _value]);

  return (
    <div>
      <Select
        mode="tags"
        style={{ width: "100%", marginTop:"5px"}}
        placeholder="Ingredienti"
        value={value}
        onChange={(ev: string[]) => {
          const toAdd = ev.filter((e) => {
            const some = options.some((o) => o.ref === e);

            return (
              some && !item.ings.some((i) => (i.ref ? i.ref === e : false))
            );
          });

          const toAdd2 = ev.filter((e) => {
            const some = options.some((o) => o.ref === e);

            return !some;
          });

          const toRemove = ev.filter((e) => {
            const some = options.some((o) => o.ref === e);

            return some && item.ings.some((i) => (i.ref ? i.ref === e : false));
          });

          const p = {
            ings: [...toAdd2, ...toAdd].map((id) => {
              const f = options.find((o) => o.ref === id);

              return f
                ? f
                : {
                    name: id,
                    kname: id,
                  };
            }) as any,
            removedIngs: toRemove
              .map((id) => {
                return options.find((o) => o.ref === id);
              })
              .filter((el) => !!el) as any,
          };
          
          onChange(p as any);
        }}
        options={options.map((i) => {
          return {
            value: i.ref,
            label: i.name,
          };
        })}
      />
    </div>
  );
};

export default ManageIngrs;
