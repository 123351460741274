import { SaveOutlined, SettingOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Row, Col, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import CommonForm, { formEl, SingleInput } from "../../common/CommonForm";
import { apiGenItem } from "../../../utils/apiutils";
import { useAuth } from "../../../../state/auth/auth";
import { ItemProps } from "./Create";
import ManageIngrs from "./ManageIngrs";
import ModalOpenBt from "../../common/ModalOpenbt";
import ItemManageIngs from "./ManageIngs";
import TagPicker from "../../common/TagPicker";
import { Articolo } from "../tabella";

type EditItemProps = {
  disabled: boolean;
  refresh: () => void;
  item: any; 
  open?: boolean;
  close?: () => void;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
};

const EditItem: React.FC<EditItemProps> = ({
  disabled,
  refresh,
  item,
  close = () => {},
  open = false,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const { api } = useAuth();

  // Modalità singola: form completo (precompilato)
  const [value, setValue] = useState<any>({});
  // Modalità bulk: raccogliamo solo le modifiche effettuate
  const [bulkValue, setBulkValue] = useState<any>({});

  // Determina se siamo in modalità bulk (più di un elemento selezionato)
  const isBulk = selectedRowKeys.length > 1;

  // In modalità singola, inizializza "value" con l'item
  useEffect(() => {
    if (!isBulk) {
      setValue(item);
    }
  }, [item, isBulk]);

  // Definizione dei campi modificabili in modalità bulk
  const bulkFields: formEl[] = [
    
    { type: "category", key: "refParent", label: "Categoria" },
    { type: "price", key: "price", label: "Prezzo" },
    {type:"tags" ,key:"tags" , label:"tags"},
    { type: "check", key: "active", label: "Attivo" },
    { type: "check", key: "showprice", label: "Mostra Prezzo" },
    { type: "check", key: "showtag", label: "Mostra Sottotitolo" },
    { type: "check", key: "showdescription", label: "Mostra Descrizione" },
    { type: "check", key: "showingredienti", label: "Mostra Ingredienti" },
    { type: "check", key: "showallegeni", label: "Mostra Allergeni" },
  ];


  const renderBulkForm = () => {
    return (
      <Row gutter={[16, 16]}>
        {bulkFields.map((field) => (
          <Col span={field.type === "check" ? 12 : 24} key={field.key}>
            <div>
              <Typography.Text strong>{field.label}</Typography.Text>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr auto", gap: 10 }}>
              <SingleInput
             
                bulkMode={true}
                element={field}
                value={bulkValue[field.key] ?? ""}
                onChange={(newVal) =>
                  setBulkValue((prev: any) => ({ ...prev, [field.key]: newVal }))
                }
              />
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  // Funzione di salvataggio:
  // - Modalità singola: invia l'intero oggetto "value"
  // - Modalità bulk: per ogni elemento selezionato, costruisce un oggetto con solo i campi modificati
  const save = () => {
    if (!isBulk) {
      apiGenItem()
        .edit(api, value)
        .then(() => {
          notification.success({ message: "Salvataggio riuscito", duration: 1.5 });
          refresh();
          close();
          setValue({});
          setSelectedRowKeys([]);
        })
        .catch(() => {
          notification.error({ message: "Errore salvataggio", duration: 1.5 });
        });
    } else {
      const payload = selectedRowKeys.map((key) => {
        const dataToUpdate: any = {};
        Object.keys(bulkValue).forEach((k) => {
          // Includi solo i campi modificati (che non sono undefined)
          if (bulkValue[k] !== undefined) {
            dataToUpdate[k] = bulkValue[k];
          }
        });
        return { ref: key, ...dataToUpdate };
      });
      // Invia il payload bulk tramite PATCH all'endpoint "bulk"
      apiGenItem()
        .extraPatch(api, "bulk", payload)
        .then(() => {
          notification.success({ message: "Salvataggio riuscito", duration: 1.5 });
          refresh();
          close();
          setBulkValue({});
          setSelectedRowKeys([]);
        })
        .catch(() => {
          notification.error({ message: "Errore salvataggio", duration: 1.5 });
        });
      
    }
  };
  
  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: 20 }}>
      <div>
        {!isBulk ? (
          open && (
            <div>
              <CommonForm
                value={value}
                onChange={(newVal) => setValue(newVal)}
                elements={ItemProps}
                extra={[
                  {
                    label: "Ingredienti",
                    children: (
                      <>
                      <ManageIngrs
                        item={item}
                        value={{
                          ings: value?.ings || [],
                          removedIngs: value?.removedIngs || [],
                        }}
                        onChange={(nv) => setValue({ ...value, ...nv })}
                      />
                                    <br />
              <ModalOpenBt btLabel="Ingredienti" btProps={{ icon: <SettingOutlined /> }}>
                <ItemManageIngs item={item} />
              </ModalOpenBt>
                      </>
                    ),
                  },

 
                ]}
              />

            </div>
          )
        ) : (
          open && renderBulkForm()
        )}
      </div>
      <div>
        <Button size="large" type="primary" onClick={save} icon={<SaveOutlined />}>
          salva
        </Button>
      </div>
    </div>
  );
};

export default EditItem;