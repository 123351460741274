import React, { useState } from "react";
import { Image, Typography, Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { useAuth } from "../../../state/auth/auth";
import dayjs from "dayjs";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const CaricaLogo = () => {
  const { api, resturant } = useAuth();

  const [time, settime] = useState<number>(dayjs().valueOf());

  const refreshImage = () => {
    settime(dayjs().valueOf());
  };

  return (<div>
    
    <ImgCrop rotationSlider>
      
      <Upload
        showUploadList={false}
        action={(f) => {
          return new Promise((res, rej) => {
            api
              .uploadImage(f, "resturant/uploadLogo")
              .then((e) => {
                refreshImage();
              })
              .catch(rej);
          });
        }}
      >
        <div
          style={{
            cursor: "pointer",
            alignContent:"center",
            justifyContent:"center"
          }}
        >
          <div>
            <img height={100} src={`/img/logo/${resturant?.ref}/${time}`} />
          </div>
          <div>
            <Typography.Text strong type="secondary">
              Carica il logo
            </Typography.Text>
          </div>
        </div>
      </Upload>
    </ImgCrop>
    </div>
  );
};

export default CaricaLogo;
