import { PlusCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Drawer, Modal } from "antd";
import React, { useState } from "react";

type props = {
  disabled?: boolean;
  children: React.ReactNode;
  btProps?: ButtonProps;
  btLabel?: string;
  btIcon?: string;
  drawerTitle?:string;
};

const ModalOpenBt: React.FC<props> = ({
  disabled = false,
  btProps = {},
  children,
  btLabel = "Configura",
  btIcon,
  drawerTitle
}) => {
  const [open, setOpen] = useState(false);

  const close = () => setOpen(false);

  const extraProp = {
    open: open,
    close: close,
  };

  return (
    <div>
      <Button
        icon={btIcon==="Crea" ? <PlusCircleOutlined /> : <SettingOutlined/>}
        {...btProps}
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        {btLabel}
      </Button>
      <Drawer width={500} open={open} onClose={close} title={drawerTitle}>
        {!disabled &&
          open &&
          React.Children.map(children, (child: any) =>
            React.cloneElement(child, { ...extraProp })
          )}
      </Drawer>
    </div>
  );
};

export default ModalOpenBt;
