import {
  PlusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Grid, Modal, notification, Tag } from "antd";
import React, { useMemo, useState } from "react";
import CommonForm from "../common/CommonForm";
import { useAuth } from "../../../state/auth/auth";
import { css } from "@emotion/css";

type props = {
  onCreate: Function;
  disabled: boolean;
  listCreated: string[];
};

const CreateModalIngrede: React.FC<props> = ({
  onCreate,
  disabled,
  listCreated,
}) => {
  const [open, setopen] = useState(false);
  const [value, setvalue] = useState<{ [key: string]: any }>({});

  const { api } = useAuth();

  const saveNoClose = (name: string) => {
    api
      .post("ingredienti/create", { name: name })
      .then((e) => {
        notification.success({ message: "salvataggio riuscito", duration: 1.5 });
        onCreate();
      })
      .catch(() => {
        notification.error({ message: "Errore salvataggio", duration: 1.5 });
      });
  };

  const prShow = useMemo(() => {
    return preset.filter((p) => !listCreated.includes(p));
  }, [listCreated]);

  const save = () => {
    if (!value.name || value.name.trim() === "") return;
    api
      .post("ingredienti/create", value)
      .then((e) => {
        notification.success({ message: "salvataggio riuscito", duration: 1.5 });
        onCreate();
        setopen(false);
        setvalue({});
      })
      .catch(() => {
        notification.error({ message: "Errore salvataggio", duration: 1.5 });
      });
  };

  const { md } = Grid.useBreakpoint();

  return (
    <div>
      <Button
        onClick={() => setopen(true)}
        icon={<PlusCircleOutlined />}
        disabled={disabled}
      >
        crea
      </Button>

      <Drawer
        onClose={() => setopen(false)}
        width={500}
        open={open}
        title="Crea ingrediente"
      >
        <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: 20 }}>
          <div>
            {open && (
              <CommonForm
                value={value}
                onChange={setvalue}
                elements={[
                  {
                    key: "name",
                    type: "string",
                    label: "Nome",
                  },
                ]}
              />
            )}
          </div>
          <div>
            <div
              style={{
                overflowX: "scroll",
                display: "flex",
                flexDirection: "row",
                width: md ? 400 : 300,
              }}
              className={css`
                &::-webkit-scrollbar {
                  display: none;
                }
              `}
            >
              {prShow.map((k) => (
                <Tag
                  key={k}
                  style={{ margin: "0 5px", cursor: "pointer" }}
                  color="blue"
                  icon={<PlusCircleOutlined />}
                  onClick={() => saveNoClose(k)}
                >
                  {k}
                </Tag>
              ))}
            </div>
          </div>
          <div>
            <Button
              size="large"
              type="primary"
              onClick={save}
              icon={<SaveOutlined />}
              disabled={!value.name || value.name.trim() === ""}
            >
              salva
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CreateModalIngrede;

const preset = [
  "Pomodori freschi",
  "Olio d'oliva extra vergine",
  "Aglio",
  "Cipolla",
  "Basilico fresco",
  "Mozzarella",
  "Parmigiano Reggiano",
  "Origano",
  "Farina di grano",
  "Sale",
  "Pepe nero",
  "Pasta (spaghetti, penne, ecc.)",
  "Aceto balsamico",
  "Pane rustico",
  "Peperoncino",
  "Vino bianco",
  "Funghi",
  "Oliva nera",
  "Capperi",
  "Acciughe",
  "Carne macinata",
  "Pollo",
  "Prosciutto crudo",
  "Salame",
  "Uova",
  "Ricotta",
  "Panna da cucina",
  "Burro",
  "Rosmarino",
  "Timo",
  "Prezzemolo",
  "Limone",
  "Zucchine",
  "Melanzane",
  "Peperoni",
  "Spinaci",
  "Patate",
  "Salsiccia",
  "Gamberi",
  "Tonno",
  "Cozze",
  "Calamari",
  "Polpo",
  "Lenticchie",
  "Fagioli cannellini",
  "Ceci",
  "Riso arborio",
  "Farro",
  "Orzo",
  "Pecorino",
  "Gorgonzola",
  "Provolone",
  "Mortadella",
  "Speck",
  "Zafferano",
  "Miele",
  "Fichi secchi",
  "Mandorle",
  "Noci",
  "Pinoli",
  "Pistacchi",
  "Rucola",
  "Asparagi",
  "Carciofi",
  "Broccoli",
  "Finocchio",
  "Carote",
  "Sedano",
  "Barbabietola",
  "Polenta",
  "Gnocchi",
  "Aceto di mele",
  "Grana Padano",
  "Farfalle",
  "Tortellini",
  "Cannelloni",
];
