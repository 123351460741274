const reduceText = (text: string) => {
  return `${text}`.toUpperCase();
};

export const searchValid = (
  text: string | string[],
  search: string
): boolean => {
  let tt = Array.isArray(text) ? reduceText(text.join(" ")) : reduceText(text);
  let st = reduceText(search);

  return tt.includes(st);
};
