import { Affix, Button, Grid, Popconfirm, theme, Typography } from "antd";
import { css } from "@emotion/css";
import React, { FC, useEffect, useState } from "react";
import ArticoliPage from "./Articoli";
import { menu } from "./dmenu";
import { useAuth } from "../../state/auth/auth";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import logimg from "../../img/Groupcol.png";
import logimgdk from "../../img/Groupbk.png";
import { getDarkmodeFromStorage } from "../../App";
import { FaLifeRing } from "react-icons/fa";





type Props = {
  onSelect: (key: string) => void; // Specifica che `onSelect` accetta una stringa
  selected: string;
};

const MenuComp: FC<Props> = ({ onSelect: _onSelect, selected }) => {
  const breakpoints = Grid.useBreakpoint();
  const { token } = theme.useToken();
  const [MobileOpen, setMobileOpen] = useState(false);
  const [openmenuwidth, setOpenmenuwidth] = useState(150); // Imposta un valore iniziale
  const { md } = Grid.useBreakpoint();
  const onSelect = (key: string) => {
    setMobileOpen(false);
    _onSelect(key);
  };

  useEffect(() => {
    let newWidth = 150; // Default per Extra Small screens

    if (breakpoints.xl) {
      newWidth = 300; // Extra Large screens
    } else if (breakpoints.lg) {
      newWidth = 230; // Large screens
    } else if (breakpoints.md) {
      newWidth = 230; // Medium screens
    } else if (breakpoints.sm) {
      newWidth = 150; // Small screens
    }

    // Aggiorna lo stato solo se cambia il valore
    if (openmenuwidth !== newWidth) {
      setOpenmenuwidth(newWidth);
    }
  }, [breakpoints]);


  const isdk = getDarkmodeFromStorage();
  const { user, resturant, logout } = useAuth();

  const title = (
    <div
      style={{
        padding: md ? "10px 20px" : 0,
        display: "grid",
        gridTemplateColumns: md ? "1fr" : "auto 1fr",
        justifyContent: "start",
        gap: 20,
      }}
      onClick={() => setMobileOpen(!MobileOpen)}
    >
      {!md && (
        <Button
          type="primary"
          size="large"
          icon={!MobileOpen ? <CaretDownOutlined /> : <CaretUpOutlined />}
        />
      )}
      {md ? (
        <div
          style={{
            padding: "20px 0",
          }}
        >
          <img src={logimg} height={100} />
        </div>
      ) : (
        <Typography.Title
          style={{ color: token.colorPrimary, margin: md ? "" : 0 }}
        >
          OrdinaQui
        </Typography.Title>
      )}
    </div>
  );

  const menudef = user && (
    <div
      style={{
        background: token.colorBgContainer,
        height: "100vh",
        display: "grid",
        gridTemplateColumns: "1fr",
        alignContent: "start",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto",
          justifyContent: "center",
          marginBottom: md ? 0 : 30,
        }}
      >
        {title}
      </div>
      {menu.map((m) => {
        if (m.superadmin && !user.superAdmin) {
          return null;
        }
        return (
          <div
            key={m.key}
            style={{
              padding: "10px 20px",
              cursor: "pointer",
              background: selected === m.key ? token.colorPrimary : "",
            }}
            onClick={() => onSelect(m.key)}
            className={css`
              &:hover {
                background-color: ${token.colorPrimaryBg};
              }
            `}
          >
            <Typography.Text strong>{m.lb}</Typography.Text>
          </div>
        );
      })}
      <div
        style={{
          margin: "20px 0",
          padding: "10px 20px",
        }}
      >
        <Typography.Title level={4} style={{ color: token.colorPrimary }}>
          {user.username}
        </Typography.Title>
        <Popconfirm
          title="Sei sicuro di voler disconnettere?"
          onConfirm={logout}
          okText="Si"
          cancelText="No"
          zIndex={10001}
        >
          <Button type="primary">Logout</Button>
        </Popconfirm>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 15,
          left: 0,
          padding: "20px",
          background: token.colorBgContainer,
        }}
      >
        <Typography.Title
          level={5}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            color: token.colorPrimary,
            margin: 0,
            marginBottom: 5,
          }}
        >
          <FaLifeRing />
          Assistenza
        </Typography.Title>
        <div
        className={css`
          &:hover {
            text-decoration: underline;
          }
        `}>
          <a
            href="https://wa.me/390280886019"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: token.colorText,
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
            
          >
            <WhatsAppOutlined />
            0280886019
          </a>
        </div>
        <div
         className={css`
          &:hover {
            text-decoration: underline;
          }
        `}>
          <a
            href="mailto:support@ordinaqui.com"
            style={{
              textDecoration: "none",
              color: token.colorText,
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
           
          >
            <MailOutlined />
            support@ordinaqui.com
          </a>
        </div>
      </div>
    </div>
  );

  return md ? (
    <div
      style={{
        width: openmenuwidth,
        boxShadow: token.boxShadow,
      }}
    >
      {menudef}
    </div>
  ) : (
    <div>
      <div
        style={{
          backgroundColor: token.colorBgContainer,
          display: "grid",
          gridTemplateColumns: "auto",
          justifyContent: "center",
          padding: "10px 0",
          
        }}
      >
        {title}
      </div>
      {MobileOpen && (
        <div
          style={{
            display: "grid",
            position: "fixed",
            zIndex: 10000,
            width: "100vw",
            top: "0",
            left: "0",
            padding: "10px 0",
            background: token.colorBgContainer,
          }}
        >
          {menudef}
        </div>
      )}
    </div>
  );
};

export default MenuComp;