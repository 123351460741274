import React from "react";
import { itemtimprops } from "./ManageAvalability";
import ModalOpenBt from "../../common/ModalOpenbt";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import CreaEditAvailabilityRule from "./CreateEditAvailabityRule";
import { Articolo } from "../tabella";
import { Button, Popconfirm, Switch, Table } from "antd";
import { apiGenItemAvailability } from "../../../utils/apiutils";
import { useAuth } from "../../../../state/auth/auth";
type props = {
  list: itemtimprops[];
  refresh: () => void;
  isloading: boolean;
  item: Articolo;
};

const AvailabilityTable: React.FC<props> = ({
  refresh,
  item,
  list,
  isloading,
}) => {
  return (
    <div
      style={{
        margin: "20px 0",
        display: "grid",
        gap: 10,
        gridTemplateColumns: "1fr",
      }}
    >
      <div
        style={{
          display: "grid",
          justifyContent: "start",
          
          gap: 10,
          gridTemplateColumns: "auto auto",
        }}
      >
        <ModalOpenBt
          btProps={{
            
            icon: <PlusCircleOutlined />,
          }}
          btLabel="Crea"
          
        >
          <CreaEditAvailabilityRule
            refresh={refresh}
            item={item}
            initName={`Regola ${list ? list.length + 1 : 1}`}
          />
        </ModalOpenBt>
      </div>
      <div>
        <TableItemsProps
          item={item}
          list={list}
          refresh={refresh}
          isloading={isloading}
        />
      </div>
    </div>
  );
};

const TableItemsProps: React.FC<props> = ({
  item,
  list,
  refresh,
  isloading,
}) => {
  const { api } = useAuth();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Stato",
      dataIndex: "active",
      key: "active",
      width: 100,
      render: (value: boolean, r: itemtimprops) => {
        return (
          <div>
            <Switch
              onChange={(e) => {
                apiGenItemAvailability()
                  .edit(api, {
                    ...r,
                    active: !value,
                    itemref: item.ref,
                  })
                  .then((e) => {
                    refresh();
                  });
              }}
              checkedChildren={"Attivo"}
              loading={isloading}
              value={value}
            />
          </div>
        );
      },
    },
    {
      title: "Azioni",
      dataIndex: "rec",
      key: "rec",
      width: 100,
      render: (_: any, r: itemtimprops) => {
        return (
          <div
            style={{ display: "grid", gridTemplateColumns: "auto", gap: 15 }}
          >
            <ModalOpenBt
              btProps={{
                icon: <EditOutlined />,
              }}
              btLabel="modifica"
            >
              <CreaEditAvailabilityRule
                refresh={refresh}
                item={item}
                editValue={r}
                initName={r.name}
              />
            </ModalOpenBt>
            <div>
              <Popconfirm
                title="Sei sicuro?"
                okText="Si!"
                onConfirm={() => {
                  apiGenItemAvailability()
                    .deleteEntity(api, { ...r, itemref: item.ref })
                    .then((e) => {
                      refresh();
                    });
                }}
                okButtonProps={{
                  danger: true,
                }}
              >
                <Button type="primary" danger icon={<DeleteOutlined />}>
                  Elimina
                </Button>
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{marginTop:"20px"}}>
      <Table columns={columns} dataSource={list} />
    </div>
  );
};

export default AvailabilityTable;
