import React, { useEffect, useMemo, useState } from "react";
import { Select, Typography, Space } from "antd";
import { useAuth } from "../../../state/auth/auth";
import { apiGenTag } from "../../utils/apiutils";
import { FaPepperHot, FaSnowflake, FaSeedling, FaCarrot } from "react-icons/fa";

export interface Tags {
  ref: string;
  name: string;
}

type Props = {
  value: Tags[];                     
  onChange: (tags: Tags[]) => void;  
};

const LEVEL_ICONS: Record<string, JSX.Element> = {
  piccante: <FaPepperHot style={{ color: "red" }} />,
  surgelato: <FaSnowflake style={{ color: "blue" }} />,
  vegano: <FaSeedling style={{ color: "green" }} />,
  vegetariano: <FaCarrot style={{ color: "orange" }} />
};

// Facoltativo: funzione helper, se vuoi una logica switch/case
const getTagIcon = (tagName: string) => {
  switch (tagName.toLowerCase()) {
    case "piccante":
      return <FaPepperHot style={{ color: "red" }} />;
    case "surgelato":
      return <FaSnowflake style={{ color: "blue" }} />;
    case "vegano":
      return <FaSeedling style={{ color: "green" }} />;
    case "vegetariano":
      return <FaCarrot style={{ color: "orange" }} />;
    default:
      return null;
  }
};

const TagPicker: React.FC<Props> = ({ value, onChange }) => {
  const [options, setOptions] = useState<Tags[]>([]);
  const { api } = useAuth();

  // Carica i tag disponibili dal server
  useEffect(() => {
    apiGenTag()
      .getList(api)
      .then((l) => {
        setOptions(l as Tags[]);
      });
  }, [api]);

  // Converti i tag selezionati in un array di stringhe (ref)
  const selectedRefs = useMemo(() => {
    return value.map((tag) => tag.ref);
  }, [value]);

  // Quando l'utente modifica la selezione
  const handleChange = (newRefs: string[]) => {
    // Convertiamo i ref in oggetti Tags
    const newTags: Tags[] = newRefs.map((r) => {
      const found = options.find((o) => o.ref === r);
      return found || { ref: r, name: r };
    });
    onChange(newTags);
  };

  return (
<Select
  mode="multiple"
  style={{ width: "100%", marginTop: "5px", marginBottom: "20px" }}
  placeholder="Assegna tags..."
  value={selectedRefs}
  onChange={handleChange}
  // Mantieni le stesse opzioni
  options={options.map((t) => ({
    value: t.ref,
    label: (
      <Space>
        {getTagIcon(t.name)}
        <Typography.Text strong>{t.name}</Typography.Text>
      </Space>
    ),
  }))}
/>
  );
};

export default TagPicker;
