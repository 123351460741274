import {
  theme,
  Typography,
  Grid,
  Button,
  Modal,
  notification
} from "antd";
import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import Articolitable, { Articolo } from "./tabella";
import ModalOpenBt from "../common/ModalOpenbt";
import CreateItem from "./drwrs/Create";
import StandPage from "../common/StandPage";
import { useAuth } from "../../../state/auth/auth";
import { apiGenItem } from "../../utils/apiutils";


const ArticoliPage = () => {
  const [lista, setlista] = useState<Articolo[]>([]);

  const [isloading, setIsloading] = useState(false);

  const { api } = useAuth();

  const [filter, setfilter] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  
  const refresh = () => {
    setIsloading(true);
    api
      .get("item/")
      .then((i) => {
        setlista(i);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  useEffect(() => {
    refresh();
  }, []);
  
  

  const { user, resturant, logout } = useAuth();
  const { md } = Grid.useBreakpoint();
  return (
    <>

    <Typography  style={{paddingLeft: md ? "5%" : "2%" }}><h3><b>Articoli</b></h3></Typography>
    <StandPage
      extraBt={
        <ModalOpenBt
          btLabel={md ? "Crea" : ""}
          drawerTitle="Crea articolo"
          disabled={isloading}
          btProps={{
            icon: <PlusCircleOutlined />,
          }}
        >
          <CreateItem disabled={isloading} refresh={refresh} />
        </ModalOpenBt>
      }
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      isLoading={isloading}
      refresh={refresh}
      showFilter
      showRefresh
      onFilterChange={setfilter}
      showDeleteButton
      lista={lista}
      apiGen={apiGenItem()}
      showEdit="articoli"
    >
      <Articolitable
        articoli={lista}
        filterText={filter}
        isloading={isloading}
        refresh={refresh}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
            < br></br>
            < br></br>
            < br></br>
    </StandPage>
    </>
  );
};

export default ArticoliPage;
