import React, { useEffect, useState } from "react";
import { App, Button, Input , Typography} from "antd";
import { useAuth } from "../../../state/auth/auth";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";


const ResetPasswordComp = () => {
  const { login, user, lastusername, api } = useAuth();
  const { notification } = App.useApp();
  
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  // Precompila lo username da user o lastusername
  useEffect(() => {
    if (user?.username) {
      setUsername(user.username);
    } else if (lastusername) {
      setUsername(lastusername);
    }
  }, [user, lastusername]);

  const handleReset = async () => {
    // Controlla che i due campi della nuova password coincidano
    if (newPassword !== confirmNewPassword) {
      notification.error({
        message: "Errore",
        description: "I due campi della nuova password non coincidono.",
        duration: 1.5,
      });
      return;
    }
    
    setLoading(true);
    try {
      // Verifica la password attuale chiamando login
      await login(username, currentPassword);
   
      
      // Chiamata API per ottenere l'hash della nuova password
      const hashResponse = await axios.get(`/api/auth/${encodeURIComponent(newPassword)}`);
      const newHash = hashResponse.data;
     
      
      // Invia il nuovo hash all'endpoint changePassword usando il client API
      const changeResponse = await api.post("user/changePassword", { newHashedPassword: newHash });
      console.log("Change password response:", changeResponse.data);
      notification.success({
        message: "Password aggiornata correttamente.",
        duration: 1.5,
      });
      
    } catch (error) {
      console.error("Reset password error:", error);
      notification.error({
        message: "Errore nel reset della password",
        description: "Verifica la password attuale oppure riprova.",
        duration: 1.5,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 400, margin: "auto", padding: "20px" }}>
      <Typography.Title level={5} style={{margin:0, marginTop:20}}>Inserisci la tua password attuale:</Typography.Title>
      <Input.Password
        size="large"
        disabled={loading}
        style={{ marginTop: "10px", backgroundColor: "white" }}
     
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <Typography.Title level={5} style={{margin:0, marginTop:20}}>Inserisci la nuova password:</Typography.Title>
      <Input.Password
        size="large"
        disabled={loading}
        style={{ marginTop: "10px", backgroundColor: "white" }}
        
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <Typography.Title level={5} style={{margin:0, marginTop:20}}>Conferma la nuova password:</Typography.Title>
      <Input.Password
        size="large"
        disabled={loading}
        style={{ marginTop: "10px", backgroundColor: "white" }}
       
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
        onPressEnter={handleReset}
      />
      <Button
        shape="round"
        size="large"
        style={{ marginTop: "16px", width: "100%" }}
        icon={loading ? <LoadingOutlined /> : null}
        disabled={loading || !currentPassword || !newPassword || !confirmNewPassword}
        type="primary"
        onClick={handleReset}
      >
        Aggiorna Password
      </Button>
    </div>
  );
};

export default ResetPasswordComp;
